import {useEffect, useState} from 'react';

import {TextField, DialogContent, DialogActions} from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import {func, bool, string, arrayOf, shape, number} from 'prop-types';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS, VAT_RATES} from '../../../const';
import Button from '../../form/buttons/Button/Button';
import FormSelect from '../../form/MuiSelect/MuiSelect';
import BaseModal from '../../modal/BaseModal';
import InputVAT from './InputVAT';

// eslint-disable-next-line complexity
const CreateBudgetAccountingEntryDialog = ({isOpen, closeModal, category, categories, subcategories, onSubmit}) => {
  const [vatRate, setVatRate] = useState(0.2);
  const [label, setLabel] = useState('');
  const [compteNum, setCompteNum] = useState('');
  const [paymentDelayInMonths, setPaymentDelayInMonths] = useState(1);
  const [entryCategory, setEntryCategory] = useState(category?.category);
  const [entrySubcategory, setEntrySubcategory] = useState(category?.parentCategory || '');

  const resetValues = () => {
    setLabel('');
    setCompteNum('');
    setVatRate(0.2);
  };

  const handleSubmit = () => {
    const data = {
      racine3_name: entryCategory,
      racine3_subname: entrySubcategory || null,
      vat_rate: vatRate,
      comptenum: compteNum,
      label,
      delay_payment: paymentDelayInMonths
    };

    onSubmit(data);
    closeModal();
  };

  useEffect(() => {
    resetValues();
  }, [isOpen]);

  useEffect(() => {
    if (category) {
      setEntryCategory(category.isSubcategory ? category.parentCategory : category.category);
      setEntrySubcategory(category.isSubcategory ? category.category : '');
    }
  }, [category, isOpen]);

  const selectedCategoryHasSubcategories = subcategories.find(sc => sc.parentCategory === entryCategory) !== undefined;
  const selectedCategorySubcategories = selectedCategoryHasSubcategories ? subcategories.filter(sc => sc.parentCategory === entryCategory) : [];
  const hasCompteNumError = compteNum !== '' && !REGULAR_EXPRESSIONS.compteNum.test(compteNum);
  const hasLabelError = label !== '' && !REGULAR_EXPRESSIONS.label.test(label);

  const submitButtonDisabled = !label || !compteNum || !VAT_RATES.includes(vatRate) || (selectedCategoryHasSubcategories && !entrySubcategory) || hasCompteNumError || hasLabelError;

  return (
    <BaseModal
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      }}
      onClose={closeModal}
      open={isOpen}
      title="Ajouter une ligne au budget"
    >
      <DialogContent>
        <Box mt={1}>
          <FormSelect
            sx={{width: selectedCategoryHasSubcategories ? '50%' : '100%'}}
            label="Catégorie"
            labelId="category-input-label"
            value={entryCategory}
            onChange={e => {
              setEntryCategory(e.target.value);
              setEntrySubcategory('');
            }}
            items={categories.map(c => (
              <MenuItem key={c} selected={c === entryCategory} value={c}>
                {c}
              </MenuItem>
            ))}
          />

          {selectedCategoryHasSubcategories && (
            <FormSelect
              sx={{width: '50%'}}
              label="Sous catégorie"
              labelId="subcategory-input-label"
              value={entrySubcategory}
              onChange={e => {
                setEntrySubcategory(e.target.value);
              }}
              items={selectedCategorySubcategories.map(({subCategory}) => (
                <MenuItem key={subCategory} selected={subCategory === entrySubcategory} value={subCategory}>
                  {subCategory}
                </MenuItem>
              ))}
            />
          )}
        </Box>

        <TextField
          sx={{mb: 3, mt: 3}}
          required
          value={label}
          error={hasLabelError}
          helperText={hasLabelError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.label : ''}
          onChange={event => {
            setLabel(event.target.value);
          }}
          label="Libellé de la ligne"
          placeholder="Saisir un libellé"
          color="primary"
          fullWidth
        />

        <TextField
          sx={{mb: 4}}
          required
          value={compteNum}
          onChange={event => {
            setCompteNum(event.target.value);
          }}
          label="Numéro de compte (6 chiffres)"
          placeholder="Saisir le numéro de compte"
          color="primary"
          fullWidth
          inputProps={{maxLength: 6}}
          error={hasCompteNumError}
          helperText={hasCompteNumError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.compteNum : ''}
        />

        <>
          <InputVAT
            value={vatRate}
            onChange={e => {
              setVatRate(e.target.value);
            }}
          />

          <FormSelect
            sx={{width: '50%'}}
            label="Délai de paiement (en mois)"
            labelId="delay-input-label"
            value={paymentDelayInMonths}
            onChange={e => {
              setPaymentDelayInMonths(e.target.value);
            }}
            items={[
              <MenuItem key={0} value={1}>
                1
              </MenuItem>,
              <MenuItem key={1} value={2}>
                2
              </MenuItem>,
              <MenuItem key={2} value={3}>
                3
              </MenuItem>
            ]}
          />
        </>
      </DialogContent>

      <DialogActions sx={{justifyContent: 'center', mt: 1}}>
        <Button size="large" variant="outlined" color="primary" onClick={closeModal}>
          Fermer
        </Button>
        <Button size="large" variant="contained" color="secondary" disabled={submitButtonDisabled} onClick={handleSubmit}>
          Ajouter
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

CreateBudgetAccountingEntryDialog.defaultProps = {
  isOpen: false
};

const categoryShape = shape({
  rowId: number,
  category: string,
  isSubcategory: bool,
  parentCategory: string || null
});
const subCategoryShape = shape({
  subCategory: string,
  parentCategory: string
});
CreateBudgetAccountingEntryDialog.propTypes = {
  isOpen: bool,
  closeModal: func.isRequired,
  onSubmit: func.isRequired,
  category: categoryShape.isRequired,
  categories: arrayOf(string).isRequired,
  subcategories: arrayOf(subCategoryShape).isRequired
};

export default CreateBudgetAccountingEntryDialog;
