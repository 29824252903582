import {useState, useEffect} from 'react';

import {DeleteForeverOutlined} from '@mui/icons-material';
import {Grid, Avatar, DialogContentText, Typography, DialogContent, DialogActions, useTheme} from '@mui/material';

import useChatbot from '../../../hooks/providers/useChatbot';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';

const DeleteThreadModal = () => {
  const theme = useTheme();
  const {deleteThread, threadIdToDelete, threads, isDeleteThreadModalOpen: open, setIsDeleteThreadModalOpen, closeThreadMenu} = useChatbot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  const conversationTitle = threads.find(t => t.thread_id === threadIdToDelete)?.thread_name;

  const onClose = () => {
    setIsDeleteThreadModalOpen(false);
    closeThreadMenu();
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    const result = await deleteThread(threadIdToDelete);
    setIsSubmitting(false);

    if (result.success) {
      setHasBeenDeleted(true);
    }
  };

  useEffect(() => {
    if (open) {
      setHasBeenDeleted(false);
    }
  }, [open]);

  return (
    <BaseModal
      canCloseOnBackdropClick={false}
      title="Supprimer une conversation"
      maxWidth="sm"
      onClose={onClose}
      open={open}
      sx={{
        zIndex: 9999
      }}
    >
      <DialogContent sx={{py: 3}}>
        {hasBeenDeleted && (
          <Grid container direction="column" alignItems="center">
            <Avatar sx={{p: 5, bgcolor: `${theme.palette.error.light}30`}}>
              <DeleteForeverOutlined sx={{fontSize: 60}} color="error" />
            </Avatar>
            <Typography sx={{mt: 5, mb: 1, fontSize: 15}} variant="inherit">
              Conversation supprimée avec succès
            </Typography>
          </Grid>
        )}
        {!isSubmitting && !hasBeenDeleted && (
          <DialogContentText variant="inherit" lineHeight={1.4}>
            Etes-vous sûr de vouloir supprimer cette conversation : <span style={{fontFamily: 'InstrumentSansBold'}}>"{conversationTitle}"</span>
          </DialogContentText>
        )}
        {isSubmitting && <Spinner text="Suppression de la conversation en cours" isLoading />}
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', mb: 2}}>
        {!isSubmitting && (
          <Button variant="outlined" color="primary" onClick={onClose}>
            {hasBeenDeleted ? 'Fermer' : 'Annuler'}
          </Button>
        )}
        {!hasBeenDeleted && (
          <Button disabled={isSubmitting} variant="contained" color="error" onClick={handleDelete}>
            Supprimer
          </Button>
        )}
      </DialogActions>
    </BaseModal>
  );
};

export default DeleteThreadModal;
