import React from 'react';

import {Grid} from '@mui/material';

import {CHATBOT_ASSISTANT_ANSWER_STATE, CHATBOT_MESSAGES_ROLES, CHATBOT_USER_MESSAGE_TYPES} from '../../../const';
import useChatbot from '../../../hooks/providers/useChatbot';
import {formatFrenchDateVerboseFromTimeStamp, hasAssistantChangedSinceLastMessage, isFirstMessageOfDay} from '../../../utils';
import AssistantIsAnsweringMessage from '../AssistantIsAnsweringMessage/AssistantIsAnsweringMessage';
import AssistantSwitchedSentence from '../AssistantSwitchedSentence/AssistantSwitchedSentence';
import ConversationEventSubtitle from '../ConversationEventSubtitle/ConversationEventSubtitle';
import Message from '../Message/Message/Message';
import UploadedFileMessage from '../UploadedFileMessage/UploadedFileMessage';

const Talk = () => {
  const {threadMessages, assistants, selectedAssistant, isSendingMessage, isStreamingMessage, sendFileInProgress} = useChatbot();

  const orderedByDateMessages = threadMessages?.sort((a, b) => a.created_at - b.created_at);
  const assistantMessages = orderedByDateMessages.filter(m => m.role === CHATBOT_MESSAGES_ROLES.assistant);
  const lastAssistantMessage = assistantMessages[assistantMessages.length - 1];
  const assistantChangedSinceLastMessage = lastAssistantMessage?.assistant_id !== selectedAssistant?.id;

  // In order to display the "John Doe is thinking/writing..." message , we must fulfil the following conditions :
  // - There must be messages inside the conversation
  // - A message sending must be in progress
  // - The current message sending is not a file upload (we do not want to display "John Doe is thinking/writing" in this in that case)
  const shouldDisplayAssistantIsAnsweringMessage = orderedByDateMessages.length > 0 && isSendingMessage && !sendFileInProgress;
  const assitantIsThinking = orderedByDateMessages.length > 0 && isSendingMessage && !sendFileInProgress && !isStreamingMessage;

  return (
    <Grid container data-testid="talk-component">
      {/* eslint-disable-next-line complexity */}
      {orderedByDateMessages.map((message, index) => {
        const isFirstOfDay = isFirstMessageOfDay(threadMessages, message);
        const isLastMessage = index === orderedByDateMessages.length - 1;

        const nextMessage = orderedByDateMessages[index + 1];
        const lastMessage = orderedByDateMessages[index - 1];

        // We want to display a message like "You shared a file with your assistant", but this message should only appear ONCE if user uploads many files (resulting in many messages)

        const isFirstFileMessage = lastMessage?.content[0]?.type !== CHATBOT_USER_MESSAGE_TYPES.accountingFile;
        const isAccountingFileMessage = message?.content[0]?.type === CHATBOT_USER_MESSAGE_TYPES.accountingFile;
        const nextMessageIsFileMessage = nextMessage?.content[0]?.type === CHATBOT_USER_MESSAGE_TYPES.accountingFile;
        const userUploadedFilesMessage = !nextMessageIsFileMessage ? 'Vous avez partagé un fichier' : `Vous avez partagé plusieurs fichiers`;

        let assistantChanged;
        let messageAssistant;
        // We want to display the sentence "Assistant has changed" BEFORE the user message.
        // So, in order to know if assistant has changed, we have to check the next assistant message
        if (message.role === CHATBOT_MESSAGES_ROLES.user && index < orderedByDateMessages.length) {
          if (nextMessage) {
            assistantChanged = hasAssistantChangedSinceLastMessage(threadMessages, nextMessage);
            messageAssistant = assistants.find(a => a.id === nextMessage?.assistant_id);
          }
        }

        return (
          <React.Fragment key={message.id}>
            {isFirstOfDay && (
              <Grid container justifyContent="center" alignItems="center" mt={2} mb={1}>
                <ConversationEventSubtitle text={formatFrenchDateVerboseFromTimeStamp(message.created_at)} />
              </Grid>
            )}
            {isAccountingFileMessage && isFirstFileMessage && (
              <Grid container justifyContent="center" alignItems="center" mt={2} mb={1}>
                <ConversationEventSubtitle text={userUploadedFilesMessage} />
              </Grid>
            )}
            {assistantChangedSinceLastMessage && isSendingMessage && isLastMessage && <AssistantSwitchedSentence assistant={selectedAssistant} />}
            {assistantChanged && <AssistantSwitchedSentence assistant={messageAssistant} />}

            {isAccountingFileMessage ? <UploadedFileMessage message={message} /> : <Message message={message} isFirstOfDay={isFirstOfDay} />}
          </React.Fragment>
        );
      })}

      {assistantChangedSinceLastMessage && !isSendingMessage && <AssistantSwitchedSentence assistant={selectedAssistant} />}

      {shouldDisplayAssistantIsAnsweringMessage && <AssistantIsAnsweringMessage step={assitantIsThinking ? CHATBOT_ASSISTANT_ANSWER_STATE.thinking : CHATBOT_ASSISTANT_ANSWER_STATE.answering} />}
    </Grid>
  );
};

export default Talk;
