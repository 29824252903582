import ReportIcon from '@mui/icons-material/Report';
import {createTheme, useTheme, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@mui/material';
import {bool, func} from 'prop-types';

import Button from '../form/buttons/Button/Button';

const createDialogTheme = parentTheme => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette)
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            padding: 1
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            padding: '24px'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            margin: '12px auto'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            textAlign: 'center',
            fontSize: 18
          }
        }
      }
    }
  });
};

const WarningDowngradingSubscriptionModal = ({onClose, isOpen}) => {
  const parentTheme = useTheme();
  const dialogTheme = createDialogTheme(parentTheme);

  const resetState = () => {
    onClose();
  };

  return (
    <ThemeProvider theme={dialogTheme}>
      <Dialog maxWidth="sm" onClose={resetState} open={isOpen} fullWidth>
        <DialogTitle>Abonnement en cours de modification</DialogTitle>
        <DialogContent dividers>
          <ReportIcon sx={{fontSize: 80, mb: 2}} color="warning" />

          <Typography color="text.secondary">
            Votre abonnement actuel <span style={{fontWeight: 'bold'}}>est déjà en cours de modification</span>.
          </Typography>
          <Typography color="text.secondary" mt={2}>
            Pour toute question, contactez-nous.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="large" color="secondary" onClick={resetState}>
            J'ai compris !
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

WarningDowngradingSubscriptionModal.defaultProps = {
  isOpen: false
};

WarningDowngradingSubscriptionModal.propTypes = {
  onClose: func.isRequired,
  isOpen: bool
};

export default WarningDowngradingSubscriptionModal;
