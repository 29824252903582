/* eslint-disable consistent-return */
import {useEffect, useMemo, useState} from 'react';

import {models} from 'powerbi-client';
import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, DEFAULT_PROJECTS_SCHEMAS, POWER_BI_PAGES, POWER_BI_REPORT_BOOKMARKS, SNACKBAR_ACTIONS, SPREADSHEET_TARGETS} from '../const';
import ReportContext from '../contexts/ReportContext';
import useHttp from '../hooks/misc/useHttp';
import useSnackbar from '../hooks/providers/useSnackbar';
import {getDistinctBudgetYearsFromData} from '../utils';

const ReportProvider = ({children}) => {
  const [reports, setReports] = useState([]);
  const [reportsFetchInProgress, setReportsFetchInProgress] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null); // Hack to prevent object from mutating for header select
  const [embedToken, setEmbedToken] = useState(null);
  const [isTokenError, setIsTokenError] = useState(false);
  const [tokenExpiration, setTokenExpiration] = useState(Date.now());
  const [createBudgetError, setCreateBudgetError] = useState('');
  const [autoSaveDisabled, setAutoSaveDisabled] = useState(JSON.parse(localStorage.getItem('autoSaveDisabled')));
  const [spreadsheetDataLoaded, setSpreadsheetDataLoaded] = useState(false);
  const [userCanUploadVisuals, setUserCanUploadVisuals] = useState(false);
  const [isCreatingOrUpdatingBudget, setIsCreatingOrUpdatingBudget] = useState(false);
  const [reportDataSuccessfullyUpdated, setReportDataSuccessfullyUpdated] = useState(false);
  const [isCreateOrUpdateBudgetFormOpen, setIsCreateOrUpdateBudgetFormOpen] = useState(false);
  const [isCreateBudgetForm, setIsCreateBudgetForm] = useState(false);
  const [isDeleteBudgetDialogOpen, setIsDeleteBudgetDialogOpen] = useState(false);
  const [spreadsheetMenuOpen, setSpreadsheetMenuOpen] = useState(false);
  const [isSpreadsheetModalOpen, setIsSpreadsheetModalOpen] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [selectedBudgetYears, setSelectedBudgetYears] = useState([]);
  const [alreadyTakenBudgetNames, setAlreadyTakenBudgetNames] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isReportInEditionMode, setIsReportInEditionMode] = useState(false);
  const [isEditableReportSaveAsModalOpen, setIsEditableReportSaveAsModalOpen] = useState(false);
  const [reportSelectedPage, setReportSelectedPage] = useState({});
  const [reportModule, setReportModule] = useState(null);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [shouldApplyPreviousFiltersStepCompleted, setShouldApplyPreviousFiltersStepCompleted] = useState(false);
  const [isApplyPreviousFiltersModalOpen, setIsApplyPreviousFiltersModalOpen] = useState(false);
  const [isSaveReportModalOpen, setIsSaveReportModalOpen] = useState(false);

  const [currentBookmark, setCurrentBookmark] = useState(null);
  const [shouldRegenerateToken, setShouldRegenerateToken] = useState(false);
  const [numberOfFailedReportsFetching, setNumberOfFailedReportsFetching] = useState(0);
  const [lastBookmarkExceptFilters, setLastBookmarkExceptFilters] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);

  const {_post, _get} = useHttp();
  const {showSnackbar} = useSnackbar();

  // This hook aims to track which was the last "real" bookmark applied, excluding the filters bookmarks.
  // This is used to get track of current bookmark on DeFi Gestion Synthesis page for displaying:
  // - AccountantDocumentRequestButton
  // - CustomizeMappingButton
  useEffect(() => {
    (async () => {
      if (!selectedReport) return;

      const isSynthesisPage = reportSelectedPage.name === POWER_BI_PAGES.reportSynthesis.id;

      if (bookmarks.length === 0) {
        const b = await selectedReport.bookmarksManager.getBookmarks();
        setBookmarks(b);
      }

      const openOrCloseFiltersBookmarks = bookmarks.find(b => b.name === POWER_BI_REPORT_BOOKMARKS.openOrCloseFilters.name)?.children;
      const isAnOpenOrCloseFiltersBookmark = openOrCloseFiltersBookmarks?.find(b => b.name === currentBookmark) !== undefined;
      const isTreasuryBookmark = currentBookmark === POWER_BI_REPORT_BOOKMARKS.treasuryDefiGestion.name;
      const isProjectChoiceBookmark = currentBookmark === POWER_BI_REPORT_BOOKMARKS.projectChoiceDefiGestion.name;

      if (isSynthesisPage && !isAnOpenOrCloseFiltersBookmark && !isTreasuryBookmark && !isProjectChoiceBookmark) {
        setLastBookmarkExceptFilters(currentBookmark);
      }
    })();
  }, [currentBookmark]);

  // eslint-disable-next-line complexity
  const loadReports = async () => {
    if (reportsFetchInProgress) return;
    setReportsFetchInProgress(true);

    const url = API_ENDPOINTS.reports.getToken;
    const {response: tokenResponse, responseJson: tokenJson} = await _get(url);

    if (!tokenResponse.ok) {
      if (tokenResponse.status !== 401) {
        // eslint-disable-next-line no-console
        console.error('failed to get embed token from python');
      }

      setReportsFetchInProgress(false);

      // Business rule, 400 means user imported a report that contains RLS but without giving necessary information on RLS in import form
      if (tokenResponse.status === 400) {
        setIsTokenError(false);
        setEmbedToken(tokenJson.token);
        setTokenExpiration(tokenJson.expiration);
        setReports((tokenJson.reports || []).sort((a, b) => a.report_name.localeCompare(b.report_name)));
      }

      // Business rule, we will fetch reports + token up to 4 times if API returns a 404
      if (tokenResponse.status === 404 && numberOfFailedReportsFetching <= 4) {
        setNumberOfFailedReportsFetching(currentNumber => currentNumber + 1);
        setTimeout(() => setShouldRegenerateToken(true), 10000);
      }

      return {success: false};
    }

    try {
      if (tokenJson && tokenResponse.status === 200) {
        setIsTokenError(false);
        setEmbedToken(tokenJson.token);
        setReports(tokenJson.reports.sort((a, b) => a.report_name.localeCompare(b.report_name)));
        setTokenExpiration(tokenJson.expiration);
        setReportsFetchInProgress(false);
        return {success: true, tokenData: tokenJson};
      }
      if (tokenJson && tokenResponse.status === 201) {
        setReports([]);
        setReportsFetchInProgress(false);
        return {success: false};
      }
    } catch (_e) {
      setIsTokenError(true);
      setReportsFetchInProgress(false);
      return {success: false};
    }
  };

  // For more info on business need , see : https://github.com/Adrian1903/drivn-powerbi-embed/issues/316
  const createDefiPreviReportBudgetData = async (siren, {budgetName, budgetYear, budgetReferenceYear}) => {
    const url = API_ENDPOINTS.reports.createDefiPreviReportBudgetData;
    setCreateBudgetError('');
    try {
      const {response} = await _post(url, {
        siren,
        budget_name: budgetName,
        budget_year: budgetYear,
        reference_year: budgetReferenceYear
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.CREATE_DEFI_PREVI_BUDGET_SUCCESS);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const getSpreadsheetData = async (siren, target) => {
    const url = API_ENDPOINTS.reports.getSpreadsheetData;
    setCreateBudgetError('');
    setSelectedBudgetYears([]);
    try {
      const {response, responseJson: data} = await _post(url, {
        siren,
        target // 'forecast' or 'budget'
      });

      if (target === SPREADSHEET_TARGETS.BUDGET) {
        const budgetYears = getDistinctBudgetYearsFromData(data);
        setSelectedBudgetYears(budgetYears);
      }

      return {data, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const saveUpdatedSpreadsheetData = async (siren, target, editedData, budget = null) => {
    const url = API_ENDPOINTS.reports.updateSpreadsheetData;
    try {
      const {response, responseJson: data} = await _post(url, {
        siren,
        target, // 'forecast' or 'budget',
        data: editedData,
        budget_name: budget
      });

      if (response.status === 400) {
        showSnackbar(data, {
          severity: 'error',
          duration: 5000
        });
      }

      return {status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const saveAsReport = async (workspaceId, schema, reportName) => {
    const url = API_ENDPOINTS.reports.saveAs;
    try {
      const {response} = await _post(url, {
        workspace_id: workspaceId,
        schema,
        report_name: reportName
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.REPORT_SUCCESSFULLY_SAVED);
      }

      return {status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const enableOrDisableAutoSave = () => {
    localStorage.setItem('autoSaveDisabled', !autoSaveDisabled);
    setAutoSaveDisabled(!autoSaveDisabled);
  };

  const createOrUpdateBudget = async ({budgetName: newBudgetName, budgetYear, budgetReferenceYear, siren}) => {
    setIsCreatingOrUpdatingBudget(true);
    const result = await createDefiPreviReportBudgetData(siren, {
      budgetName: newBudgetName,
      budgetYear,
      budgetReferenceYear
    });
    if (result.status === 200) {
      selectedReport.refresh();
      setSpreadsheetDataLoaded(false);
      setIsCreateOrUpdateBudgetFormOpen(false);
      setSpreadsheetMenuOpen(false);
    }
    setIsCreatingOrUpdatingBudget(false);
  };

  const deleteBudget = async ({budgetName, budgetYear = null, siren}) => {
    const url = API_ENDPOINTS.reports.deleteDefiPreviReportBudgetData;
    try {
      const {response} = await _post(url, {
        siren,
        budget_name: budgetName,
        ...(budgetYear && {budget_year: parseInt(budgetYear, 10)})
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.DELETE_DEFI_PREVI_BUDGET_SUCCESS);
        selectedReport.refresh();
        setSpreadsheetDataLoaded(false);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const sendAccountantDocumentRequest = async ({siren, data, userId, userEmail}) => {
    const url = API_ENDPOINTS.reports.sendDocumentRequest;
    try {
      const {response} = await _post(url, {
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion,
        siren,
        content: data,
        ...(userId && {to_user_id: userId}),
        ...(userEmail && {to_usermail: userEmail})
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.SEND_DOCUMENT_REQUEST_SUCCESS);
      }

      return {data, success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const isReportEditable = reports?.find(r => r.report_id === selectedReportId)?.isEditable || false;
  const isDesktopLayout = selectedReport?.config.settings.layoutType === models.LayoutType.Master;

  const value = useMemo(
    () => ({
      reports,
      setReports,
      selectedReport,
      setSelectedReport,
      selectedReportId,
      setSelectedReportId,
      embedToken,
      loadReports,
      isTokenError,
      tokenExpiration,
      setTokenExpiration,
      createDefiPreviReportBudgetData,
      createBudgetError,
      setCreateBudgetError,
      getSpreadsheetData,
      saveUpdatedSpreadsheetData,
      enableOrDisableAutoSave,
      autoSaveDisabled,
      spreadsheetDataLoaded,
      setSpreadsheetDataLoaded,
      saveAsReport,
      userCanUploadVisuals,
      setUserCanUploadVisuals,
      isCreatingOrUpdatingBudget,
      setIsCreatingOrUpdatingBudget,
      reportDataSuccessfullyUpdated,
      setReportDataSuccessfullyUpdated,
      isCreateOrUpdateBudgetFormOpen,
      setIsCreateOrUpdateBudgetFormOpen,
      createOrUpdateBudget,
      isCreateBudgetForm,
      setIsCreateBudgetForm,
      isDeleteBudgetDialogOpen,
      setIsDeleteBudgetDialogOpen,
      deleteBudget,
      spreadsheetMenuOpen,
      setSpreadsheetMenuOpen,
      isSpreadsheetModalOpen,
      setIsSpreadsheetModalOpen,
      selectedBudget,
      setSelectedBudget,
      selectedBudgetYears,
      alreadyTakenBudgetNames,
      setAlreadyTakenBudgetNames,
      selectedProject,
      setSelectedProject,
      isReportInEditionMode,
      setIsReportInEditionMode,
      isEditableReportSaveAsModalOpen,
      setIsEditableReportSaveAsModalOpen,
      reportSelectedPage,
      setReportSelectedPage,
      reportModule,
      setReportModule,
      isReportEditable,
      filtersInitialized,
      setFiltersInitialized,
      filtersLoading,
      setFiltersLoading,
      shouldApplyPreviousFiltersStepCompleted,
      setShouldApplyPreviousFiltersStepCompleted,
      isApplyPreviousFiltersModalOpen,
      setIsApplyPreviousFiltersModalOpen,
      isDesktopLayout,
      currentBookmark,
      setCurrentBookmark,
      sendAccountantDocumentRequest,
      shouldRegenerateToken,
      setShouldRegenerateToken,
      isSaveReportModalOpen,
      setIsSaveReportModalOpen,
      lastBookmarkExceptFilters
    }),
    [
      reports,
      setReports,
      selectedReport?.config.id,
      selectedReportId,
      setSelectedReport,
      embedToken,
      isTokenError,
      loadReports,
      createDefiPreviReportBudgetData,
      createBudgetError,
      setCreateBudgetError,
      getSpreadsheetData,
      saveUpdatedSpreadsheetData,
      enableOrDisableAutoSave,
      autoSaveDisabled,
      spreadsheetDataLoaded,
      setSpreadsheetDataLoaded,
      saveAsReport,
      userCanUploadVisuals,
      setUserCanUploadVisuals,
      isCreatingOrUpdatingBudget,
      setIsCreatingOrUpdatingBudget,
      reportDataSuccessfullyUpdated,
      setReportDataSuccessfullyUpdated,
      isCreateOrUpdateBudgetFormOpen,
      setIsCreateOrUpdateBudgetFormOpen,
      createOrUpdateBudget,
      isCreateBudgetForm,
      setIsCreateBudgetForm,
      isDeleteBudgetDialogOpen,
      setIsDeleteBudgetDialogOpen,
      deleteBudget,
      spreadsheetMenuOpen,
      setSpreadsheetMenuOpen,
      isSpreadsheetModalOpen,
      setIsSpreadsheetModalOpen,
      selectedBudget,
      setSelectedBudget,
      selectedBudgetYears,
      alreadyTakenBudgetNames,
      setAlreadyTakenBudgetNames,
      selectedProject,
      setSelectedProject,
      isReportInEditionMode,
      setIsReportInEditionMode,
      isEditableReportSaveAsModalOpen,
      setIsEditableReportSaveAsModalOpen,
      reportSelectedPage,
      setReportSelectedPage,
      reportModule,
      setReportModule,
      isReportEditable,
      filtersInitialized,
      setFiltersInitialized,
      filtersLoading,
      setFiltersLoading,
      shouldApplyPreviousFiltersStepCompleted,
      setShouldApplyPreviousFiltersStepCompleted,
      isApplyPreviousFiltersModalOpen,
      setIsApplyPreviousFiltersModalOpen,
      isDesktopLayout,
      currentBookmark,
      setCurrentBookmark,
      sendAccountantDocumentRequest,
      shouldRegenerateToken,
      setShouldRegenerateToken,
      isSaveReportModalOpen,
      setIsSaveReportModalOpen,
      lastBookmarkExceptFilters
    ]
  );

  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
};
ReportProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ReportProvider;
