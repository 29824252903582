import {useEffect, useState} from 'react';

import Box from '@mui/material/Box';

import useProjects from '../../../../../hooks/providers/useProjects';
import ErrorMessage from '../../../../form/ErrorMessage/ErrorMessage';
import Spinner from '../../../../spinner/Spinner';
import LinkToConnectorLoginTutorial from '../LinkToConnectorLoginTutorial/LinkToConnectorLoginTutorial';
import LoginToConnectorForm from '../LoginToConnectorForm/LoginToConnectorForm';

const LoginToConnector = () => {
  const [isLogging, setIsLogging] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [isCheckingConnectorConnection, setIsCheckingConnectorConnection] = useState(true);

  const {loginToConnector, setIsSuccessfullyLoggedInToConnector, checkConnectorConnection, connector, loginToConnectorFormData: formData} = useProjects();

  const handleSubmit = async () => {
    setLoginErrorMessage('');
    setIsLogging(true);
    const resLogin = await loginToConnector(connector.id, formData);
    setIsLogging(false);

    if (resLogin.success) {
      setIsSuccessfullyLoggedInToConnector(true);
    } else {
      setLoginErrorMessage(resLogin.data);
    }
  };

  // This hook aims to check if user has already logged-in into this connector
  useEffect(() => {
    (async () => {
      await checkConnectorConnection(connector.id);
      setIsCheckingConnectorConnection(false);
    })();
  }, []);

  return (
    <Box>
      <LinkToConnectorLoginTutorial tutorialUrl={connector.url_help} isLoading={isCheckingConnectorConnection || isLogging} />

      {isCheckingConnectorConnection && <Spinner isLoading text="Accès aux dossiers..." />}

      <LoginToConnectorForm handleSubmit={handleSubmit} isLogging={isLogging} isCheckingConnectorConnection={isCheckingConnectorConnection} />

      {isLogging && <Spinner isLoading text="Connexion en cours..." />}

      {loginErrorMessage !== '' && <ErrorMessage message={loginErrorMessage} />}
    </Box>
  );
};

export default LoginToConnector;
