import {POWER_BI_PAGES} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import {deleteAllFiltersFromLocalStorage} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import ReportSpinner from './ReportSpinner';

const FiltersLoadingSpinner = () => {
  const {filtersLoading, selectedReport, setFiltersLoading, setFiltersInitialized} = useReport();

  const cancelFiltersInitialization = async () => {
    await selectedReport.setPage(POWER_BI_PAGES.reportHome.id);
    setFiltersLoading(false);
    setFiltersInitialized(true);
    deleteAllFiltersFromLocalStorage();
  };

  if (filtersLoading) {
    return (
      <ReportSpinner text="Initialisation du rapport à l'aide des précédents filtres configurés ...">
        <Button sx={{mt: 2, margin: '0 auto'}} variant="contained" color="error" onClick={cancelFiltersInitialization}>
          Annuler l'application des filtres
        </Button>
      </ReportSpinner>
    );
  }

  return null;
};

export default FiltersLoadingSpinner;
