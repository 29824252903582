import {useEffect, useState} from 'react';

import {DialogContent, Grid} from '@mui/material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';

import useProjects from '../../../../hooks/providers/useProjects';
import Button from '../../../form/buttons/Button/Button';
import BaseModal from '../../../modal/BaseModal';

const ActualizeDataDatePicker = () => {
  const {closeProjectMenu, actualizeProjectDataDatePickerOpen, setActualizeProjectDataDatePickerOpen, runManualProjectDataRefresh, selectedProject: project} = useProjects();
  const [date, setDate] = useState(dayjs());
  const formattedPickedDate = date.format('DD/MM/YYYY');

  // This hook aims to reset date to today after datepicker opening / closing
  useEffect(() => {
    if (actualizeProjectDataDatePickerOpen) {
      setDate(dayjs());
    }
  }, [actualizeProjectDataDatePickerOpen]);

  const handleSubmit = () => {
    setActualizeProjectDataDatePickerOpen(false);
    const nextDayDate = date.add(1, 'day'); // API requires next day that the one picked by user
    const formattedDateForApi = nextDayDate.format('YYYY-MM-DD');
    runManualProjectDataRefresh(project, formattedDateForApi);
    closeProjectMenu();
  };

  const minimumYear = 2020;
  const currentYear = dayjs().year();

  const minDate = dayjs(`${minimumYear}-01-01`);
  const maxDate = dayjs(`${currentYear}-12-31`);

  return (
    <BaseModal maxWidth="xs" onClose={() => setActualizeProjectDataDatePickerOpen(false)} open={actualizeProjectDataDatePickerOpen} title="Choix de la date d'actualisation">
      <DialogContent
        sx={{
          margin: '0 auto'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            views={['year', 'month', 'day']}
            minDate={minDate}
            maxDate={maxDate}
            sx={{
              '& .MuiDateCalendar-root': {
                height: 'auto'
              }
            }}
            slots={{
              actionBar: () => {
                return null;
              }
            }}
            open
            value={date}
            onChange={newDate => {
              setDate(newDate);
            }}
          />
        </LocalizationProvider>

        <Grid container mb={2} mt={2} spacing={1}>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={handleSubmit}>
              Actualiser au {formattedPickedDate}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={() => setActualizeProjectDataDatePickerOpen(false)}>
              Fermer
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseModal>
  );
};

export default ActualizeDataDatePicker;
