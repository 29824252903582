import {Grid} from '@mui/material';
import {arrayOf, shape, string} from 'prop-types';

import CsvFileMessage from '../../CsvFileMessage/CsvFileMessage';

const MessageFiles = ({files}) => {
  return (
    <Grid container justifyContent="flex-end">
      {files?.map((file, index) => (
        <Grid item key={index} mt={1} ml={1}>
          <CsvFileMessage size="small" filename={file.filename} />
        </Grid>
      ))}
    </Grid>
  );
};

MessageFiles.defaultProps = {
  files: []
};

MessageFiles.propTypes = {
  files: arrayOf(
    shape({
      filename: string.isRequired
    })
  )
};

export default MessageFiles;
