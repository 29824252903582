import {useState} from 'react';

import {Fullscreen} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import styled from 'styled-components';

import HeaderContactButton from '../HeaderContactButton';
import HeaderPrintReportButton from '../HeaderPrintReportButton';
import HeaderStyledIconButton from '../HeaderStyledIconButton/HeaderStyledIconButton';
import HeaderUploadReportFileButton from '../HeaderUploadReportFileButton/HeaderUploadReportFileButton';

const canFullScreen = () => {
  const elem = document.documentElement;
  return !!(elem.requestFullscreen || elem.webkitRequestFullscreen || elem.webkitEnterFullscreen || elem.msRequestFullscreen);
};

// https://www.w3schools.com/howto/howto_js_fullscreen.asp
const enableFullScreen = () => {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.webkitEnterFullscreen) {
    /* iOS */
    elem.webkitEnterFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
};

const disableFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
};

const IconsContainer = styled.div`
  display: flex;
`;

const HeaderDesktopIcons = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (isFullScreen) {
      disableFullScreen();
    } else {
      enableFullScreen();
    }

    setIsFullScreen(!isFullScreen);
  };

  return (
    <IconsContainer>
      <HeaderUploadReportFileButton />

      <HeaderPrintReportButton />

      {canFullScreen() && (
        <HeaderStyledIconButton onClick={toggleFullScreen}>
          <Tooltip title="Passer en plein écran">
            <Fullscreen />
          </Tooltip>
        </HeaderStyledIconButton>
      )}

      <HeaderContactButton />
    </IconsContainer>
  );
};

export default HeaderDesktopIcons;
