import {Menu} from '@mui/material';
import {bool, element, func, node, object, oneOf, oneOfType} from 'prop-types';

import HeaderContactButton from './HeaderContactButton';
import HeaderPrintReportButton from './HeaderPrintReportButton';
import HeaderUploadReportFileButton from './HeaderUploadReportFileButton/HeaderUploadReportFileButton';

const HeaderMobileIconsMenu = ({onClose, anchorEl, isOpen}) => {
  return (
    <Menu
      data-testid="mobile-menu"
      // sx={{zIndex: 9999}}
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <HeaderUploadReportFileButton isMobile onClose={onClose} />
      <HeaderPrintReportButton isMobile />
      <HeaderContactButton isMobile onClose={onClose} />
    </Menu>
  );
};

HeaderMobileIconsMenu.defaultProps = {
  anchorEl: null
};

HeaderMobileIconsMenu.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  anchorEl: oneOfType([object, element, node, oneOf([null])])
};

export default HeaderMobileIconsMenu;
