import {ClickAwayListener, Paper, Popper} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {bool, element, func, node, object, oneOf, oneOfType} from 'prop-types';

import useReport from '../../../../hooks/providers/useReport';
import {projectShape} from '../../../../proptypes-shapes';

const ReportsList = ({project, isOpen, anchorEl, onClose, handleReportClick}) => {
  const {reports} = useReport();

  const getReportsList = () => {
    const reportsList = reports.filter(r => !r.isCustomModel).filter(r => r.schema_name.includes(project.schema_name));

    if (reportsList.length === 0) {
      return (
        <MenuItem>
          <ListItemText>Aucun rapport disponible</ListItemText>
        </MenuItem>
      );
    }

    return reportsList.map(r => {
      return (
        <MenuItem key={r.report_id} onClick={() => handleReportClick(r)}>
          <ListItemText>{r.report_name}</ListItemText>
        </MenuItem>
      );
    });
  };

  const reportsList = getReportsList();

  return (
    <Popper open={isOpen} anchorEl={anchorEl}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper
          elevation={2}
          sx={{
            borderRadius: 2.5,
            minWidth: 150
          }}
        >
          {reportsList}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

ReportsList.defaultProps = {
  anchorEl: null
};

ReportsList.propTypes = {
  project: projectShape.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  handleReportClick: func.isRequired,
  anchorEl: oneOfType([object, element, node, oneOf([null])])
};

export default ReportsList;
