import {useEffect} from 'react';

import {Grid, MenuItem, TextField, Typography} from '@mui/material';
import {bool, func} from 'prop-types';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import {getHumanReadableSiren, getTruncatedString} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import Spinner from '../../spinner/Spinner';

const CustomWorkspaceProjectForm = ({handleSubmit, isSubmitting}) => {
  const {isLoading, setSiren, siren, projects, selectedReportTab} = useProjects();
  const {selectedWorkspace} = useWorkspaces();

  const reportName = selectedWorkspace.reports?.find(r => r.report_id === selectedReportTab)?.report_name;

  // Businness rule: user can only create a project in a custom workspace report from DeFi Gestion projects
  const defaultSchemasProjects = projects.filter(p => p.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion);
  // Businness rule: user can only create a project in a custom workspace report if he's the project owner
  const projectsOwnedByUser = defaultSchemasProjects?.filter(p => p.is_owner);
  // Businness rule: user can only create a project in a custom if this projects hasn't been already created for this report
  const notAlreadyExistingProjects = projectsOwnedByUser.filter(p => !projects.find(i => i.report_id === selectedReportTab && p.siren === i.siren));

  useEffect(() => {
    setSiren('');
  }, []);

  return (
    <>
      <Typography sx={{fontSize: 14, mb: 3}}>
        Pour ajouter une nouvelle connexion dans{' '}
        <Typography component="span" fontSize={13} fontFamily="InstrumentSansBold">
          "{reportName}"
        </Typography>{' '}
        , choisissez-la dans la liste ci-dessous :
      </Typography>

      {isLoading ? (
        <Spinner text="Chargement des connexions" isLoading={isLoading && !isSubmitting} />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} sm flex={1}>
            <TextField size="small" sx={{mb: 2}} onChange={e => setSiren(e.target.value)} fullWidth disabled={isSubmitting} id="outlined-select-project" select label="Choisissez une connexion ...">
              {notAlreadyExistingProjects.length === 0 && (
                <MenuItem disabled value={null}>
                  Pas de connexion
                </MenuItem>
              )}
              {notAlreadyExistingProjects.map(project => (
                <MenuItem key={project.siren} value={project.siren}>
                  {getTruncatedString(project.project_name)} ({getHumanReadableSiren(project.siren)})
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm="auto">
            <Button disabled={!siren || isSubmitting} color="primary" variant="contained" onClick={handleSubmit}>
              Ajouter la connexion
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

CustomWorkspaceProjectForm.propTypes = {
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired
};

export default CustomWorkspaceProjectForm;
