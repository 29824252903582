import React from 'react';

import Typography from '@mui/material/Typography';
import {func, node, oneOfType, string} from 'prop-types';

const FirstLetterCapitalizedText = ({children = null, component: Component = Typography, ...props}) => {
  if (children == null) {
    return null; // Return null if children is null or undefined
  }

  // Capitalize only the first letter of the text
  const capitalizeFirstLetter = text => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  // Recursive function to traverse through children
  const processChildren = child => {
    if (typeof child === 'string') {
      // If child is a string, apply capitalization
      return capitalizeFirstLetter(child);
    }
    if (Array.isArray(child)) {
      // If child is an array, map over each element recursively
      return child.map(processChildren);
    }
    if (React.isValidElement(child) && child.props.children) {
      // If child is a valid React element with children, process its children recursively
      return React.cloneElement(child, {
        children: processChildren(child.props.children)
      });
    }
    // Return the child as is if it doesn't match any of the above conditions
    return child;
  };

  // Process children using the recursive function
  const processedChildren = React.Children.map(children, processChildren);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props}>{processedChildren}</Component>;
};

FirstLetterCapitalizedText.propTypes = {
  children: oneOfType([node, func]),
  component: oneOfType([func, string])
};

export default FirstLetterCapitalizedText;
