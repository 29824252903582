import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import {Divider, Menu, Tooltip, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import useChatbot from '../../../hooks/providers/useChatbot';

const ChatbotSingleThreadMenu = () => {
  const theme = useTheme();
  const {threadMenuAnchorEl: anchorEl, setIsDeleteThreadModalOpen, isThreadMenuOpen: isOpen, closeThreadMenu: onClose} = useChatbot();

  return (
    <Menu
      sx={{zIndex: 9999}}
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Tooltip placement="top" arrow title="Bientôt disponible">
        <Box mb={0.5}>
          <MenuItem disabled>
            <ListItemIcon sx={{color: theme.palette.primary.main}}>
              <AddCircleOutlineRoundedIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Partager la conversation</ListItemText>
          </MenuItem>
        </Box>
      </Tooltip>

      <Divider />
      <MenuItem
        onClick={() => {
          setIsDeleteThreadModalOpen(true);
          onClose();
        }}
      >
        <ListItemIcon color={theme.palette.error.main}>
          <DeleteForeverIcon fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{color: 'error'}}>Supprimer la conversation</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default ChatbotSingleThreadMenu;
