import {useEffect, useState} from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {DialogContent, useTheme, Box, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {CONNECTORS_ON_CLICK_ACTIONS} from '../../../const';
import useContact from '../../../hooks/providers/useContact';
import useProjects from '../../../hooks/providers/useProjects';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import CustomAlert from '../../alert/Alert';
import OpenContactFormButton from '../../contact/OpenContactFormButton/OpenContactFormButton';
import CustomDimmer from '../../dimmer/Dimmer';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import LoginToConnector from './connector-login/LoginToConnector/LoginToConnector';
import ConnectorFoldersListForm from './ConnectorFoldersListForm/ConnectorFoldersListForm';
import Connectors from './Connectors/Connectors';
import ManualConnectorForm from './ManualConnectorForm';

const ModalTitleConnectorLogo = styled.img`
  max-height: 25px;
`;

// eslint-disable-next-line complexity
const CreateDatalakeConnectionModal = () => {
  const [creationStep, setCreationStep] = useState(0);

  const {
    isSubmitting,
    connector,
    setConnector,
    setLoginToConnectorFormData,
    setIsSuccessfullyLoggedInToConnector,
    isSuccessfullyLoggedInToConnector,
    setSiren,
    createProjectModalOpen,
    setCreateProjectModalOpen,
    isConnectorsLoading,
    isRedirectingToTiimeAuthenticationServer,
    setFoldersCompanyIdsToConnect
  } = useProjects();
  const {openAskConnectorOpeningContactForm} = useContact();
  const {isDefaultWorkspace} = useWorkspaces();

  const parentTheme = useTheme();
  const location = useLocation();

  const open = createProjectModalOpen && isDefaultWorkspace;

  const onClose = () => {
    setCreateProjectModalOpen(false);
  };

  const resetFormState = () => {
    setLoginToConnectorFormData({});
    setFoldersCompanyIdsToConnect([]);
  };

  useEffect(() => {
    if (!open) {
      setConnector(null);
      setCreationStep(0);
    }

    resetFormState();
  }, [open]);

  useEffect(() => {
    // Once user has selected a connector, we get to the next form step
    if (connector) {
      setCreationStep(1);

      // When being redirected from Tiime OAuth callback page with isTiimeProjectCreationModalOpen: true, we know that user has successfully
      // Logged-in into his Tiime account, so we do no call setIsSuccessfullyLoggedInToConnector(false)
      if (!location.state?.isTiimeProjectCreationModalOpen) {
        setIsSuccessfullyLoggedInToConnector(false);
      }
    }
  }, [connector]);

  useEffect(() => {
    if (connector && connector.open_on_click === CONNECTORS_ON_CLICK_ACTIONS.OPEN_CONTACT_FORM) {
      onClose();
      openAskConnectorOpeningContactForm(connector);
    }
  }, [connector]);

  // We reset errors when switching connector in form
  // because error is shared between all connectors
  useEffect(() => {
    setSiren('');
    resetFormState();
  }, [connector]);

  const getModalTitle = () => {
    if (creationStep === 0) {
      return 'Créer une connexion';
    }

    if (creationStep === 1 && connector.is_manual) {
      return 'Importer manuellement';
    }

    return <ModalTitleConnectorLogo alt="logo connecteur" src={connector.url_logo} />;
  };

  const shouldDisplayConnectors = creationStep === 0 && !isSubmitting;
  const shouldDisplayManualConnectorForm = creationStep === 1 && !isSuccessfullyLoggedInToConnector && connector.is_manual;
  const shouldDisplayLoginForm =
    creationStep === 1 && !isSuccessfullyLoggedInToConnector && !connector.is_manual && connector.open_on_click === CONNECTORS_ON_CLICK_ACTIONS.OPEN_CREATE_CONNECTION_FORM;
  const shouldDisplayFoldersListForm = creationStep === 1 && isSuccessfullyLoggedInToConnector;

  const title = getModalTitle();

  return (
    <BaseModal hasTitleDivider={creationStep === 1} onClose={onClose} open={open} title={title}>
      {isConnectorsLoading ? (
        <Spinner isLoading text="Chargement des connecteurs..." />
      ) : (
        <>
          <CustomDimmer isOpen={isRedirectingToTiimeAuthenticationServer} spinnerText="Connexion à Tiime" />
          <DialogContent>
            {creationStep === 1 && (
              <Box sx={{position: 'sticky', top: '-8px', background: 'white', zIndex: 2}}>
                <Button
                  sx={{
                    color: 'text.primary'
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    setConnector(null);
                    setCreationStep(0);
                  }}
                >
                  Retour
                </Button>
              </Box>
            )}

            {shouldDisplayConnectors ? <Connectors /> : null}

            <Box py={3}>
              {shouldDisplayManualConnectorForm ? <ManualConnectorForm /> : null}
              {shouldDisplayLoginForm && !location.state?.isTiimeProjectCreationModalOpen ? <LoginToConnector /> : null}
              {shouldDisplayFoldersListForm ? <ConnectorFoldersListForm /> : null}
            </Box>
          </DialogContent>

          <CustomAlert sx={{background: parentTheme.palette.primary.light, color: grey[700]}}>
            <Typography fontSize={14}>Vous souhaitez un nouveau connecteur ?&nbsp;</Typography>
            <OpenContactFormButton text="Contactez-nous !" />
          </CustomAlert>
        </>
      )}
    </BaseModal>
  );
};

export default CreateDatalakeConnectionModal;
