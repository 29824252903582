import {Grid} from '@mui/material';

import useChatbot from '../../../hooks/providers/useChatbot';
import Spinner from '../../spinner/Spinner';
import SwitchAssistantWidget from '../SwitchAssistantWidget/SwitchAssistantWidget';
import Talk from '../Talk/Talk';

const ConversationContent = () => {
  const {selectedAssistant, isThreadLoading} = useChatbot();

  return (
    <>
      <Grid item sx={{overflow: 'hidden', display: 'flex', flex: isThreadLoading ? 1 : 'none'}}>
        {isThreadLoading ? (
          <Grid container justifyContent="center" alignItems="center" flex={1}>
            <Spinner text="Chargement de la conversation" size={128} isLoading />
          </Grid>
        ) : (
          <Talk />
        )}
      </Grid>

      {selectedAssistant && !isThreadLoading && (
        <Grid item mb={2} flex={1}>
          <SwitchAssistantWidget />
        </Grid>
      )}
    </>
  );
};

export default ConversationContent;
