import {useState} from 'react';

import {Box, Grid} from '@mui/material';

import useProjects from '../../../hooks/providers/useProjects';
import {generateFormFields} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import FormUppercaseLabel from '../../form/FormUppercaseLabel/FormUppercaseLabel';
import Spinner from '../../spinner/Spinner';

const ManualConnectorForm = () => {
  const {
    createConnection,
    connector,
    loginToConnectorFormData: formData,
    setLoginToConnectorFormData: setFormData,
    isSirenValid,
    handleSirenChange,
    siren,
    isSubmitting,
    setIsSubmitting
  } = useProjects();

  const [files, setFiles] = useState([]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await createConnection(connector.id, [{siren}], files);
    setIsSubmitting(false);
  };

  if (isSubmitting) return <Spinner text="Envoi des données" isLoading />;

  const formFields = generateFormFields({connector, formData, setFormData, setFiles, handleSirenChange});

  return (
    <Box>
      {formFields}

      {connector.compatible_editors ? (
        <Box mb={3}>
          <FormUppercaseLabel label="Fichiers compatibles" />

          <Grid justifyContent="center" container flexWrap="nowrap" mt={1}>
            {connector.compatible_editors.map(c => (
              <Grid
                component={c.url_help ? 'a' : 'div'}
                href={c.url_help}
                target="_blank"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr={1}
                item
                xs={3}
                sx={{
                  cursor: c.url_help ? 'pointer' : 'inherit',
                  height: 48,
                  boxShadow: '0px 0px 5px #00000029',
                  borderRadius: 2.5
                }}
              >
                <img style={{maxWidth: '90%', maxHeight: '100%'}} src={c.url_logo} alt={`logo ${c.name}`} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : null}

      <Grid container justifyContent="center" mt={2}>
        <Grid item xs={12} sm={6}>
          <Button disabled={isSubmitting || files.length === 0 || !isSirenValid} fullWidth onClick={handleSubmit} variant="contained" color="secondary" size="large">
            Importer vos données
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManualConnectorForm;
