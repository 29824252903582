import Box from '@mui/material/Box';
import {oneOf} from 'prop-types';

import {REPORT_LOADING_STEPS} from '../../../const';
import LinkToRse from '../../spinner/LinkToRse';
import ReportSpinner from './ReportSpinner';

const ReportLoadingSpinner = ({loadingStep}) => {
  const getMessage = () => {
    if (loadingStep === REPORT_LOADING_STEPS.REPORT_LOADING) {
      return 'Chargement du rapport en cours';
    }
    if (loadingStep === REPORT_LOADING_STEPS.PROJECT_BOOKMARK_APPLYING) {
      return 'Chargement de la connexion';
    }
  };

  const loadingMessage = getMessage();

  return (
    <ReportSpinner text={loadingMessage}>
      <Box mt={0.5}>
        <LinkToRse fontSize={13} />
      </Box>
    </ReportSpinner>
  );
};

ReportLoadingSpinner.propTypes = {
  loadingStep: oneOf(Object.values(REPORT_LOADING_STEPS)).isRequired
};

export default ReportLoadingSpinner;
