import {useEffect, useState} from 'react';

import {TextField, MenuItem, Box, Grid, DialogContent, useTheme, Typography} from '@mui/material';
import {func, bool} from 'prop-types';

import {CONNECTORS, DEFAULT_PROJECTS_SCHEMAS, SNACKBAR_ACTIONS} from '../../const';
import useAuth from '../../hooks/providers/useAuth';
import useProjects from '../../hooks/providers/useProjects';
import useReport from '../../hooks/providers/useReport';
import useSnackbar from '../../hooks/providers/useSnackbar';
import useUpload from '../../hooks/providers/useUpload';
import {getManualConnectorFilename, getHumanReadableSiren, getTruncatedString} from '../../utils';
import Alert from '../alert/Alert';
import OpenContactFormButton from '../contact/OpenContactFormButton/OpenContactFormButton';
import Button from '../form/buttons/Button/Button';
import ErrorMessage from '../form/ErrorMessage/ErrorMessage';
import BaseModal from '../modal/BaseModal';
import Spinner from '../spinner/Spinner';
import FileUploadProgress from './FileUploadProgress';
import UploadDropzone from './UploadDropzone';

const sortProjects = projects => {
  const manualProjects = projects.filter(p => p.connector?.toUpperCase() === CONNECTORS.manual);
  const otherProjects = projects.filter(p => p.connector?.toUpperCase() !== CONNECTORS.manual);
  return [...manualProjects, ...otherProjects];
};

// eslint-disable-next-line complexity
const UploadDialog = ({isOpen, closeModal}) => {
  const projectsContext = useProjects();
  const {selectedReport} = useReport();
  const {projectSelected, setProjectSelected, isUploadFilesModalOpen} = useUpload();
  const auth = useAuth();
  const {showSnackbar} = useSnackbar();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [numberOfFilesUploaded, setNumberOfFilesUploaded] = useState(0);
  const [fileUploadError, setFileUploadError] = useState('');
  const [sortedProjects, setSortedProjects] = useState([]);

  const resetState = () => {
    if (!isLoading) {
      setFiles(null);
      setNumberOfFilesUploaded(0);
      setIsLoading(false);
      closeModal();
      setProjectSelected('');
      setFileUploadError('');
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const uploadPromisesResults = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      const res = await projectsContext.importFecFile(projectSelected, file);
      setNumberOfFilesUploaded(prevCount => prevCount + 1);
      uploadPromisesResults.push(res);
    }

    setTimeout(() => {
      setIsLoading(false);
      setNumberOfFilesUploaded(0);
      const allFilesUploadedSuccessfully = uploadPromisesResults.every(res => res.status === 200);

      if (allFilesUploadedSuccessfully) {
        showSnackbar(SNACKBAR_ACTIONS.IMPORT_PROJECT_FILES_SUCCESS);
        if (selectedReport) {
          selectedReport?.refresh();
        }
        resetState();
      }
    }, 2500);
  };

  useEffect(() => {
    (async () => {
      setProjectSelected('');
      setFiles(null);
      if (auth?.user?.tokenAad && isOpen && projectsContext.projects?.length === 0) {
        await projectsContext.fetchProjects();
      }
    })();
  }, [isOpen]);

  useEffect(() => {
    setSortedProjects(sortProjects(projectsContext?.projects || []));
  }, [projectsContext.projects]);

  useEffect(() => {
    setProjectSelected(projectSelected || '');
  }, [isUploadFilesModalOpen]);

  useEffect(() => {
    setFileUploadError('');
    files?.forEach(async file => {
      try {
        await getManualConnectorFilename(file, projectSelected);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setFileUploadError('Une erreur est survenue. Veuillez réessayer de joindre vos fichiers.');
      }
    });
  }, [files]);

  const currentUserProjects = sortedProjects?.filter(p => p.is_owner && p.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion);

  return (
    <BaseModal onClose={resetState} open={isOpen} title="Importez vos fichiers">
      <DialogContent>
        <Spinner text="Chargement des connexions" isLoading={projectsContext.isLoading} />
        {!projectsContext.isLoading && (
          <Box mt={1} mb={2}>
            <TextField
              onChange={e => setProjectSelected(e.target.value)}
              fullWidth
              id="outlined-select-project"
              select
              label="Choisissez une connexion ..."
              value={projectSelected || ''}
              helperText={
                <Typography fontSize={13} mt={0.5}>
                  Glisser-déposer un ou plusieurs fichiers FEC.{' '}
                  <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000027804775" target="_blank" rel="noreferrer">
                    En savoir plus sur le format FEC
                  </a>
                </Typography>
              }
            >
              {currentUserProjects.length === 0 && (
                <MenuItem disabled value={null}>
                  Pas de connexion
                </MenuItem>
              )}
              {currentUserProjects.map(project => {
                const isProjectManual = project.connector?.toUpperCase() === CONNECTORS.manual;
                return (
                  <MenuItem disabled={!isProjectManual} key={project.siren} value={project.siren}>
                    {getTruncatedString(project.project_name)} ({getHumanReadableSiren(project.siren)}) {!isProjectManual && ' - Mise à jour automatique'}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        )}
        {projectSelected !== '' && !projectsContext.isLoading && !isLoading && (
          <Box pt={2} mb={2}>
            <UploadDropzone error={fileUploadError} onFilesUpload={setFiles} />
          </Box>
        )}

        {isLoading && (
          <Grid my={3} container alignItems="center" justifyContent="center" direction="column">
            <FileUploadProgress totalNumberOfFiles={files?.length} numberOfFileAlreadyUploaded={numberOfFilesUploaded} />
            <Typography sx={{mt: 1}} fontWeight="bold">
              Import des données en cours
            </Typography>
          </Grid>
        )}

        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={5}>
            <Button
              disabled={isLoading || !projectSelected || !files || files?.length === 0 || fileUploadError !== ''}
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
            >
              Importer vos données
            </Button>
          </Grid>
          {projectsContext?.importFecFileError !== '' && <ErrorMessage message={projectsContext?.importFecFileError} />}
        </Grid>
      </DialogContent>
      <Alert
        sx={{
          marginTop: theme.spacing(2),
          background: theme.palette.primary.light,
          color: theme.typography.color.main
        }}
      >
        <Typography fontSize={14}>Pour toute question,</Typography>
        <OpenContactFormButton text="Contactez-nous !" />
      </Alert>
    </BaseModal>
  );
};

UploadDialog.defaultProps = {
  isOpen: false
};

UploadDialog.propTypes = {
  isOpen: bool,
  closeModal: func.isRequired
};

export default UploadDialog;
