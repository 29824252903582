import {Box} from '@mui/material';
import {bool} from 'prop-types';

import DefaultAvatar from '../../../../img/default-avatar.png';
import {assistantShape} from '../../../../proptypes-shapes';
import AssistantPictureTooltip from '../../AssistantPictureTooltip/AssistantPictureTooltip';

const MessageAvatar = ({isMessageMine, assistant}) => {
  if (isMessageMine) {
    return <Box sx={{maxWidth: 40}} component="img" src={DefaultAvatar} alt="User Avatar" />;
  }
  return <AssistantPictureTooltip assistant={assistant} />;
};

MessageAvatar.propTypes = {
  isMessageMine: bool.isRequired,
  assistant: assistantShape.isRequired
};

export default MessageAvatar;
