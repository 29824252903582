import {Alert} from '@mui/material';
import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../../const';
import usePayment from '../../../hooks/providers/usePayment';

const ExpiredSubscriptionAlert = () => {
  const {userHasEndedTrialAndIsOwnerOfProjects, hasBeenSubscribedOnce} = usePayment();

  const shouldDisplayAlert = userHasEndedTrialAndIsOwnerOfProjects && hasBeenSubscribedOnce;
  return shouldDisplayAlert ? (
    <Alert severity="error">
      <Link to={APP_ROUTES.profile}>Choisissez un abonnement</Link>&nbsp; pour activer les services. <br />
      Supprimez les connexions créées pour profiter des connexions sur lesquelles vous avez été invité.
    </Alert>
  ) : null;
};

export default ExpiredSubscriptionAlert;
