import {Grid, Typography} from '@mui/material';

import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button/Button';

const PromptInstructionsBlock = () => {
  const {shouldCustomizeInstructions, setIsSummaryInstructionsModalOpen} = useReportSummary();

  const spanColor = shouldCustomizeInstructions ? 'warning.main' : 'success.main';
  const instructionsTypeLabel = shouldCustomizeInstructions ? 'personnalisées' : 'par défaut';

  return (
    <Grid item mb={1}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography fontFamily="InstrumentSansBold" fontSize={15}>
          Instructions{' '}
          <Typography component="span" color={spanColor} fontFamily="inherit" fontSize="inherit">
            {instructionsTypeLabel}
          </Typography>
        </Typography>
        <Grid item xs={3}>
          <Button onClick={() => setIsSummaryInstructionsModalOpen(true)} fullWidth size="small" variant="outlined">
            Modifier
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PromptInstructionsBlock;
