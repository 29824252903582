import {Alert, AlertTitle} from '@mui/material';
import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../../const';
import usePayment from '../../../hooks/providers/usePayment';
import useProfile from '../../../hooks/providers/useProfile';
import OpenContactFormButton from '../../contact/OpenContactFormButton/OpenContactFormButton';

const ExpiredTrialAlert = () => {
  const {isPartnerPolicy} = useProfile();
  const {hasBeenSubscribedOnce, userHasEndedTrialAndIsOwnerOfProjects} = usePayment();

  const shouldDisplayAlert = userHasEndedTrialAndIsOwnerOfProjects && !hasBeenSubscribedOnce;

  return shouldDisplayAlert ? (
    <Alert severity="error" variant="standard">
      <AlertTitle fontFamily="InstrumentSans">Votre période d’essai est terminée</AlertTitle>
      {isPartnerPolicy ? (
        <OpenContactFormButton text="Contactez-nous pour bénéficier de votre tarif préférentiel" />
      ) : (
        <>
          <Link to={APP_ROUTES.subscription}>Sélectionner un abonnement </Link> pour profiter pleinement des services de Drivn
        </>
      )}
    </Alert>
  ) : null;
};

export default ExpiredTrialAlert;
