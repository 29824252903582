import useTranscript from '../../../hooks/providers/useTranscript';
import Button from '../../form/buttons/Button/Button';

const SaveRecordButton = () => {
  const {handleStopRecording: stopRecording} = useTranscript();

  return (
    <Button size="small" variant="text" color="primary" onClick={stopRecording} sx={{textDecoration: 'underline', textUnderlineOffset: 2, mt: 1}}>
      Terminer l'enregistrement
    </Button>
  );
};

export default SaveRecordButton;
