/* eslint-disable react/jsx-props-no-spreading */
import {useEffect, useRef} from 'react';

import {TabContext} from '@mui/lab';
import {Grid, Hidden} from '@mui/material';
import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';

import {DEFAULT_WORKSPACE} from '../../const';
import useProfile from '../../hooks/providers/useProfile';
import useProjects from '../../hooks/providers/useProjects';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import CreateOrUpdateWorkspaceModal from '../workspaces/CreateOrUpdateWorkspaceModal';
import DeleteWorkspaceModal from '../workspaces/DeleteWorkspaceOrReportModal';
import WorkspacesTabsMenu from '../workspaces/WorkspacesTabsMenu';
import CreateWorkspaceButton from './CreateWorkspaceButton';
import StyledTab from './StyledTab';
import StyledTabs from './StyledTabs';
import TabLabel from './TabLabel';

const TabsContainer = muiStyled(Box)(({theme}) => ({
  borderBottom: '1px solid #E2E7EF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  padding: 0,
  [theme.breakpoints.down('md')]: {
    padding: 0
  }
}));

// eslint-disable-next-line react/prop-types,complexity
const WorkspacesTabs = () => {
  const {isLoading, setSelectedReportTab, isGuestMode} = useProjects();
  const {workspaces, selectedWorkspaceId, setSelectedWorkspaceId, openWorkspaceTabsMenu, rightClickedWorkspace, setRightClickedWorkspace} = useWorkspaces();
  const {profile} = useProfile();

  const rightToolbarRef = useRef(null);
  const rightToolbarWidth = rightToolbarRef.current?.offsetWidth;

  const handleTabRightClick = (e, workspace) => {
    e.preventDefault();
    setRightClickedWorkspace(workspace);
    openWorkspaceTabsMenu(e);
  };

  // Reset to default DeFi workspace on component mount
  useEffect(() => {
    setSelectedWorkspaceId(DEFAULT_WORKSPACE.workspace_id);
  }, []);

  const handleChange = (event, newSelectedWorkspaceId) => {
    setSelectedWorkspaceId(newSelectedWorkspaceId);
    const newSelectedWorkspace = workspaces.find(w => w.workspace_id === newSelectedWorkspaceId);
    if (newSelectedWorkspaceId !== DEFAULT_WORKSPACE.workspace_id) {
      const noticeReport = newSelectedWorkspace?.reports.find(r => r.is_notice_report);
      setSelectedReportTab(noticeReport?.report_id);
    }
  };

  if (!selectedWorkspaceId) return null;

  const workspacesStyledTabs = workspaces.map(workspace => {
    const {workspace_name: workspaceName, workspace_id: workspaceId, workspace_users: users} = workspace;
    const currentUser = users?.find(u => u.user_id === profile?.id);
    const isCurrentUserWorkspaceOwner = currentUser?.is_owner;

    const isDefaultWorkspace = workspaceId === DEFAULT_WORKSPACE.workspace_id;
    const isTabActive = selectedWorkspaceId === workspaceId;
    const canOpenMenu = isCurrentUserWorkspaceOwner && !isDefaultWorkspace;
    const onIconClick = canOpenMenu ? handleTabRightClick : null;
    const isLabelWithoutMenuFeature = isDefaultWorkspace || !isCurrentUserWorkspaceOwner;

    const label = isLabelWithoutMenuFeature ? workspaceName : <TabLabel onIconClick={e => onIconClick(e, workspace)} label={workspaceName} isActive={isTabActive} />;

    return <StyledTab wrapped key={workspaceId} isReportTab={false} disabled={isLoading} label={label} value={workspaceId} onContextMenu={e => canOpenMenu && handleTabRightClick(e, workspace)} />;
  });

  return (
    <Box sx={{typography: 'body1', width: '100%'}}>
      <TabContext value={selectedWorkspaceId}>
        <TabsContainer>
          <Grid container justifyContent="space-between" alignItems="center">
            {!isGuestMode && (
              <Hidden smDown>
                <Box ml={2} sx={{width: rightToolbarWidth}} />
              </Hidden>
            )}

            <StyledTabs isReportTabs={false} centered onChange={handleChange} aria-label="Sélecteur d'espace de travail">
              {workspacesStyledTabs}
            </StyledTabs>

            {!isGuestMode ? (
              <Box ref={rightToolbarRef}>
                <CreateWorkspaceButton />
              </Box>
            ) : null}

            {rightClickedWorkspace ? <WorkspacesTabsMenu workspace={rightClickedWorkspace} /> : null}
          </Grid>
        </TabsContainer>
      </TabContext>

      <DeleteWorkspaceModal />

      <CreateOrUpdateWorkspaceModal />
    </Box>
  );
};

export default WorkspacesTabs;
