import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import {Paper, Tooltip, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import {Link} from 'react-router-dom';

import {APP_ROUTES, MAXIMUM_NUMBER_OF_CHABOT_THREADS} from '../../../../const';
import useChatbot from '../../../../hooks/providers/useChatbot';
import usePayment from '../../../../hooks/providers/usePayment';
import Button from '../../../form/buttons/Button/Button';
import SearchBar from '../../../form/SearchBar/SearchBar';

const HeaderContainer = muiStyled(Paper)(() => ({
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 9999999,
  maxWidth: 300 - 16,
  padding: '16px 8px'
}));

const TalksListHeader = () => {
  const {openNewConversation, isThreadLoading, threads, chatbotDataLoading, threadNameSearched, setThreadNameSearched, setIsTalksListDrawerOpen, setChatbotSidebarOpen} = useChatbot();
  const {isTrial} = usePayment();

  const userHasReachedMaximumNumberOfConversations = isTrial && threads.length >= MAXIMUM_NUMBER_OF_CHABOT_THREADS;
  const newConversationButtonDisabled = isThreadLoading || userHasReachedMaximumNumberOfConversations || chatbotDataLoading;

  return (
    <HeaderContainer>
      <Tooltip
        enterTouchDelay={0}
        title={
          userHasReachedMaximumNumberOfConversations ? (
            <Box pt={0.5} pb={1}>
              <Typography fontSize={11} color="#FFF" mb={1}>
                Vous ne pouvez créer que {MAXIMUM_NUMBER_OF_CHABOT_THREADS} conversations en période d'essai.
              </Typography>
              <Link
                to={APP_ROUTES.subscription}
                onClick={() => {
                  setIsTalksListDrawerOpen(false);
                  setChatbotSidebarOpen(false);
                }}
              >
                Sélectionner un abonnement
              </Link>
              &nbsp;pour plus de conversations.
            </Box>
          ) : null
        }
      >
        <span>
          <Button
            disabled={newConversationButtonDisabled}
            sx={{mb: 1.5}}
            fullWidth
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<RateReviewOutlinedIcon />}
            onClick={openNewConversation}
          >
            Nouvelle discussion
          </Button>
        </span>
      </Tooltip>
      <SearchBar placeholder="Recherchez une conversation ..." value={threadNameSearched} setValue={setThreadNameSearched} sx={{padding: '0 14px !important', fontSize: 13}} />
    </HeaderContainer>
  );
};

export default TalksListHeader;
