import {Tooltip} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {BREAKPOINTS} from '../../../../const';
import useBreakpoint from '../../../../hooks/dom/useBreakpoint';
import usePayment from '../../../../hooks/providers/usePayment';
import useProfile from '../../../../hooks/providers/useProfile';

const RoundedBordersCircularProgress = styled(CircularProgress)(() => ({
  [`& .${circularProgressClasses.svg}`]: {
    strokeLinecap: 'round'
  }
}));

/**
 * Normalises value to be used by MUI Progress component (range 0-100)
 * @param value
 * @param maxValue
 * @returns {number}
 */
const normaliseValue = (value, maxValue) => {
  const minValue = 0;
  if (value <= minValue) {
    return 0;
  }
  if (value >= maxValue) {
    return 100;
  }
  return ((value - minValue) / (maxValue - minValue)) * 100;
};

const getCounterCssColor = (normalizedValue, isPartner) => {
  if (normalizedValue < 80 || isPartner) {
    return '#2BC5AB';
  }
  if (normalizedValue >= 80 && normalizedValue < 100) {
    return '#EA9C1F';
  }
  return '#DB434F';
};

// eslint-disable-next-line complexity
const getNumberOfProjectsFontSize = (maxNumberOfProjects, breakpoint) => {
  if (maxNumberOfProjects >= 100 && maxNumberOfProjects <= 999) {
    return breakpoint === BREAKPOINTS.xs ? 14 : 15;
  }
  if (maxNumberOfProjects >= 1000 && maxNumberOfProjects <= 9999) {
    return breakpoint === BREAKPOINTS.xs ? 12 : 13;
  }
  if (maxNumberOfProjects >= 10000 && maxNumberOfProjects <= 99999) {
    return breakpoint === BREAKPOINTS.xs ? 10 : 11;
  }
  return breakpoint === BREAKPOINTS.xs ? 14 : 16;
};

// eslint-disable-next-line complexity
const getCounterSize = (maxNumberOfProjects, breakpoint) => {
  if (maxNumberOfProjects >= 100 && maxNumberOfProjects <= 999) {
    return breakpoint === BREAKPOINTS.xs ? 65 : 75;
  }
  if (maxNumberOfProjects >= 1000 && maxNumberOfProjects <= 9999) {
    return breakpoint === BREAKPOINTS.xs ? 75 : 85;
  }

  if (maxNumberOfProjects >= 10000 && maxNumberOfProjects <= 99999) {
    return breakpoint === BREAKPOINTS.xs ? 85 : 95;
  }
  return breakpoint === BREAKPOINTS.xs ? 55 : 70;
};

const ConnectionsCounter = () => {
  const {maxNumberOfProjects, numberOfProjectsOwnedByUser} = usePayment();
  const {isPartner, profile} = useProfile();

  // Business rule : We want to display a full-filled circle for guest users
  const value = isPartner ? 100 : normaliseValue(numberOfProjectsOwnedByUser, maxNumberOfProjects);

  const color = getCounterCssColor(value, isPartner);
  const breakpoint = useBreakpoint();
  const size = getCounterSize(maxNumberOfProjects, breakpoint);
  const thickness = breakpoint === BREAKPOINTS.xs ? 3 : 4;

  const renderNumberOfProjectsText = () => {
    if (!profile) return null;

    if (isPartner) {
      const numberFontSize = getNumberOfProjectsFontSize(numberOfProjectsOwnedByUser, breakpoint);

      return (
        <Typography textAlign="center" lineHeight={1.5} variant="caption" component="div" fontSize={numberFontSize} fontWeight="bold">
          {numberOfProjectsOwnedByUser}
        </Typography>
      );
    }
    const numberFontSize = getNumberOfProjectsFontSize(maxNumberOfProjects, breakpoint);

    return (
      <Typography lineHeight={1} variant="caption" component="div" fontSize={numberFontSize} fontWeight="bold">
        {`${numberOfProjectsOwnedByUser} / ${maxNumberOfProjects}`}
      </Typography>
    );
  };

  return (
    <Tooltip placement="top" arrow title="Un dossier peut avoir plusieurs connexions">
      <Box sx={{position: 'relative', display: 'inline-flex'}}>
        <RoundedBordersCircularProgress data-testid="progressbar" size={size} variant="determinate" thickness={thickness} value={value} sx={{color, zIndex: 1}} />
        <RoundedBordersCircularProgress size={size} variant="determinate" thickness={thickness} value={100} sx={{position: 'absolute', color: 'lightgrey', zIndex: 0}} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          {renderNumberOfProjectsText()}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default ConnectionsCounter;
