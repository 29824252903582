import {useEffect, useState} from 'react';

import {TextField, DialogContent, DialogActions, ClickAwayListener} from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {func, bool} from 'prop-types';
import 'dayjs/locale/fr';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS, VAT_RATES} from '../../../const';
import {MOVEMENT_TYPES} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import FormSelect from '../../form/MuiSelect/MuiSelect';
import BaseModal from '../../modal/BaseModal';
import InputVAT from './InputVAT';

// eslint-disable-next-line complexity
const CreateForecastEntryModal = ({isOpen, closeModal, onSubmit}) => {
  const [vatRate, setVatRate] = useState(0.2);
  const [label, setLabel] = useState('');
  const [amount, setAmount] = useState('');
  const [movementType, setMovementType] = useState(MOVEMENT_TYPES[0]);
  const [date, setDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const resetValues = () => {
    setLabel('');
    setVatRate(0.2);
    setAmount('');
    setMovementType('');
    setDate(null);
  };

  const handleSubmit = () => {
    const data = {
      vatRate,
      label,
      amount: parseInt(amount, 10),
      movementType,
      date
    };

    onSubmit(data);
    closeModal();
  };

  useEffect(() => {
    resetValues();
  }, [isOpen]);

  const hasLabelError = label !== '' && !REGULAR_EXPRESSIONS.label.test(label);
  const hasAmountError = !REGULAR_EXPRESSIONS.positiveOrNegativeAmount.test(amount);
  const submitButtonDisabled = !label || !VAT_RATES.includes(vatRate) || !amount || hasAmountError || amount === '' || !movementType || !date || hasLabelError;

  return (
    <BaseModal
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      }}
      onClose={closeModal}
      open={isOpen}
      title="Ajouter une ligne"
    >
      <DialogContent>
        <TextField
          sx={{mb: 2, mt: 3}}
          required
          value={label}
          onChange={event => {
            setLabel(event.target.value);
          }}
          label="Libellé de la ligne"
          placeholder="Libellé anonymisé 294111"
          color="primary"
          fullWidth
          error={hasLabelError}
          helperText={hasLabelError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.label : ''}
        />

        <ClickAwayListener onClickAway={() => setIsDatePickerOpen(false)}>
          <Box mb={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                slotProps={{
                  openPickerIcon: {
                    onClick: () => setIsDatePickerOpen(true)
                  },
                  textField: {
                    inputProps: {
                      onClick: () => setIsDatePickerOpen(true)
                    }
                  }
                }}
                open={isDatePickerOpen}
                sx={{width: '100%'}}
                label="Date"
                value={date}
                onChange={newDate => {
                  setDate(newDate);
                  setIsDatePickerOpen(false);
                }}
              />
            </LocalizationProvider>
          </Box>
        </ClickAwayListener>

        <>
          <TextField
            sx={{width: '50%'}}
            required
            value={amount}
            error={hasAmountError}
            helperText={hasAmountError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.positiveOrNegativeAmount : ''}
            onChange={event => {
              setAmount(event.target.value);
            }}
            label="Montant HT"
            placeholder="12000"
            color="primary"
            fullWidth
          />
          <InputVAT
            value={vatRate}
            onChange={e => {
              setVatRate(e.target.value);
            }}
          />
        </>

        <FormSelect
          sx={{mb: 3, mt: 3, width: '100%'}}
          label="Type de mouvement"
          labelId="movement-input-label"
          value={movementType}
          onChange={e => {
            setMovementType(e.target.value);
          }}
          items={MOVEMENT_TYPES.map((movement, i) => (
            <MenuItem selected={movement === movementType} key={i} value={movement}>
              {movement}
            </MenuItem>
          ))}
        />
      </DialogContent>

      <DialogActions sx={{justifyContent: 'center', mt: 1}}>
        <Button size="large" variant="outlined" color="primary" onClick={closeModal}>
          Fermer
        </Button>
        <Button size="large" variant="contained" color="secondary" disabled={submitButtonDisabled} onClick={handleSubmit}>
          Ajouter
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

CreateForecastEntryModal.defaultProps = {
  isOpen: false
};

CreateForecastEntryModal.propTypes = {
  isOpen: bool,
  closeModal: func.isRequired,
  onSubmit: func.isRequired
};

export default CreateForecastEntryModal;
