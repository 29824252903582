import {useEffect, useRef, useState} from 'react';

import {Grid, Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import {oneOf} from 'prop-types';

import {CHATBOT_ASSISTANT_ANSWER_STATE} from '../../../const';
import useChatbot from '../../../hooks/providers/useChatbot';
import AssistantAnswerState from '../AssistantAnswerState/AssistantAnswerState';
import AssistantPictureTooltip from '../AssistantPictureTooltip/AssistantPictureTooltip';
import MarkdownText from '../MarkdownText';
import TypingIndicator from '../TypingIndicator/TypingIndicator';

const AssistantIsAnsweringMessage = ({step}) => {
  const {selectedAssistant, streamedMessage, isStreamingMessage, scrollConversationToBottom, userHasScrolledUp, setUserHasScrolledUp} = useChatbot();
  const theme = useTheme();

  const containerRef = useRef(null);
  const [prevContainerHeight, setPrevContainerHeight] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    // Check if container has expanded to a new line
    const currentContainerHeight = container.scrollHeight;

    if (currentContainerHeight + 10 > prevContainerHeight && !userHasScrolledUp) {
      scrollConversationToBottom();
      setPrevContainerHeight(currentContainerHeight);
    }
  }, [prevContainerHeight, scrollConversationToBottom, streamedMessage]);

  useEffect(() => {
    setUserHasScrolledUp(false);
    setPrevContainerHeight(0);
  }, [isStreamingMessage]);

  const isAnsweringStep = step === CHATBOT_ASSISTANT_ANSWER_STATE.answering;
  const isThinkingStep = step === CHATBOT_ASSISTANT_ANSWER_STATE.thinking;

  if (!isStreamingMessage && isAnsweringStep) {
    return null;
  }

  const answerStepText = isThinkingStep ? 'réfléchit' : 'écrit';

  return (
    <Grid container spacing={1} sx={{background: theme.palette.primary.light}} pt={1} pb={2.5} px={1} mb={1} ref={isThinkingStep ? null : containerRef}>
      <Grid item xs="auto">
        <AssistantPictureTooltip assistant={selectedAssistant} />
      </Grid>

      <Grid item xs={10} pb={1} display="flex" flexDirection="column">
        <Typography color="primary" fontFamily="SoehneBreitKraftig" fontSize={15} mb={1} mt={1.5}>
          {selectedAssistant.name}
          <AssistantAnswerState stateText={answerStepText} />
        </Typography>

        {isThinkingStep && <TypingIndicator />}

        {isAnsweringStep && (
          <Box
            sx={{
              fontFamily: 'InstrumentSans',
              fontSize: 14
            }}
          >
            <MarkdownText message={streamedMessage.join('')} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

AssistantIsAnsweringMessage.propTypes = {
  step: oneOf(Object.values(CHATBOT_ASSISTANT_ANSWER_STATE)).isRequired
};

export default AssistantIsAnsweringMessage;
