import {DialogContent, useTheme, Box, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';

import useProjects from '../../../hooks/providers/useProjects';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import CustomAlert from '../../alert/Alert';
import OpenContactFormButton from '../../contact/OpenContactFormButton/OpenContactFormButton';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import CustomWorkspaceProjectForm from './CustomWorkspaceProjectForm';

const CreateCustomWorkspaceConnectionModal = () => {
  const {isSubmitting, setIsSubmitting, siren, createProject, createProjectModalOpen, setCreateProjectModalOpen} = useProjects();
  const {isDefaultWorkspace} = useWorkspaces();

  const theme = useTheme();
  const open = createProjectModalOpen && !isDefaultWorkspace;

  const onClose = () => {
    setCreateProjectModalOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const result = await createProject({siren});
    setIsSubmitting(false);

    const successfulStatusCodes = [200, 201, 202];

    if (successfulStatusCodes.includes(result.status)) {
      onClose();
    }
  };

  return (
    <BaseModal onClose={onClose} open={open} title="Ajouter une connexion">
      <DialogContent>
        <Box pt={3}>
          <CustomWorkspaceProjectForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
        </Box>
      </DialogContent>

      {isSubmitting && (
        <Box mt={2}>
          <Spinner text="Création de la connexion en cours" isLoading />
        </Box>
      )}

      <CustomAlert sx={{background: theme.palette.primary.light, color: grey[700]}}>
        <Typography fontSize={14}>Vous souhaitez un nouveau connecteur ?&nbsp;</Typography>
        <OpenContactFormButton text="Contactez-nous !" />
      </CustomAlert>
    </BaseModal>
  );
};

export default CreateCustomWorkspaceConnectionModal;
