import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {IconButton, useTheme} from '@mui/material';

import useChatbot from '../../../hooks/providers/useChatbot';

const ScrollToBottomArrow = () => {
  const theme = useTheme();
  const {isAtBottom, userHasScrolledUp, scrollConversationToBottom} = useChatbot();

  if (isAtBottom || !userHasScrolledUp) {
    return null;
  }

  return (
    <IconButton
      sx={{
        border: '2px solid lightgrey',
        width: 40,
        height: 40,
        position: 'sticky',
        bottom: 48,
        margin: '0 auto',
        background: theme.palette.primary.main,
        '&:hover': {
          background: theme.palette.primary.main
        }
      }}
      color="white"
      aria-label="Go to conversation bottom"
      edge="end"
      onClick={() => scrollConversationToBottom('smooth')}
    >
      <ArrowDownwardIcon />
    </IconButton>
  );
};

export default ScrollToBottomArrow;
