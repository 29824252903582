import Typography from '@mui/material/Typography';
import {string} from 'prop-types';

const ErrorMessage = ({message}) => {
  if (!message) return null;

  return (
    <Typography fontSize={14} mt={2} color="error" variant="inherit">
      {message}
    </Typography>
  );
};

ErrorMessage.propTypes = {
  message: string.isRequired
};

export default ErrorMessage;
