import {Grid} from '@mui/material';

import useContact from '../../../../../hooks/providers/useContact';
import {connectorShape} from '../../../../../proptypes-shapes';
import Button from '../../../../form/buttons/Button/Button';

const LocalConnectorAskCredentialsButton = ({connector}) => {
  const {openAskConnectorOpeningContactForm} = useContact();

  if (!connector.is_local) {
    return null;
  }

  return (
    <Grid item mt={0.5}>
      <Button variant="text" size="small" sx={{textDecoration: 'underline'}} onClick={() => openAskConnectorOpeningContactForm(connector)}>
        Demander mes identifiants
      </Button>
    </Grid>
  );
};

LocalConnectorAskCredentialsButton.propTypes = {
  connector: connectorShape.isRequired
};

export default LocalConnectorAskCredentialsButton;
