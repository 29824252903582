import {useState} from 'react';

import {DeleteForeverOutlined} from '@mui/icons-material';
import {Grid, Avatar, DialogContentText, Typography, DialogActions, useTheme} from '@mui/material';
import Box from '@mui/material/Box';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';

// eslint-disable-next-line complexity
const DeleteProjectModal = () => {
  const {
    deleteError,
    deleteProjectModalOpen: open,
    selectedProject: project,
    setDeleteProjectModalOpen: setOpen,
    deleteProject,
    projects,
    setProjects,
    selectedReportTab,
    closeProjectMenu
  } = useProjects();
  const {siren} = project || {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  const isDeFiGestionProject = project.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion;
  const theme = useTheme();

  const onClose = () => {
    setOpen(false);
    closeProjectMenu();
  };

  const onDelete = async () => {
    setIsSubmitting(true);
    const result = await deleteProject(project);
    setIsSubmitting(false);

    if (result.status === 200) {
      setHasBeenDeleted(true);
    }
  };

  const handleClose = async (event, reason, projectDeleted = false) => {
    if (projectDeleted) {
      const projectToDeleteIndex = projects.findIndex(p => p.siren === siren && (p.schema_name === project.schema_name || p.report_id === selectedReportTab));

      let newProjects = [...projects];
      newProjects.splice(projectToDeleteIndex, 1);

      if (isDeFiGestionProject) {
        // Business rule : All associated projects in custom workspaces must be deleted after DeFi Gestion project deletion
        newProjects = newProjects.filter(p => {
          // if project has a different siren, we keep it
          if (p.siren !== siren) return true;
          // if project has the same siren and is not inside a custom workspace, we DO NOT keep it
          return Object.values(DEFAULT_PROJECTS_SCHEMAS).includes(p.schema_name);
        });
      }

      setProjects(newProjects);
    }

    onClose();
  };

  const title = hasBeenDeleted ? 'Connexion supprimée' : 'Supprimer la connexion';

  return (
    <BaseModal
      sx={{
        '& .MuiDialogContentText-root': {
          color: theme.palette.error.main
        }
      }}
      title={title}
      canCloseOnBackdropClick={false}
      onClose={handleClose}
      open={open}
    >
      <Box p={3}>
        {hasBeenDeleted && (
          <Grid container direction="column" alignItems="center">
            <Avatar sx={{p: 5, bgcolor: `${theme.palette.error.light}30`}}>
              <DeleteForeverOutlined sx={{fontSize: 60}} color="error" />
            </Avatar>
            <Typography sx={{mt: 5, mb: 1, fontSize: 15}} variant="inherit">
              Votre connexion a bien été supprimée !
            </Typography>
          </Grid>
        )}
        {!isSubmitting && !hasBeenDeleted && <DialogContentText textAlign="center">Êtes-vous sûr de vouloir supprimer définitivement cette connexion ?</DialogContentText>}
        {!isSubmitting && !hasBeenDeleted && isDeFiGestionProject && (
          <Typography mt={2} color="text.secondary" fontSize={13} fontWeight="bold" textAlign="center">
            Cette connexion sera également supprimée de vos rapports personnalisés si elle y est associée.
          </Typography>
        )}
        {isSubmitting && (
          <>
            <Spinner text="Suppression de la connexion en cours" isLoading />
            <Typography color="text.secondary" fontSize={13} textAlign="center">
              Selon la taille de votre connexion, sa suppression complète peut prendre plus d'une minute.
            </Typography>
          </>
        )}
      </Box>
      <DialogActions
        sx={{
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {hasBeenDeleted ? (
          <Button size="large" variant="contained" color="secondary" onClick={(event, reason) => handleClose(event, reason, true)}>
            Retour à mes connexions
          </Button>
        ) : (
          <>
            {!isSubmitting && (
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Annuler
              </Button>
            )}
            <Button disabled={isSubmitting} variant="contained" color="error" onClick={onDelete}>
              Supprimer
            </Button>
          </>
        )}
      </DialogActions>
      {deleteError && <Typography sx={{color: theme.palette.error.main}} />}
    </BaseModal>
  );
};

export default DeleteProjectModal;
