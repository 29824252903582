import {Grid, Typography} from '@mui/material';
import Lottie from 'lottie-react';
import {bool, string, object, number} from 'prop-types';
import {SpinnerCircular} from 'spinners-react';
import styled from 'styled-components';

import getCompanySpinnerAnimation from '../../img/company-spinner';

const SpinnerAnimation = styled(Lottie)`
  margin: 16px auto;
`;

const Spinner = ({isLoading, text, textStyle, size, withoutText}) => {
  const animation = getCompanySpinnerAnimation();

  if (isLoading) {
    return (
      <Grid data-testid="app-spinner" container alignItems="center" justifyContent="center" direction="column">
        <Grid item>{animation ? <SpinnerAnimation style={{height: size, width: size}} loop animationData={animation} /> : <SpinnerCircular color="#fff" size={size} />}</Grid>
        {!withoutText && (
          <Typography
            sx={{
              fontFamily: 'SoehneBreitKraftig',
              textAlign: 'center',
              fontSize: 18,
              marginBottom: 1,
              color: 'text.primary',
              ...textStyle
            }}
            component="div"
            variant="h6"
          >
            {text}
          </Typography>
        )}
      </Grid>
    );
  }
  return null;
};

Spinner.defaultProps = {
  isLoading: false,
  text: 'Sauvegarde en cours',
  textStyle: {},
  size: 96,
  withoutText: false
};

Spinner.propTypes = {
  isLoading: bool,
  text: string,
  // eslint-disable-next-line react/forbid-prop-types
  textStyle: object,
  size: number,
  // eslint-disable-next-line react/boolean-prop-naming
  withoutText: bool
};

export default Spinner;
