import {CloudUpload} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {bool, func} from 'prop-types';

import useReport from '../../../hooks/providers/useReport';
import useUpload from '../../../hooks/providers/useUpload';
import {getReportsIdsHavingUploadButton} from '../../../utils';
import HeaderStyledIconButton from '../HeaderStyledIconButton/HeaderStyledIconButton';

const HeaderUploadReportFileButton = ({onClose, isMobile = false}) => {
  const {reports, selectedReport} = useReport();
  const {setIsUploadFilesModalOpen} = useUpload();
  const reportsHavingUploadFilesButton = getReportsIdsHavingUploadButton(reports);

  const handleClick = async () => {
    setIsUploadFilesModalOpen(true);
    onClose();
  };

  if (!reportsHavingUploadFilesButton.includes(selectedReport?.config.id)) {
    return null;
  }

  if (isMobile) {
    return (
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <CloudUpload fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Charger votre fichier</ListItemText>
      </MenuItem>
    );
  }

  return (
    <HeaderStyledIconButton onClick={handleClick}>
      <Tooltip title="Charger votre fichier">
        <CloudUpload />
      </Tooltip>
    </HeaderStyledIconButton>
  );
};

HeaderUploadReportFileButton.defaultProps = {
  isMobile: false,
  onClose: null
};

HeaderUploadReportFileButton.propTypes = {
  isMobile: bool,
  onClose: func
};

export default HeaderUploadReportFileButton;
