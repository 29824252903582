import {RuleFolder} from '@mui/icons-material';
import {MenuItem, ListItemIcon} from '@mui/material';
import Grid from '@mui/material/Grid';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';

const ProjectMenuUpdateButton = () => {
  const {setUpdateProjectModalOpen, selectedProject: project} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setUpdateProjectModalOpen, project});

  const theme = useTheme();

  return (
    <Grid container alignItems="center">
      <MenuItem sx={{flex: 1}} onClick={openModal}>
        <ListItemIcon color={theme.palette.primary.main}>
          <RuleFolder fontSize="small" color="primary" />
        </ListItemIcon>
        <Typography variant="inherit">Paramètres de la connexion</Typography>
      </MenuItem>
    </Grid>
  );
};

export default ProjectMenuUpdateButton;
