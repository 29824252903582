import {useState} from 'react';

import {Close, DeleteForever, PersonAddAlt1, PersonRemove, Key} from '@mui/icons-material';
import {Box, Chip, Grid, ListItemIcon, Menu, MenuItem, Typography, useTheme} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {string, arrayOf, func, bool} from 'prop-types';

import useProfile from '../../../hooks/providers/useProfile';
import {userShape} from '../../../proptypes-shapes';
import TitleWithTooltip from '../TitleWithTooltip';

const UsersContainer = muiStyled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  padding: '8px'
}));

const ProjectUsersList = ({title, titleTooltip, subtitle, users, onChipDelete, isSubmitting, promoteUser, demoteUser}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const {profile} = useProfile();

  const open = Boolean(anchorEl);
  const isOwnersList = users.every(user => user.is_owner);
  const isCurrentUserOnlyOwner = isOwnersList && users.length === 1 && users[0].user_id.toLowerCase() === profile.id;

  const theme = useTheme();

  const openMenu = (event, userId) => {
    setAnchorEl(event.target);
    setSelectedUser(userId);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const deleteUser = userId => {
    onChipDelete(userId);
    closeMenu();
  };

  const onPromote = () => {
    closeMenu();
    promoteUser(selectedUser);
  };

  const onDemote = async () => {
    closeMenu();
    await demoteUser(selectedUser);
  };

  if (users.length === 0) {
    return null;
  }

  return (
    <Box mb={2}>
      <Grid container alignItems="center" mb={2}>
        <TitleWithTooltip title={title} tooltip={titleTooltip} />
      </Grid>
      {subtitle && (
        <Typography sx={{mt: 0.5, mb: 1}} variant="caption" component="div" color="text.secondary">
          {subtitle}
        </Typography>
      )}
      <UsersContainer>
        {users.map(({username, user_id: userId}, index) => (
          <Chip
            icon={isOwnersList ? <Key /> : null}
            disabled={isSubmitting || isCurrentUserOnlyOwner}
            sx={{mr: 1, mb: 1, cursor: 'pointer'}}
            key={index}
            color="primary"
            variant={selectedUser === userId ? 'contained' : 'outlined'}
            label={username?.toLowerCase()}
            onClick={e => openMenu(e, userId)}
            onDelete={onChipDelete ? () => deleteUser(userId) : undefined}
            deleteIcon={<Close fontSize="small" color="primary" />}
          />
        ))}
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={closeMenu}
        >
          <MenuItem onClick={isOwnersList ? onDemote : onPromote}>
            <ListItemIcon color={isOwnersList ? theme.palette.error.main : theme.palette.primary.main}>
              {isOwnersList ? <PersonRemove fontSize="small" color="primary" /> : <PersonAddAlt1 fontSize="small" color="primary" />}
            </ListItemIcon>
            <Typography variant="inherit">{isOwnersList ? 'Retirer des propriétaires' : 'Rendre propriétaire de la connexion'}</Typography>
          </MenuItem>
          <MenuItem onClick={() => deleteUser(selectedUser)}>
            <ListItemIcon color={theme.palette.error.main}>
              <DeleteForever fontSize="small" color="error" />
            </ListItemIcon>
            <Typography color={theme.palette.error.main} variant="inherit">
              Supprimer l'utilisateur
            </Typography>
          </MenuItem>
        </Menu>
      </UsersContainer>
    </Box>
  );
};

ProjectUsersList.defaultProps = {
  subtitle: '',
  onChipDelete: null,
  promoteUser: null,
  demoteUser: null
};

ProjectUsersList.propTypes = {
  title: string.isRequired,
  subtitle: string,
  titleTooltip: string.isRequired,
  users: arrayOf(userShape).isRequired,
  onChipDelete: func,
  promoteUser: func,
  demoteUser: func,
  isSubmitting: bool.isRequired
};

export default ProjectUsersList;
