import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import {Fab} from '@mui/material';
import {useLocation} from 'react-router-dom';

import {APP_ROUTES} from '../../const';
import useProjects from '../../hooks/providers/useProjects';
import useReport from '../../hooks/providers/useReport';
import useTranscript from '../../hooks/providers/useTranscript';
import useWorkspaces from '../../hooks/providers/useWorkspaces';

const RecordDayWidget = () => {
  const {selectedReport} = useReport();
  const {setMediaStream, setIsTranscriptWorkingDayModalOpen} = useTranscript();
  const {workspaces, selectedWorkspace} = useWorkspaces();
  const location = useLocation();
  const {selectedReportTab} = useProjects();

  const hasTranscriptFeature = reportId => {
    return workspaces.some(workspace => workspace.reports?.some(report => report.report_id === reportId && report.isTimesheet));
  };

  const shouldButtonDisplay = () => {
    // Button appears on /reports page if selected report has this feature
    if (location.pathname.startsWith('/reports')) {
      return hasTranscriptFeature(selectedReport?.config?.id);
    }

    // Button appears on /mes-connexions page if selected report tab has this feature
    if (location.pathname === APP_ROUTES.connections && selectedWorkspace) {
      const report = selectedWorkspace.reports?.find(r => r.report_id === selectedReportTab);
      return report?.isTimesheet ?? false;
    }

    return false;
  };

  const shouldDisplay = shouldButtonDisplay();
  if (!shouldDisplay) return null;

  return (
    <Fab
      color="secondary"
      onClick={() => {
        setMediaStream();
        setIsTranscriptWorkingDayModalOpen(true);
      }}
    >
      <KeyboardVoiceOutlinedIcon sx={{fontSize: 30}} />
    </Fab>
  );
};

export default RecordDayWidget;
