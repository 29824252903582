/* eslint-disable react/jsx-props-no-spreading */
import {useEffect, useRef, useState} from 'react';

import {TabContext} from '@mui/lab';
import MuiTabPanel from '@mui/lab/TabPanel';
import {Grid, Hidden} from '@mui/material';
import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import {useWindowSize} from '@uidotdev/usehooks';
import {func} from 'prop-types';

import useProjects from '../../hooks/providers/useProjects';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import CreateOrImportReportModal from '../workspaces/CreateOrImportReportModal';
import DeleteReportModal from '../workspaces/DeleteWorkspaceOrReportModal';
import ReportTabsMenu from '../workspaces/ReportTabsMenu';
import CreateReportButton from './CreateReportButton/CreateReportButton';
import CustomReportTabs from './CustomReportTabs';
import OpenOverflowedTabsMenuButton from './OpenOverflowedTabsMenuButton';
import OverflowedTabsMenu from './OverflowedTabsMenu';

const Container = muiStyled(Box)(() => ({
  width: '100%',
  typography: 'body1',
  display: 'flex',
  flexDirection: 'column',
  flex: 1
}));

const TabsContainer = muiStyled(Box)(() => ({
  width: '100%',
  margin: '0 auto',
  background: '#F5F5F5',
  display: 'flex'
}));

const TabPanel = muiStyled(MuiTabPanel)(({theme}) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1)
  }
}));

// eslint-disable-next-line complexity
const ReportTabs = ({renderContent}) => {
  const {selectedReportTab, setSelectedReportTab} = useProjects();
  const {selectedWorkspace, setCreateReportModalOpen, createReportModalOpen} = useWorkspaces();

  const [anchorElOverflowedTabsMenu, setAnchorElOverflowedTabsMenu] = useState(null);
  const [isOverflowedTabsMenuOpen, setIsOverflowedTabsMenuOpen] = useState(false);
  const [showMoreReportsButtonDisabled, setShowMoreReportsButtonDisabled] = useState(true);

  const styledTabsRef = useRef(null);
  const rightToolbarRef = useRef(null);
  const rightToolbarWidth = rightToolbarRef.current?.offsetWidth;

  const windowSize = useWindowSize();

  const getOverflowTabs = reps => {
    const container = styledTabsRef.current;
    if (!container) return [];

    const tabElements = Array.from(container.querySelectorAll('.MuiTab-root'));
    const containerWidth = container.clientWidth;
    const tabHorizontalMargin = 32;
    let totalWidth = 0;
    let index = 0;

    for (; index < tabElements.length; index++) {
      totalWidth += tabElements[index].offsetWidth + tabHorizontalMargin;
      if (totalWidth > containerWidth) break;
    }
    return (reps || []).slice(index);
  };

  useEffect(() => {
    if (styledTabsRef.current) {
      const reports = selectedWorkspace?.reports;
      const notVisibleTabs = getOverflowTabs(reports);
      if (notVisibleTabs && notVisibleTabs.length > 0) {
        setShowMoreReportsButtonDisabled(false);
      } else {
        setShowMoreReportsButtonDisabled(true);
      }
    }
  }, [windowSize.width, styledTabsRef, selectedWorkspace?.reports]);

  const handleChange = (event, newSelectedSchema) => {
    setSelectedReportTab(newSelectedSchema);
  };

  const getSortedReports = () => {
    // Business need: we have to "extract" Notice report from all reports list.
    // We want to display this specific report at the first position, and potentially apply specific style to it
    const alphabeticallySortedReports = selectedWorkspace?.reports?.sort((a, b) => a.report_name.toLowerCase().localeCompare(b.report_name.toLowerCase())).filter(r => !r.is_notice_report) || [];
    const noticeReport = selectedWorkspace?.reports?.find(r => r.is_notice_report);
    if (noticeReport) {
      alphabeticallySortedReports.unshift(noticeReport);
    }
    return alphabeticallySortedReports;
  };

  const alphabeticallySortedReports = getSortedReports();

  return (
    <Container>
      <TabContext value={selectedReportTab}>
        <TabsContainer>
          <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            <Hidden only="xs">
              <Box ml={1} sx={{width: rightToolbarWidth}} />
            </Hidden>
            <Hidden smUp>
              <Box ml={5} />
            </Hidden>

            <Box overflow="hidden" ref={styledTabsRef}>
              <CustomReportTabs handleChange={handleChange} />
            </Box>

            <Grid item ref={rightToolbarRef}>
              <Grid container alignItems="center" flexWrap="nowrap">
                {!showMoreReportsButtonDisabled && (
                  <OpenOverflowedTabsMenuButton
                    onClick={e => {
                      setAnchorElOverflowedTabsMenu(e.currentTarget);
                      setIsOverflowedTabsMenuOpen(true);
                    }}
                  />
                )}
                <CreateReportButton />
              </Grid>
            </Grid>
          </Grid>
        </TabsContainer>

        {alphabeticallySortedReports?.map(r => (
          <TabPanel key={r.report_id} value={r.report_id}>
            {renderContent()}
          </TabPanel>
        ))}

        <ReportTabsMenu />

        <DeleteReportModal isReportDeletion />
      </TabContext>

      <OverflowedTabsMenu
        tabLabelProperty="report_name"
        tabValueProperty="report_id"
        tabs={selectedWorkspace?.reports}
        anchorEl={anchorElOverflowedTabsMenu}
        isOpen={isOverflowedTabsMenuOpen}
        onClose={() => setIsOverflowedTabsMenuOpen(false)}
      />

      <CreateOrImportReportModal isOpen={createReportModalOpen} closeModal={() => setCreateReportModalOpen(false)} />
    </Container>
  );
};

ReportTabs.propTypes = {
  renderContent: func.isRequired
};

export default ReportTabs;
