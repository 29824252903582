import {useEffect, useLayoutEffect, useRef, useState} from 'react';

import {Divider, Grid} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {styled as muiStyled} from '@mui/material/styles';
import {useWindowSize} from '@uidotdev/usehooks';
import {func, node, oneOfType} from 'prop-types';

import {CHATBOT_SIDEBAR_WIDTH, MAXIMUM_NUMBER_OF_CHABOT_THREADS} from '../../../const';
import useScrollTracking from '../../../hooks/dom/useScrollTracking';
import useChatbot from '../../../hooks/providers/useChatbot';
import useLoading from '../../../hooks/providers/useLoading';
import usePayment from '../../../hooks/providers/usePayment';
import CustomDimmer from '../../dimmer/Dimmer';
import ChatbotMainMenu from '../ChatbotMainMenu/ChatbotMainMenu';
import ChatbotMessageInput from '../ChatbotMessageInput/ChatbotMessageInput';
import ChatbotShareDataButton from '../ChatbotShareDataButton/ChatbotShareDataButton';
import ChatbotTopbar from '../ChatbotTopbar/ChatbotTopbar';
import ConversationContent from '../ConversationContent/ConversationContent';
import ConversationContentMaximumNumberOfConversationReached from '../ConversationContentMaximumNumberOfConversationReached/ConversationContentMaximumNumberOfConversationReached';
import MaximumNumberOfTrialMessagesBanner from '../MaximumNumberOfTrialMessagesBanner/MaximumNumberOfTrialMessagesBanner';
import NewConversationContent from '../NewConversationContent/NewConversationContent';
import ScrollToBottomArrow from '../ScrollToBottomArrow/ScrollToBottomArrow';
import TalksList from '../TalksList/TalksList/TalksList';

const Main = muiStyled('main', {shouldForwardProp: prop => prop !== 'open'})(({theme, open}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -CHATBOT_SIDEBAR_WIDTH,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative'
}));

const TopBarDividerContainer = muiStyled(Box)(() => ({
  width: '95%',
  margin: '0 auto',
  position: 'sticky',
  top: 54,
  zIndex: 9
}));

// eslint-disable-next-line complexity
const ChatbotSidebar = ({children}) => {
  const {
    isChatbotSidebarOpen: open,
    getChatbotData,
    selectedAssistant,
    createThread,
    isWarningAboutDataPrivacyBackdropOpen,
    threadMessages,
    addMessageToThread,
    isFullScreenMode,
    currentThreadId,
    isThreadLoading,
    scrollConversationToBottom,
    conversationContainerRef,
    setUserHasScrolledUp,
    setIsAtBottom,
    setIsSendingMessage,
    threads,
    sendFileInProgress
  } = useChatbot();
  const {isTrial} = usePayment();
  const {setCompletedApiCalls} = useLoading();

  const [message, setMessage] = useState('');

  const [st, setScrollTop] = useState(0);

  const containerRef = useRef(null);
  useScrollTracking(containerRef, setIsAtBottom, setUserHasScrolledUp, setScrollTop);

  const windowSize = useWindowSize();

  const handleSendMessage = async () => {
    const isNewThread = threadMessages.length === 0;

    setIsSendingMessage(true);
    const res = isNewThread ? await createThread(message) : await addMessageToThread(message);
    setIsSendingMessage(false);

    if (res?.success) {
      setMessage('');
    }
  };

  useEffect(() => {
    (async () => {
      await getChatbotData();
      setCompletedApiCalls(prevCompleted => prevCompleted + 1);
    })();
  }, []);

  // This hook aims to scroll conversation to bottom once conversation is loaded
  useLayoutEffect(() => {
    if (!isThreadLoading) {
      setTimeout(() => scrollConversationToBottom('smooth'), 250);
    }
  }, [isThreadLoading]);

  // This hook aims to scroll conversation to bottom once user has checked the privacy warning
  useLayoutEffect(() => {
    if (!isWarningAboutDataPrivacyBackdropOpen) {
      setTimeout(() => scrollConversationToBottom('smooth'), 250);
    }
  }, [isWarningAboutDataPrivacyBackdropOpen]);

  const userHasReachedMaximumNumberOfConversations = isTrial && threads.length >= MAXIMUM_NUMBER_OF_CHABOT_THREADS;

  const shouldRenderNewConversation = (isWarningAboutDataPrivacyBackdropOpen || !selectedAssistant) && !userHasReachedMaximumNumberOfConversations;
  const shouldRenderNormalConversation = !isWarningAboutDataPrivacyBackdropOpen && (selectedAssistant || currentThreadId);
  const shouldRenderMaximumThreadsReached = !shouldRenderNewConversation && !shouldRenderNormalConversation && (isWarningAboutDataPrivacyBackdropOpen || userHasReachedMaximumNumberOfConversations);

  return (
    <Box sx={{display: 'flex'}}>
      <ChatbotMainMenu />

      <Main
        sx={{
          padding: 0,
          maxWidth: `calc(100% - ${open ? CHATBOT_SIDEBAR_WIDTH : 0}px)`
        }}
        open={open}
      >
        {children}
      </Main>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            display: 'flex',
            flexDirection: 'row',
            width: {
              xs: '100%',
              lg: isFullScreenMode ? windowSize.width : CHATBOT_SIDEBAR_WIDTH
            },
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10
          }
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <TalksList />

        <Grid item flex={1} display="flex" flexDirection="column" overflow="scroll" ref={containerRef}>
          <ChatbotTopbar />

          <TopBarDividerContainer>
            <Divider />
          </TopBarDividerContainer>

          <Grid container flexDirection="column" justifyContent="space-between" flex={1} ref={conversationContainerRef} position="relative">
            <MaximumNumberOfTrialMessagesBanner />

            {shouldRenderNewConversation && <NewConversationContent />}

            {shouldRenderNormalConversation && <ConversationContent />}

            {shouldRenderMaximumThreadsReached && <ConversationContentMaximumNumberOfConversationReached />}

            <Grid item flex={1} pl={0.5} pr={2} mb={2} flexGrow={0}>
              <Grid spacing={1} container alignItems="center" maxWidth={1200} margin="0 auto">
                <Grid item flex="unset">
                  <ChatbotShareDataButton />
                </Grid>
                <Grid item flex={1}>
                  <ChatbotMessageInput message={message} setMessage={setMessage} sendMessage={handleSendMessage} />
                </Grid>
              </Grid>
            </Grid>

            <CustomDimmer isOpen={sendFileInProgress} spinnerText="Envoi du fichier en cours" />
            <ScrollToBottomArrow />
          </Grid>
        </Grid>
      </Drawer>
    </Box>
  );
};

ChatbotSidebar.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ChatbotSidebar;
