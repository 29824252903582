import {Box} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

const PageContainer = muiStyled(Box)(({theme}) => ({
  maxWidth: 1200,
  minHeight: 'calc(100vh - 20vh)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  background: 'white',
  boxShadow: '0 0 10px #00000029',
  borderRadius: 10,
  opacity: 1,
  [theme.breakpoints.up('xs')]: {
    margin: '12px 6px',
    padding: '4px 8px'
  },
  [theme.breakpoints.up('sm')]: {
    margin: '24px 12px',
    padding: '12px 24px'
  },
  [theme.breakpoints.up('md')]: {
    margin: '48px 16px'
  },
  [theme.breakpoints.up('lg')]: {
    margin: '48px auto'
  }
}));

export default PageContainer;
