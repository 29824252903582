import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {ThemeProvider} from '@mui/material';
import {RouterProvider} from 'react-router-dom';

import {msalConfig} from './authConfig';
import InstallPwaModal from './components/pwa/InstallPwaModal';
import SpinnerRse from './components/spinner-rse/SpinnerRse';
import ErrorBoundary from './hocs/ErrorBoundary';
import RequireMaintenanceStatus from './hocs/RequireMaintenanceStatus';
import BannerProvider from './providers/BannerProvider';
import ChatbotProvider from './providers/ChatbotProvider';
import ContactProvider from './providers/ContactProvider';
import ErrorProvider from './providers/ErrorProvider';
import LoadingProvider from './providers/LoadingProvider';
import PaymentProvider from './providers/PaymentProvider';
import ProfileProvider from './providers/ProfileProvider';
import ProjectsCustomMappingsProvider from './providers/ProjectsCustomMappingsProvider';
import ProjectsProvider from './providers/ProjectsProvider';
import PwaProvider from './providers/PwaProvider';
import ReportProvider from './providers/ReportProvider';
import ReportSummaryProvider from './providers/ReportSummaryProvider';
import SnackbarProvider from './providers/SnackbarProvider';
import TranscriptProvider from './providers/TranscriptProvider';
import UploadProvider from './providers/UploadProvider';
import UserProvider from './providers/UserProvider';
import WorkspacesProvider from './providers/WorkspacesProvider';
import Router from './Router';
import theme from './styles/mui-theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './styles/index.css';
import './fonts/soehne-breit-kraftig.woff2';
import './fonts/InstrumentSans-Regular.ttf';
import './fonts/InstrumentSans-SemiBold.ttf';

export const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <ErrorProvider>
          <ErrorBoundary>
            <LoadingProvider>
              <SnackbarProvider>
                <UserProvider>
                  <ReportProvider>
                    <ReportSummaryProvider>
                      <PaymentProvider>
                        <WorkspacesProvider>
                          <ProjectsProvider>
                            <ProjectsCustomMappingsProvider>
                              <ProfileProvider>
                                <UploadProvider>
                                  <BannerProvider>
                                    <PwaProvider>
                                      <ChatbotProvider>
                                        <TranscriptProvider>
                                          <ContactProvider>
                                            <SpinnerRse />
                                            <InstallPwaModal />
                                            <RequireMaintenanceStatus>
                                              <RouterProvider router={Router} />
                                            </RequireMaintenanceStatus>
                                          </ContactProvider>
                                        </TranscriptProvider>
                                      </ChatbotProvider>
                                    </PwaProvider>
                                  </BannerProvider>
                                </UploadProvider>
                              </ProfileProvider>
                            </ProjectsCustomMappingsProvider>
                          </ProjectsProvider>
                        </WorkspacesProvider>
                      </PaymentProvider>
                    </ReportSummaryProvider>
                  </ReportProvider>
                </UserProvider>
              </SnackbarProvider>
            </LoadingProvider>
          </ErrorBoundary>
        </ErrorProvider>
      </MsalProvider>
    </ThemeProvider>
  );
};

export default App;
