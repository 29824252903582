import {useEffect} from 'react';

const useScrollTracking = (containerRef, setIsAtBottom, setUserHasScrolledUp, setScrollTop, bottomThreshold = 15) => {
  useEffect(() => {
    if (!containerRef.current) return;

    const handleScroll = () => {
      const {scrollTop, scrollHeight, clientHeight} = containerRef.current;

      setScrollTop(prevScrollTop => {
        const atBottom = scrollTop + clientHeight + bottomThreshold >= scrollHeight;
        const userHasScrolledUp = scrollTop + bottomThreshold < prevScrollTop;

        setIsAtBottom(atBottom);
        if (userHasScrolledUp) {
          setUserHasScrolledUp(true);
        }

        return scrollTop;
      });
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => container.removeEventListener('scroll', handleScroll);
  }, [containerRef, setIsAtBottom, setUserHasScrolledUp, setScrollTop, bottomThreshold]);
};

export default useScrollTracking;
