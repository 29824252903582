import {Grid} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

import useChatbot from '../../../hooks/providers/useChatbot';
import AssistantCard from '../AssistantCard/AssistantCard';

const AssistantCardContainer = muiStyled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
}));

const AssistantList = () => {
  const {assistants, setSelectedAssistant} = useChatbot();

  return (
    <Grid
      flexWrap="nowrap"
      flexDirection="column"
      alignItems="center"
      sx={{
        padding: '0 16px 0 40px',
        mb: 4
      }}
      container
      spacing={1.5}
      mt={1.5}
      justifyContent="center"
    >
      {assistants.map(a => (
        <AssistantCardContainer item key={a.id} onClick={() => setSelectedAssistant(a)} sx={{}}>
          <AssistantCard assistant={a} />
        </AssistantCardContainer>
      ))}
    </Grid>
  );
};

export default AssistantList;
