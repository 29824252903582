import {useState} from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {useNavigate} from 'react-router-dom';

import {APP_ROUTES} from '../../../const';
import usePayment from '../../../hooks/providers/usePayment';
import useProjects from '../../../hooks/providers/useProjects';
import useReport from '../../../hooks/providers/useReport';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import {projectShape} from '../../../proptypes-shapes';
import {makeHumanFriendlyUrl} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import ReportsList from './ReportsList/ReportsList';

const SeeReportButton = ({project}) => {
  const {isDefaultWorkspace} = useWorkspaces();
  const {selectedReportTab} = useProjects();
  const {userHasEndedTrialAndIsOwnerOfProjects} = usePayment();
  const {reports, embedToken} = useReport();

  const navigate = useNavigate();

  const [isReportsListOpen, setIsReportsListOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const getReportUrl = report => {
    return makeHumanFriendlyUrl(APP_ROUTES.report(report.report_name));
  };

  const getTooltipMessage = () => {
    if (!embedToken) {
      return 'Rapports bientôt disponibles.';
    }

    return '';
  };

  const tooltipMessage = getTooltipMessage();

  const handleReportClick = report => {
    const url = getReportUrl(report);
    navigate(url, {
      state: {
        reportId: report.report_id,
        projectSiren: project.siren
      }
    });
  };

  if (isDefaultWorkspace) {
    return (
      <>
        <Button
          disabled={!embedToken || reports.length === 0 || userHasEndedTrialAndIsOwnerOfProjects}
          hasTooltip={!embedToken}
          tooltip={tooltipMessage}
          endIcon={<KeyboardArrowDownIcon />}
          size="small"
          variant="contained"
          color="secondary"
          sx={{fontSize: 13}}
          onClick={e => {
            setIsReportsListOpen(true);
            setAnchorEl(e.currentTarget);
          }}
        >
          Choisir un rapport
        </Button>
        <ReportsList project={project} handleReportClick={handleReportClick} anchorEl={anchorEl} isOpen={isReportsListOpen} onClose={() => setIsReportsListOpen(false)} />
      </>
    );
  }

  const report = reports.find(r => r.report_id === selectedReportTab);

  return (
    <Button
      disabled={!report || !embedToken || reports.length === 0 || userHasEndedTrialAndIsOwnerOfProjects}
      hasTooltip={!embedToken}
      tooltip={tooltipMessage}
      endIcon={<EyeIcon />}
      size="small"
      variant="contained"
      color="secondary"
      sx={{fontSize: 13}}
      onClick={() => handleReportClick(report)}
    >
      Voir le rapport
    </Button>
  );
};

SeeReportButton.propTypes = {
  project: projectShape.isRequired
};

export default SeeReportButton;
