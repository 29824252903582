import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {bool, string} from 'prop-types';

const LinkToConnectorLoginTutorial = ({tutorialUrl, isLoading}) => {
  if (!tutorialUrl || isLoading) {
    return null;
  }

  return (
    <Box mb={4}>
      <Grid container alignItems="center">
        <Grid item>
          <HelpOutlineOutlinedIcon fontSize="small" color="primary" />
        </Grid>
        <Grid item ml={1}>
          <Typography color="text.secondary" fontSize={14}>
            Consultez notre
            <b>
              &nbsp;
              <a href={tutorialUrl} target="_blank" rel="noreferrer">
                guide de connexion
              </a>
            </b>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

LinkToConnectorLoginTutorial.propTypes = {
  tutorialUrl: string.isRequired,
  isLoading: bool.isRequired
};

export default LinkToConnectorLoginTutorial;
