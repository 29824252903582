import {useState} from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import MenuItem from '@mui/material/MenuItem';
import {bool} from 'prop-types';

import useReportSummary from '../../../hooks/providers/useReportSummary';
import FormSelect from '../../form/MuiSelect/MuiSelect';
import DeletePromptConfirmModal from './DeletePromptConfirmModal';

const PromptsSelect = ({disabled}) => {
  const {promptModels, promptModel, setPromptModel, setInstructions} = useReportSummary();

  const [isDeletePromptModalOpen, setIsDeletePromptModalOpen] = useState(false);
  const [modelToDelete, setModelToDelete] = useState(null);

  const getPromptModelsSelectItems = () => {
    return (promptModels || []).map(({title}) => (
      <MenuItem key={title} value={title} sx={{display: 'flex', justifyContent: 'space-between'}}>
        {title}
        <DeleteForeverIcon
          color="error"
          onClick={e => {
            e.stopPropagation();
            setModelToDelete(title);
            setIsDeletePromptModalOpen(true);
          }}
        />
      </MenuItem>
    ));
  };

  const handlePromptModelChange = e => {
    const model = promptModels.find(m => m.title === e.target.value);
    setPromptModel(model);
    setInstructions(model.prompt);
  };

  const promptModelsItems = getPromptModelsSelectItems();

  return (
    <>
      <FormSelect
        variant={disabled ? 'filled' : 'outlined'}
        size="small"
        sx={{width: '100%'}}
        disabled={disabled || promptModels.length === 0}
        label="Choisir un modèle"
        labelId="model-label"
        value={promptModel.title}
        onChange={handlePromptModelChange}
        items={promptModelsItems}
        renderValue={selected => selected}
      />
      <DeletePromptConfirmModal model={modelToDelete} isOpen={isDeletePromptModalOpen} onClose={() => setIsDeletePromptModalOpen(false)} />
    </>
  );
};

PromptsSelect.propTypes = {
  disabled: bool.isRequired
};

export default PromptsSelect;
