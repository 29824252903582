import {useEffect, useState} from 'react';

import {Box} from '@mui/material';
import styled from '@mui/material/styles/styled';

const IndicatorContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme => theme.spacing(2)
});

const Dot = styled(Box)(({theme}) => ({
  marginLeft: 2,
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: 'white',
  transition: 'transform 0.3s ease-in-out'
}));

const RoundedBox = styled(Box)(({theme}) => ({
  display: 'flex',
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1.2, 1),
  borderRadius: theme.spacing(2)
}));

const TypingIndicator = () => {
  const [activeDotIndex, setActiveDotIndex] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDotIndex(prevIndex => (prevIndex + 1) % 3);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <IndicatorContainer data-testid="typing-indicator">
      <RoundedBox>
        {[0, 1, 2].map(index => (
          <Dot
            data-testid={`dot-${index}`}
            key={index}
            style={{
              transform: `translateY(${activeDotIndex === index ? '-5px' : '0px'})`,
              transitionDelay: `${Math.abs(index - activeDotIndex) * 0.15}s`
            }}
          />
        ))}
      </RoundedBox>
    </IndicatorContainer>
  );
};

export default TypingIndicator;
