import WarningIcon from '@mui/icons-material/Warning';
import {DialogActions, Typography, DialogContent} from '@mui/material';
import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';

const MaximumNumberOfProjectsModal = () => {
  const {maximumNumberOfProjectsReachedModalOpen: isOpen, setMaximumNumberOfProjectsReachedModalOpen: setIsOpen} = useProjects();

  const resetState = () => {
    setIsOpen(false);
  };

  return (
    <BaseModal title="Limite de connexions atteinte" onClose={resetState} open={isOpen}>
      <DialogContent sx={{pb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
        <WarningIcon sx={{fontSize: 120, mb: 3}} color="warning" />
        <Typography fontWeight="bold">Vous avez atteint la limite de connexions que vous pouvez créer.</Typography>
        <br />
        <Typography>
          Pour pouvoir créer plus de connexions, vous devez{' '}
          <Link style={{color: process.env.REACT_APP_SECONDARY_COLOR}} to={APP_ROUTES.profile}>
            sélectionner un abonnement
          </Link>{' '}
          .
        </Typography>
      </DialogContent>

      <DialogActions sx={{width: '100%', justifyContent: 'center'}}>
        <Button variant="outlined" size="large" color="inherit" autoFocus onClick={resetState}>
          Fermer
        </Button>
        <Link to={APP_ROUTES.profile} style={{textDecoration: 'none'}}>
          <Button sx={{ml: 2}} variant="contained" size="large" color="secondary" autoFocus onClick={resetState}>
            Je veux créer plus de connexions
          </Button>
        </Link>
      </DialogActions>
    </BaseModal>
  );
};

export default MaximumNumberOfProjectsModal;
