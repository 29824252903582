import {Grid, Typography} from '@mui/material';
import {bool, func} from 'prop-types';

import Switch from '../../../switch/Switch';

const ToggleVisibleRowsSwitch = ({hiddenRowsVisible, toggleHiddenRowsVisible}) => {
  return (
    <Grid container justifyContent="flex-end" alignItems="center" mt={1} mb={2}>
      <Grid item>
        <Grid container alignItems="center" spacing={0.5}>
          <Grid item>
            <Typography color="text.primary" fontWeight={300} fontSize={13} lineHeight={1}>
              Afficher les lignes masquées
            </Typography>
          </Grid>
          <Grid item position="relative">
            <Switch onChange={toggleHiddenRowsVisible} checked={hiddenRowsVisible} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ToggleVisibleRowsSwitch.propTypes = {
  hiddenRowsVisible: bool.isRequired,
  toggleHiddenRowsVisible: func.isRequired
};

export default ToggleVisibleRowsSwitch;
