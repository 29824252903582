import {useState, useEffect, useMemo} from 'react';

import {InteractionType} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {oneOfType, node, func} from 'prop-types';

import {scopeBase} from '../authConfig';
import AuthContext from '../contexts/AuthContext';

const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLocalStorageLoaded, setIsLocalStorageLoaded] = useState(false);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [isRegeneratingToken, setIsRegeneratingToken] = useState(false);
  const [loggedIntoBadPartnerErrorMessage, setLoggedIntoBadPartnerErrorMessage] = useState(null);
  const [loggedIntoBadPartnerRedirectUrl, setLoggedIntoBadPartnerRedirectUrl] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);

  const context = useMsal();

  const login = async (scopes = scopeBase) => {
    const {instance, accounts, inProgress} = context;
    const isAuthenticated = accounts?.length > 0;
    const loginRequest = {
      scopes,
      account: accounts[0]
    };

    if (!isAuthenticated && inProgress === InteractionType.None) {
      instance.loginRedirect(loginRequest);
    } else if (isAuthenticated && inProgress === InteractionType.None) {
      // get access token silently from cached id-token
      instance
        .acquireTokenSilent(loginRequest)
        .then(response => {
          setIsTokenInvalid(false);
          setUser({
            ...(user || {}),
            username: accounts[0].name,
            tokenAad: response.accessToken,
            mail: accounts[0].username
          });
        })
        .catch(error => {
          // Refresh access token silently from cached id-token
          // Makes the call to handleredirectcallback
          if (error.errorCode === 'consent_required' || error.errorCode === 'interaction_required' || error.errorCode === 'login_required') {
            instance.acquireTokenRedirect(loginRequest);
          } else if (error.errorCode === '429') {
            // eslint-disable-next-line no-console
            console.error('Our Service Token Server (STS) is overloaded, please try again in sometime');
          } else {
            // eslint-disable-next-line no-console
            console.error(`There was some problem fetching the access token ${error.toString()}`);
          }
        });
    }
  };

  const logout = async () => {
    const {instance} = context;
    instance.logoutRedirect({
      postLogoutRedirectUri: '/login'
    });
    localStorage.clear();
  };

  useEffect(() => {
    if (!user) {
      // if no user logged in, try to fetch save data in localStorage
      const localStorageItem = localStorage.getItem('user');

      if (localStorageItem) {
        try {
          setIsTokenInvalid(false);
          setUser(JSON.parse(localStorageItem));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Failed to parse user from local storage, clearing it');
          // if data in localStorage doesn't exist or is not parseable, remove it
          localStorage.removeItem('user');
        }
      }
    }
    setIsLocalStorageLoaded(true);
  }, []);

  useEffect(() => {
    if (user) {
      // on login, save user in localStorage
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      // on logout, clear user in localStorage
      localStorage.removeItem('user');
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      // [TODO] : if adrian confirms: remove logic setIsRegeneratingToken, check in other component for isRegeneratingToken occurences
      if (isTokenInvalid) {
        setIsRegeneratingToken(true);
        await logout();
        setIsRegeneratingToken(false);
      }
    })();
  }, [isTokenInvalid]);

  const memoizedValues = {
    user,
    login,
    logout,
    profile,
    setProfile,
    isTokenInvalid,
    setIsTokenInvalid,
    isRegeneratingToken,
    setUser,
    loggedIntoBadPartnerErrorMessage,
    setLoggedIntoBadPartnerErrorMessage,
    loggedIntoBadPartnerRedirectUrl,
    setLoggedIntoBadPartnerRedirectUrl,

    isNewUser,
    setIsNewUser
  };

  const useMemoDeps = Object.values(memoizedValues).map(value => value);

  const value = useMemo(() => memoizedValues, useMemoDeps);

  return isLocalStorageLoaded ? <AuthContext.Provider value={value}>{children}</AuthContext.Provider> : null;
};

UserProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default UserProvider;
