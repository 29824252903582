import {useEffect} from 'react';

import * as Sentry from '@sentry/react';
import {useLocation} from 'react-router-dom';

function useEmbedReportLoadingTracking({
  isLoading,
  isLoadingHidden,
  isProjectSlicerLoading,
  selectedReport,
  selectedProject,
  filtersInitialized,
  filtersLoading,
  isSlicerLoading,
  selectedProjectName
}) {
  const location = useLocation();

  useEffect(() => {
    const TIME_BEFORE_SENDING_LOGS_TO_SENTRY = 5000; // 5 seconds delay
    let timeoutId;

    if (!isLoadingHidden) {
      timeoutId = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.info({
          isLoading,
          isLoadingHidden,
          isProjectSlicerLoading,
          selectedReport,
          selectedProject,
          filtersInitialized,
          filtersLoading,
          isSlicerLoading,
          selectedProjectName
        });

        // Send log to Sentry after the timer
        Sentry.captureMessage(`Power BI report loading did not resolved the endLoading event after ${TIME_BEFORE_SENDING_LOGS_TO_SENTRY / 1000} seconds`, {
          level: 'warning',
          extra: {
            isLoading,
            isLoadingHidden,
            isProjectSlicerLoading,
            selectedReport,
            selectedProject,
            filtersInitialized,
            filtersLoading,
            isSlicerLoading,
            selectedProjectName
          }
        });
      }, TIME_BEFORE_SENDING_LOGS_TO_SENTRY);
    }

    if (!location.pathname.startsWith('/reports')) {
      clearTimeout(timeoutId);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoadingHidden, location.pathname]);
}

export default useEmbedReportLoadingTracking;
