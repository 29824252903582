import {useState} from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import styled from 'styled-components';

import usePayment from '../../../hooks/providers/usePayment';
import useProjects from '../../../hooks/providers/useProjects';
import Button from '../../form/buttons/Button/Button';

const CenteredContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DeleteAllConnectionsButton = () => {
  const [isDeletingProjectsLoading, setIsDeletingProjectsLoading] = useState(false);

  const {userHasEndedTrialAndIsOwnerOfProjects} = usePayment();
  const {projects, deleteProject, fetchProjects} = useProjects();

  const deleteAllProjectsCreatedByUser = async () => {
    setIsDeletingProjectsLoading(true);
    const projectsToDelete = projects?.filter(p => p.is_owner);
    await Promise.all(projectsToDelete.map(p => deleteProject(p)));
    await fetchProjects();
    setIsDeletingProjectsLoading(false);
  };

  if (userHasEndedTrialAndIsOwnerOfProjects) {
    return (
      <CenteredContainer>
        <Button size="large" sx={{mt: 2}} disabled={isDeletingProjectsLoading} variant="contained" color="error" startIcon={<DeleteForeverIcon />} onClick={deleteAllProjectsCreatedByUser}>
          Supprimer les connexions dont je suis propriétaire
        </Button>
      </CenteredContainer>
    );
  }
  return null;
};

export default DeleteAllConnectionsButton;
