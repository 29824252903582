import {useEffect, useState} from 'react';

import {List, Box, Grid} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {useLocation} from 'react-router-dom';

import PageContainer from '../components/layout/PageContainer';
import ExpiredSubscriptionAlert from '../components/project/alerts/ExpiredSubscriptionAlert';
import ExpiredTrialAlert from '../components/project/alerts/ExpiredTrialAlert';
import InsufficientSubscriptionAlert from '../components/project/alerts/InsufficientSubscriptionAlert';
import ConnectorsCategoryTitle from '../components/project/create/ConnectorsCategoryTitle/ConnectorsCategoryTitle';
import DeleteAllConnectionsButton from '../components/project/DeleteAllConnectionsButton/DeleteAllConnectionsButton';
import ConnectionsPageModals from '../components/project/modals/ConnectionsPageModals';
import NoConnectionsFound from '../components/project/NoConnectionsFound';
import SeeCustomReportButton from '../components/project/SeeCustomReportButton';
import ActualizeDataDatePicker from '../components/project/single-project-item/actualize-data-button/ActualizeDataDatePicker';
import Project from '../components/project/single-project-item/Project';
import ConnectionsToolbar from '../components/project/toolbar/ConnectionsToolbar/ConnectionsToolbar';
import Spinner from '../components/spinner/Spinner';
import ReportTabs from '../components/tabs/ReportTabs';
import WorkspacesTabs from '../components/tabs/WorkspacesTabs';
import {BREAKPOINTS, CONNECTORS, PROJECTS_GROUPING_TYPES, PROJECTS_VIEW_TYPES} from '../const';
import useBreakpoint from '../hooks/dom/useBreakpoint';
import useAuth from '../hooks/providers/useAuth';
import useLoading from '../hooks/providers/useLoading';
import usePayment from '../hooks/providers/usePayment';
import useProjects from '../hooks/providers/useProjects';
import useWorkspaces from '../hooks/providers/useWorkspaces';

const ContentContainer = muiStyled(Box)(({theme}) => ({
  // maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    padding: '0 10px',
    margin: '0 6px'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '0 24px',
    margin: '0 12px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '0 32px',
    margin: '0 16px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 48px',
    margin: '0 16px'
  }
}));

const SingleProjectContainer = muiStyled(Grid)(({theme}) => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    minWidth: '100% '
  }
}));

// eslint-disable-next-line complexity
const Connections = () => {
  const auth = useAuth();
  const paymentContext = usePayment();

  const [searchedProject, setSearchedProject] = useState('');

  const location = useLocation();

  const projectsContext = useProjects();
  const {selectedReportTab, isConnectorsLoading, setConnector, createProjectModalOpen, setCreateProjectModalOpen, connectionGroupingType, collapsedCategories, setCollapsedCategories, connectors} =
    projectsContext;
  const {isDefaultWorkspace, getWorkspaces} = useWorkspaces();
  const breakpoint = useBreakpoint();
  const {setCompletedApiCalls} = useLoading();
  const projectsLoaded = projectsContext?.projects?.length > 0 && !projectsContext.isLoading;

  // This hook automatically opens project creation modal if location.state.isTiimeProjectCreationModalOpen
  // This is useful because when user logs to Tiime oauth, he leaves the site. We want to redirect him to the initial page (folders)
  // and keep the modal open + tiime connector selected, to improve UX.
  useEffect(() => {
    const shouldOpenProjectCreationModal = location.state?.isTiimeProjectCreationModalOpen;
    if (shouldOpenProjectCreationModal) {
      setCreateProjectModalOpen(true);
      const tiimeConnector = connectors.find(c => c.id === CONNECTORS.tiime);
      if (tiimeConnector) {
        setConnector(tiimeConnector);
        // We clear the state to prevent re-opening create modal on tiime connector if user reload page, because state is persist on page reload
        // navigate(location.pathname, {replace: true});
      }
    }
  }, [connectors]);

  useEffect(() => {
    (async () => {
      if (auth?.user?.tokenAad && projectsContext.shouldFetchProjects) {
        if (auth.isNewUser) {
          await projectsContext.fetchProjects();
        } else {
          await Promise.all([getWorkspaces(), projectsContext.fetchProjects()]);
        }
        setCompletedApiCalls(prevCompleted => prevCompleted + 2);
        projectsContext.setShouldFetchProjects(false);
      }
    })();
  }, [auth?.user?.tokenAad, projectsContext.shouldFetchProjects]);

  // This hook aims to fetch stripe products list
  // Even if products are not used on this page, it will reduce loading time on /profile and /abonnement pages when user navigates there
  useEffect(() => {
    (async () => {
      const {getProductsList, products, productsLoading} = paymentContext;
      if (products?.length < 1 && !productsLoading) {
        await getProductsList();
      }
    })();
  }, [auth?.user?.tokenAad]);

  // This hook tracks window size. Its goal is to automatically set projects list view type to CARD
  // When screen becomes too small to display projects as a list
  useEffect(() => {
    if (breakpoint === BREAKPOINTS.xs) {
      projectsContext.setViewType(PROJECTS_VIEW_TYPES.card);
    }
  }, [breakpoint]);

  const getSectionProjects = sectionId => {
    let projects = [];

    if (isDefaultWorkspace) {
      if (connectionGroupingType === PROJECTS_GROUPING_TYPES.connection) {
        projects = (projectsContext.projects || []).filter(p => p.category === sectionId);
      } else {
        projects = (projectsContext.projects || []).filter(p => p.project_name === sectionId);
      }
    } else {
      projects = (projectsContext.projects || []).filter(p => p.report_id === selectedReportTab);
    }

    return projects;
  };

  const getFilteredProjectsBasedOnSearchInput = projects => {
    if (searchedProject) {
      const lowerCaseSearchedProject = searchedProject.toLowerCase();
      return projects.filter(
        p =>
          p.project_name.toLowerCase().includes(lowerCaseSearchedProject) ||
          p.siren.includes(lowerCaseSearchedProject) ||
          p.users?.find(u => u.username.toLowerCase().includes(lowerCaseSearchedProject)) ||
          p.connector.includes(lowerCaseSearchedProject)
      );
    }

    return projects;
  };

  const handleCategoryOpenOrCollapse = category => {
    if (collapsedCategories.includes(category)) {
      setCollapsedCategories(currentCategories => [...currentCategories].filter(c => c !== category));
    } else {
      setCollapsedCategories(currentCategories => [...currentCategories, category]);
    }
  };

  const renderProjectsCardsOrListItems = (isSection = false, sectionTitle = '') => {
    const {viewType} = projectsContext;
    const projects = isSection ? getSectionProjects(sectionTitle) : projectsContext.projects;

    const filteredProjects = getFilteredProjectsBasedOnSearchInput(projects);

    return viewType === PROJECTS_VIEW_TYPES.card ? (
      <>
        {isSection ? <ConnectorsCategoryTitle withArrow category={sectionTitle} onArrowClick={() => handleCategoryOpenOrCollapse(sectionTitle)} /> : null}
        <Grid mt={2} container spacing={2} justifyContent="center" alignItems="stretch">
          {filteredProjects.map((project, index) => (
            <SingleProjectContainer key={index} item xs sm={6} md={4}>
              <Project project={project} />
            </SingleProjectContainer>
          ))}
        </Grid>
      </>
    ) : (
      <>
        {isSection ? <ConnectorsCategoryTitle withArrow category={sectionTitle} onArrowClick={() => handleCategoryOpenOrCollapse(sectionTitle)} /> : null}
        <List dense sx={{mt: 2}}>
          {filteredProjects.map((project, index) => (
            <Project key={index} project={project} />
          ))}
        </List>
      </>
    );
  };

  const renderSectionProjects = sectionId => {
    const projects = getSectionProjects(sectionId);
    const filteredProjects = getFilteredProjectsBasedOnSearchInput(projects);
    const isCategoryOpen = !collapsedCategories.includes(sectionId);

    if (!projectsLoaded) {
      return null;
    }

    if (!isCategoryOpen) {
      return <ConnectorsCategoryTitle withArrow category={sectionId} onArrowClick={() => handleCategoryOpenOrCollapse(sectionId)} />;
    }

    if (filteredProjects.length === 0) {
      return null;
    }

    return renderProjectsCardsOrListItems(true, sectionId);
  };

  const renderConnections = () => {
    let sections = [];
    if (connectionGroupingType === PROJECTS_GROUPING_TYPES.folder) {
      const foldersNames = (projectsContext.projects || []).map(p => p.project_name);
      const uniqueFoldersNames = [...new Set([...foldersNames])];
      sections = uniqueFoldersNames;
    } else {
      const categories = [...new Set(connectors.map(c => c.category))];
      sections = categories;
    }

    if (connectionGroupingType === PROJECTS_GROUPING_TYPES.connection && isConnectorsLoading) {
      return <Spinner isLoading text="Chargement des connecteurs..." />;
    }

    return (
      <Box mt={3}>
        {sections.map(section => (
          <Box key={section} mt={5}>
            {renderSectionProjects(section)}
          </Box>
        ))}
      </Box>
    );
  };

  const renderCustomWorkspaceConnections = () => {
    return renderProjectsCardsOrListItems(false);
  };

  const getContent = () => {
    const filteredProjects = getFilteredProjectsBasedOnSearchInput(projectsContext.projects);
    const noProjectsFound = (filteredProjects || []).length === 0 && !projectsContext.isLoading && searchedProject;

    const isNoticeReport = projectsContext.currentReport.is_notice_report;
    const isCustomReport = projectsContext.currentReport.isCustomModel;

    if (isNoticeReport || isCustomReport) {
      return (
        <Grid item xs="auto" sx={{mt: 2}}>
          <SeeCustomReportButton isNoticeReport={isNoticeReport} isCustomReport={isCustomReport} />
        </Grid>
      );
    }

    return (
      <Grid container flex={1} maxWidth="lg" sx={{margin: '0 auto'}}>
        <Grid
          item
          xs={12}
          pb={3}
          px={{
            xs: 0,
            sm: 2
          }}
        >
          <ExpiredTrialAlert />
          <ExpiredSubscriptionAlert />
          <InsufficientSubscriptionAlert />

          <ConnectionsToolbar searchedProject={searchedProject} setSearchedProject={setSearchedProject} />

          <Spinner text="Chargement des connexions" isLoading={projectsContext.isLoading && !createProjectModalOpen} />

          <ActualizeDataDatePicker />

          <DeleteAllConnectionsButton />

          {isDefaultWorkspace ? renderConnections() : renderCustomWorkspaceConnections()}

          {noProjectsFound && <NoConnectionsFound />}
        </Grid>
      </Grid>
    );
  };

  return (
    <PageContainer>
      <WorkspacesTabs />
      <ContentContainer>
        {!isDefaultWorkspace ? <ReportTabs renderContent={getContent} /> : <Box px={1}>{getContent()}</Box>}
        <ConnectionsPageModals />
      </ContentContainer>
    </PageContainer>
  );
};

export default Connections;
