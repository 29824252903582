import React from 'react';

import {Grid} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import {CHATBOT_MESSAGES_ROLES} from '../../../../const';
import useChatbot from '../../../../hooks/providers/useChatbot';
import useProfile from '../../../../hooks/providers/useProfile';
import {messageShape} from '../../../../proptypes-shapes';
import {getMessageAuthorName} from '../../../../utils';
import MessageAvatar from '../MessageAvatar/MessageAvatar';
import MessageContent from '../MessageContent/MessageContent';
import MessageFiles from '../MessageFiles/MessageFiles';
import MessageHeader from '../MessageHeader/MessageHeader';

const Message = ({message}) => {
  const theme = useTheme();
  const {assistants, isStreamingMessage} = useChatbot();
  const {profile} = useProfile();
  const isMessageMine = message.role === CHATBOT_MESSAGES_ROLES.user;
  const messageAuthor = getMessageAuthorName(message, profile, assistants);
  const assistant = assistants.find(a => a.id === message.assistant_id);
  const assistantHasJustAnsweredNow = isStreamingMessage && !isMessageMine;
  const pictureFlexOrder = isMessageMine ? 1 : 0;
  const messageContent = message.content[0]?.text.value || '';
  const formattedContent = messageContent.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <Grid container justifyContent={isMessageMine ? 'flex-end' : 'flex-start'} spacing={1} sx={{background: isMessageMine ? '#fff' : theme.palette.primary.light}} pt={1} pb={2.5} px={1} mb={1}>
      <Grid item xs="auto" sx={{order: pictureFlexOrder}}>
        <MessageAvatar isMessageMine={isMessageMine} assistant={assistant} />
      </Grid>

      <Grid item xs={10} pb={1} display="flex" alignItems={isMessageMine ? 'flex-end' : 'flex-start'} flexDirection="column">
        <MessageHeader messageAuthor={messageAuthor} assistantHasJustAnsweredNow={assistantHasJustAnsweredNow} />
        <MessageContent isMessageMine={isMessageMine} messageContent={messageContent} formattedContent={formattedContent} />
        {message.files?.length > 0 && <MessageFiles files={message.files} />}
      </Grid>
    </Grid>
  );
};

Message.propTypes = {
  message: messageShape.isRequired
};

export default Message;
