import {useEffect, useState} from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';

import ErrorComponent from '../components/error/ErrorComponent';
import Button from '../components/form/buttons/Button/Button';
import PageContainer from '../components/layout/PageContainer';
import Link from '../components/link/Link';
import BillsTable from '../components/payment/BillsTable';
import Spinner from '../components/spinner/Spinner';
import PageTitle from '../components/typography/PageTitle';
import {APP_ROUTES} from '../const';
import usePayment from '../hooks/providers/usePayment';

const Bills = () => {
  const [billsLoading, setBillsLoading] = useState(false);
  const {getUserBills, bills, getUserBillsErrorMessage = []} = usePayment();

  useEffect(() => {
    (async () => {
      setBillsLoading(true);
      await getUserBills();
      setBillsLoading(false);
    })();
  }, []);

  const renderBills = () => {
    if (billsLoading) {
      return (
        <Grid container flex={1}>
          <Spinner isLoading text="Chargement de vos factures" />
        </Grid>
      );
    }

    if (!bills || bills?.length === 0) {
      return <Typography variant="h6">Aucune facture</Typography>;
    }

    return <BillsTable bills={bills || []} />;
  };

  return (
    <PageContainer>
      <Grid container alignItems="flex-start" sx={{mb: 2}}>
        <Link to={APP_ROUTES.profile}>
          <Button size="small" startIcon={<ArrowBackIcon />} variant="text">
            Retour à mon compte
          </Button>
        </Link>
      </Grid>
      <Box sx={{alignSelf: 'flex-start', mb: 5}}>
        <PageTitle>Factures et historique de facturation</PageTitle>
      </Box>
      {getUserBillsErrorMessage ? <ErrorComponent message={getUserBillsErrorMessage} href={APP_ROUTES.profile} /> : renderBills()}
    </PageContainer>
  );
};

export default Bills;
