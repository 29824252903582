import {oneOfType, node, func} from 'prop-types';
import {Navigate} from 'react-router-dom';

import {APP_ROUTES} from '../const';
import useProfile from '../hooks/providers/useProfile';

const RequireToBeDrivnUser = ({children}) => {
  const {isPartnerPolicy} = useProfile();

  if (isPartnerPolicy) {
    return <Navigate to={APP_ROUTES.connections} replace />;
  }

  return children;
};

RequireToBeDrivnUser.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default RequireToBeDrivnUser;
