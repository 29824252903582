import {Typography, Box} from '@mui/material';

import {assistantShape} from '../../../proptypes-shapes';

const AssistantWelcomeMessage = ({assistant}) => {
  return (
    <Box
      sx={{
        width: '33%',
        margin: '0 auto',
        textAlign: 'center'
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontStyle: 'italic',
          fontSize: '0.7rem'
        }}
      >
        &quot;{assistant.description}&quot;
      </Typography>
    </Box>
  );
};

AssistantWelcomeMessage.propTypes = {
  assistant: assistantShape.isRequired
};

export default AssistantWelcomeMessage;
