import {List, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {arrayOf, shape} from 'prop-types';

import {CONVERSATIONS_TIME_PERIODS_TRANSLATIONS} from '../../../../const';
import {threadShape} from '../../../../proptypes-shapes';
import ConversationItem from '../ConversationItem/ConversationItem';

const GroupedThreadsList = ({groupedThreads}) => {
  return (
    <List dense sx={{maxWidth: 300}}>
      {Object.entries(groupedThreads).map(([timePeriod, conversations]) => (
        <Box key={timePeriod} mb={1.5}>
          <Typography ml={2} fontSize={16} variant="h6" color="primary" fontFamily="SoehneBreitKraftig">
            {CONVERSATIONS_TIME_PERIODS_TRANSLATIONS[timePeriod]}
          </Typography>
          {conversations.map(t => (
            <ConversationItem key={t.thread_id} thread={t} />
          ))}
        </Box>
      ))}
    </List>
  );
};

GroupedThreadsList.propTypes = {
  groupedThreads: shape({
    today: arrayOf(threadShape).isRequired,
    yesterday: arrayOf(threadShape).isRequired,
    last7Days: arrayOf(threadShape).isRequired,
    last30Days: arrayOf(threadShape).isRequired,
    thisMonth: arrayOf(threadShape).isRequired,
    thisYear: arrayOf(threadShape).isRequired
  }).isRequired
};

export default GroupedThreadsList;
