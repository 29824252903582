import {useRef, useEffect} from 'react';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const DEFAULT_THRESHOLD = 40;
const DEFAULT_SCROLL_SPEED = 10;

const AutoScroll = ({
  children,
  threshold = DEFAULT_THRESHOLD, // pixels from edge to trigger scroll
  scrollSpeed = DEFAULT_SCROLL_SPEED,
  enabled = true, // allows disabling the auto-scroll
  className = ''
}) => {
  const containerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  const handleScroll = e => {
    if (!enabled) return;

    const container = containerRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const mouseY = e.clientY;

    // Calculate distance from bottom/top edge
    const distanceFromBottom = rect.bottom - mouseY;
    const distanceFromTop = mouseY - rect.top;

    // Clear any existing scroll interval
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }

    // Start scrolling if near edges
    if (distanceFromBottom < threshold) {
      scrollIntervalRef.current = setInterval(() => {
        container.scrollTop += scrollSpeed;
      }, 16);
    } else if (distanceFromTop < threshold) {
      scrollIntervalRef.current = setInterval(() => {
        container.scrollTop -= scrollSpeed;
      }, 16);
    }
  };

  const handleMouseLeave = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
  };

  useEffect(() => {
    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, []);

  return (
    <Box
      role="region"
      ref={containerRef}
      className={className}
      onMouseMove={handleScroll}
      onMouseLeave={handleMouseLeave}
      sx={{
        height: '100%',
        maxHeight: '80vh',
        overflow: 'auto',
        pl: 2
      }}
    >
      {children}
    </Box>
  );
};

AutoScroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  threshold: PropTypes.number,
  scrollSpeed: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  enabled: PropTypes.bool,
  className: PropTypes.string
};

AutoScroll.defaultProps = {
  threshold: DEFAULT_THRESHOLD,
  scrollSpeed: DEFAULT_SCROLL_SPEED,
  enabled: true,
  className: ''
};

export default AutoScroll;
