import {CloudUpload} from '@mui/icons-material';
import {MenuItem, ListItemIcon} from '@mui/material';
import Typography from '@mui/material/Typography';

import {CONNECTORS_HAVING_IMPORT_DATA_BUTTON} from '../../../../const';
import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';
import useUpload from '../../../../hooks/providers/useUpload';

const ProjectMenuImportDataButton = () => {
  const {selectedProject: project} = useProjects();
  const {setProjectSelected, setIsUploadFilesModalOpen} = useUpload();
  const {openModal} = useProjectCrudModal({setModalOpen: setIsUploadFilesModalOpen, project});
  const shouldRender = CONNECTORS_HAVING_IMPORT_DATA_BUTTON.includes(project?.connector);

  const handleOpen = openModalFunction => {
    setProjectSelected(project.siren);
    openModalFunction();
  };

  if (!shouldRender) {
    return null;
  }

  return (
    <MenuItem onClick={() => handleOpen(openModal)}>
      <ListItemIcon>
        <CloudUpload fontSize="small" color="primary" />
      </ListItemIcon>
      <Typography variant="inherit">Importer les données</Typography>
    </MenuItem>
  );
};

export default ProjectMenuImportDataButton;
