import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Grid, Typography} from '@mui/material';

import Button from '../components/form/buttons/Button/Button';
import Link from '../components/link/Link';
import {APP_ROUTES} from '../const';

const PaymentSuccess = () => {
  return (
    <Grid sx={{height: 'calc(100vh - 64px)'}} container justifyContent="center" alignItems="center" direction="column" flex={1}>
      <CheckCircleIcon sx={{fontSize: 64, mb: 3}} color="success" />
      <Typography variant="h4" fontWeight="bold" color="#000" mb={1}>
        Paiement effectué!
      </Typography>
      <Typography variant="p" mb={3}>
        Merci de vous être abonné à Drivn.
      </Typography>

      <Link to={APP_ROUTES.connections}>
        <Button variant="contained" color="secondary" size="large">
          Revenir à mes connexions
        </Button>
      </Link>
    </Grid>
  );
};

export default PaymentSuccess;
