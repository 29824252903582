/* eslint-disable react/boolean-prop-naming */
import {FormControl, InputLabel, Select} from '@mui/material';
import {arrayOf, bool, func, node, oneOfType, shape, string} from 'prop-types';

const FormSelect = ({withoutLabel = false, variant, sx, label, labelId, value, onChange, items, disabled, size = 'medium', renderValue, darkMode}) => {
  const labelSx = darkMode
    ? {
        color: 'white !important'
      }
    : {};

  const disabledLabelSx = darkMode
    ? {
        WebkitTextFillColor: '#dcdcdc !important'
      }
    : {};

  return (
    <FormControl variant={variant} disabled={disabled} sx={sx} size={size}>
      {withoutLabel ? null : (
        <InputLabel sx={labelSx} id={labelId}>
          {label}
        </InputLabel>
      )}
      <Select
        sx={
          withoutLabel
            ? {
                '& .Mui-disabled': disabledLabelSx,
                '& .MuiFilledInput-input': {
                  padding: '10px 20px'
                },
                '& .MuiFilledInput-input:focus': {
                  background: 'transparent'
                }
              }
            : {}
        }
        displayEmpty={withoutLabel}
        inputProps={{'data-testid': 'mui-select'}}
        renderValue={renderValue}
        labelId={labelId}
        value={value}
        label={label}
        onChange={onChange}
      >
        {items}
      </Select>
    </FormControl>
  );
};

FormSelect.defaultProps = {
  sx: {},
  disabled: false,
  withoutLabel: false,
  size: 'medium',
  renderValue: null,
  variant: 'outlined',
  darkMode: false,
  label: '',
  labelId: ''
};

FormSelect.propTypes = {
  disabled: bool,
  sx: shape({}),
  label: string,
  labelId: string,
  value: string.isRequired,
  onChange: func.isRequired,
  items: oneOfType([arrayOf(node), node]).isRequired,
  size: string,
  renderValue: func,
  variant: string,
  withoutLabel: bool,
  darkMode: bool
};

export default FormSelect;
