import {RuleFolder} from '@mui/icons-material';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';
import {projectShape} from '../../../../proptypes-shapes';
import ProjectActionButton from '../ProjectActionButton';
import ProjectMenuUpdateButton from './ProjectMenuUpdateButton';

const UpdateProjectButton = ({project}) => {
  const {setUpdateProjectModalOpen} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setUpdateProjectModalOpen, project});

  return <ProjectActionButton cardMarkup={<ProjectMenuUpdateButton />} listMarkup={<RuleFolder sx={{cursor: 'pointer'}} fontSize="small" color="primary" onClick={openModal} />} />;
};

UpdateProjectButton.defaultProps = {
  project: null
};

UpdateProjectButton.propTypes = {
  project: projectShape
};

export default UpdateProjectButton;
