import {useEffect, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {APP_ROUTES} from '../const';
import useAuth from '../hooks/providers/useAuth';
import useError from '../hooks/providers/useError';
import useLoading from '../hooks/providers/useLoading';
import useProfile from '../hooks/providers/useProfile';
import useProjects from '../hooks/providers/useProjects';
import useReport from '../hooks/providers/useReport';

const RequireConsentsAndHelpButtons = ({children}) => {
  const [consentsFetched, setConsentsFetched] = useState(false);

  const auth = useAuth();
  const {consents, fetchConsents, isConsentsLoading, getHelp, getHelpButtons, isGetHelpLoading, setIsGetHelpLoading} = useProfile();
  const {triggerError} = useError();
  const {setCompletedApiCalls} = useLoading();
  const {getConnectors} = useProjects();
  const {loadReports} = useReport();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if ((consents || []).length < 1 && auth?.user?.tokenAad) {
        const res = await fetchConsents();

        if (!isConsentsLoading) {
          if (!res.success && res.status >= 500) {
            triggerError('Unexpected error');
          }
          setCompletedApiCalls(prevCompleted => prevCompleted + 1);
        }
      } else if (consents.length > 0 && consents.filter(c => c?.consent_value).length < 2) {
        navigate(APP_ROUTES.subscribe);
        setConsentsFetched(true);
      } else {
        setConsentsFetched(true);
        loadReports();
        getConnectors();
      }
    })();
  }, [consents, auth?.user?.tokenAad]);

  useEffect(() => {
    (async () => {
      if (!getHelpButtons && !isGetHelpLoading && auth?.user?.tokenAad) {
        setIsGetHelpLoading(true);
        await getHelp();
        setIsGetHelpLoading(false);
        setCompletedApiCalls(prevCompleted => prevCompleted + 1);
      }
    })();
  }, []);

  if (!consentsFetched || (consents || []).length < 1 || isGetHelpLoading || !getHelpButtons) {
    return null;
  }

  return children;
};

RequireConsentsAndHelpButtons.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default RequireConsentsAndHelpButtons;
