import {useState} from 'react';

import {Skeleton, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import muiStyled from '@mui/material/styles/styled';
import {bool, func, shape, string} from 'prop-types';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {APP_ROUTES} from '../../const';
import usePayment from '../../hooks/providers/usePayment';
import drivnSymbol from '../../img/drivn-symbol-dark.png';
import {getFrenchFormattedDate} from '../../utils';
import ErrorComponent from '../error/ErrorComponent';
import Button from '../form/buttons/Button/Button';
import Link from '../link/Link';
import WarningDowngradingSubscriptionModal from '../payment/WarningDowngradingSubscriptionModal';
import Spinner from '../spinner/Spinner';

const MembershipLogo = ({hasMembership = false, productImageUrl = '', onLoad}) => {
  const backgroundColor = hasMembership ? process.env.REACT_APP_MAIN_COLOR : process.env.REACT_APP_SECONDARY_COLOR;
  const style = {
    background: backgroundColor,
    borderRadius: 10,
    maxWidth: 120,
    maxHeight: 120
  };
  return <img src={hasMembership ? productImageUrl : drivnSymbol} alt="" style={style} onLoad={onLoad} />;
};

MembershipLogo.defaultProps = {
  hasMembership: false,
  productImageUrl: ''
};

MembershipLogo.propTypes = {
  hasMembership: bool,
  productImageUrl: string,
  onLoad: func.isRequired
};

const Title = muiStyled(Typography)(({theme}) => ({
  marginBottom: theme.spacing(2),
  fontSize: 15,
  fontWeight: 'bold',
  font: 'normal normal normal 16px/25px SoehneBreitKraftig'
}));

const MembershipName = muiStyled(Typography)(({theme}) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  font: 'normal normal 600 26px/40px InstrumentSans'
}));

const MembershipPrice = muiStyled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 'bold',
  fontFamily: 'SoehneBreitKraftig',
  lineHeight: 1
}));

const MembershipPriceDetails = styled.span`
  font-size: 14px;
  vertical-align: top;
`;

const MembershipNumberOfProjects = muiStyled(Typography)(({theme}) => ({
  marginBottom: theme.spacing(3),
  fontSize: 13
}));

const MembershipDescription = muiStyled(Box)(({theme}) => ({
  marginBottom: 26,
  fontSize: 13,
  textAlign: 'center',
  padding: `0 ${theme.spacing(1)}`,
  minHeight: 38,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

// eslint-disable-next-line complexity
const MembershipComponent = ({hasMembership, subscription}) => {
  const [membershipImageLoading, setMembershipImageLoading] = useState(true);
  const [warningDowngradingSubscriptionModalOpen, setWarningDowngradingSubscriptionModalOpen] = useState(false);
  const {products, maxNumberOfProjects, isDowngradingSubscription, newProductAfterDowngrading} = usePayment();

  const productSubscribed = products.find(p => p.id === subscription?.product_id);
  const formattedDate = subscription ? getFrenchFormattedDate(subscription?.current_period_end, 'long') : '';
  const navigate = useNavigate();
  const periodicityWord = productSubscribed?.interval === 'month' ? 'mois' : 'an';
  const numberOfProjectsWord = maxNumberOfProjects > 1 ? 'connexions' : 'connexion';

  const getMembershipDescription = () => {
    if (!hasMembership) {
      return 'Pour profiter pleinement de toutes les fonctionnalités, veuillez sélectionner un abonnement.';
    }
    const yearlyOrMonthlyWord = productSubscribed?.interval === 'month' ? 'mensualisé' : 'annualisé';

    return (
      <span style={{lineHeight: 1.5}}>
        {subscription?.auto_renew ? (
          <>
            Prochain paiement le {formattedDate}
            <br />
            Abonnement {yearlyOrMonthlyWord}
          </>
        ) : (
          `Cet abonnement prendra fin le ${formattedDate}`
        )}
        {isDowngradingSubscription && (
          <Box mt={1}>
            Votre nouveau plan{' '}
            <span
              style={{
                fontWeight: 'bold',
                textDecoration: 'underline'
              }}
            >
              {' '}
              {newProductAfterDowngrading}
            </span>
            &nbsp;démarrera le {formattedDate}
          </Box>
        )}
      </span>
    );
  };

  const getMembershipName = () => {
    if (hasMembership) {
      return productSubscribed?.name?.split('-')[0];
    }
    return maxNumberOfProjects > 0 ? "Période d'essai" : 'Aucun abonnement';
  };

  const getBillsButtonMarginBottom = () => {
    if (hasMembership) {
      return isDowngradingSubscription ? 0 : '27px';
    }

    return '11px';
  };

  const onSubscriptionButtonClick = () => {
    if (!isDowngradingSubscription) {
      navigate(APP_ROUTES.subscription);
    }
    setWarningDowngradingSubscriptionModalOpen(true);
  };

  return (
    <>
      <Title variant="h6">{hasMembership ? 'Votre formule actuelle' : 'Aucune formule choisie'}</Title>

      {membershipImageLoading && <Skeleton variant="rectangular" width={120} height={120} />}
      <MembershipLogo
        hasMembership={hasMembership}
        productImageUrl={subscription?.product_image}
        onLoad={() => {
          setMembershipImageLoading(false);
        }}
      />
      <MembershipName>{getMembershipName()}</MembershipName>
      <MembershipPrice>
        {hasMembership ? productSubscribed?.amount : '0'}
        <MembershipPriceDetails>€ht/{periodicityWord}</MembershipPriceDetails>
      </MembershipPrice>
      <MembershipNumberOfProjects>
        {maxNumberOfProjects} {numberOfProjectsWord}
      </MembershipNumberOfProjects>
      <MembershipDescription>{getMembershipDescription()}</MembershipDescription>

      <Button onClick={onSubscriptionButtonClick} size="large" variant="contained" color="secondary">
        {hasMembership ? 'Modifier' : 'Choisir'} ma formule
      </Button>

      <Grid item container alignItems="center" sx={{width: 'auto', mt: 1, marginBottom: getBillsButtonMarginBottom()}}>
        <Link to={APP_ROUTES.bills}>
          <Button variant="text">Mes facturations</Button>
        </Link>
      </Grid>

      <WarningDowngradingSubscriptionModal isOpen={warningDowngradingSubscriptionModalOpen} onClose={() => setWarningDowngradingSubscriptionModalOpen(false)} />
    </>
  );
};

const Membership = ({isLoading}) => {
  const {hasMembership, productSubscribed, getProductsList, getProductsListErrorMessage, productsLoading} = usePayment();

  const fetchProductsList = async () => {
    await getProductsList();
  };

  if (getProductsListErrorMessage) {
    return (
      <Box pt={2}>
        <ErrorComponent message={getProductsListErrorMessage} onClick={fetchProductsList} buttonText="Réessayer" />
      </Box>
    );
  }

  return (
    <Grid container direction="column" alignItems="center">
      {isLoading || productsLoading ? <Spinner text="Chargement de votre formule d'abonnement" isLoading /> : <MembershipComponent hasMembership={hasMembership} subscription={productSubscribed} />}
    </Grid>
  );
};

MembershipComponent.defaultProps = {
  hasMembership: false,
  subscription: {}
};

MembershipComponent.propTypes = {
  hasMembership: bool,
  subscription: shape({
    auto_renew: bool,
    current_period_end: string,
    product_description: string,
    product_id: string,
    product_image: string,
    product_name: string,
    subscription_id: string
  })
};

Membership.propTypes = {
  isLoading: bool.isRequired
};

export default Membership;
