import {useEffect, useState} from 'react';

import {TextField, DialogContent, DialogActions, RadioGroup} from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import {func, bool} from 'prop-types';
import 'dayjs/locale/fr';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import {getAccountantExpectedElementsVisualJsonData} from '../../../utils';
import Button from '../../form/buttons/Button/Button';
import FormUppercaseLabel from '../../form/FormUppercaseLabel/FormUppercaseLabel';
import FormSelect from '../../form/MuiSelect/MuiSelect';
import Radio from '../../form/Radio/Radio';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import DocumentRequestSpreadsheet from '../DocumentRequestSpreadsheet';

const AccountantDocumentRequestModal = ({isOpen, closeModal}) => {
  const {selectedProject: project, reportSelectedPage, sendAccountantDocumentRequest} = useReport();

  const [isUserRegisteredOnApp, setIsUserRegisteredOnApp] = useState(true);
  const [recipientUserId, setRecipientUserId] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [accountingData, setAccountingData] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetValues = () => {
    setRecipientUserId('');
    setRecipientEmail('');
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const res = await sendAccountantDocumentRequest({
      siren: project.siren,
      data: accountingData,
      userId: recipientUserId,
      userEmail: recipientEmail
    });

    if (res.success) {
      closeModal();
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    (async () => {
      const data = await getAccountantExpectedElementsVisualJsonData(reportSelectedPage);
      const formattedData = data.map(e => {
        const dateKey = 'Date pièce';
        const dateBeforeFormatting = e[dateKey];
        const dateAfterFormatting = dateBeforeFormatting.substring(0, 10);
        return {
          ...e,
          [dateKey]: dateAfterFormatting
        };
      });
      setAccountingData(formattedData);
      await resetValues();
    })();
  }, [isOpen]);

  const hasEmailError = recipientEmail !== '' && !recipientUserId && !REGULAR_EXPRESSIONS.email.test(recipientEmail);
  const submitButtonDisabled = hasEmailError || (!recipientUserId && !recipientEmail) || isSubmitting;

  return (
    <BaseModal shouldMatchEmbedReportWidth maxWidth={false} onClose={closeModal} open={isOpen} title="Envoyer une demande de documents">
      <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
        <Box mb={1}>
          <FormUppercaseLabel label="Récapitulatif des données" />
        </Box>
        {accountingData?.length > 0 && (
          <Box overflow="scroll">
            <DocumentRequestSpreadsheet data={accountingData} updateData={setAccountingData} />
          </Box>
        )}

        <Box mt={2.5}>
          <FormUppercaseLabel label="Sélectionner un destinataire" />
          <RadioGroup
            sx={{mb: 1.5}}
            row
            value={isUserRegisteredOnApp}
            onChange={() => {
              resetValues();
              setIsUserRegisteredOnApp(!isUserRegisteredOnApp);
            }}
            aria-labelledby="Choisir un utilisateur de l'application ou saisir un email"
            name="user-is-registered-on-app"
          >
            <Radio value label="Choisir dans la liste des utilisateurs" checked={isUserRegisteredOnApp} />
            <Radio value={false} label="Saisir un email" checked={!isUserRegisteredOnApp} />
          </RadioGroup>

          <Box mb={2}>
            {isUserRegisteredOnApp ? (
              <FormSelect
                sx={{width: '100%'}}
                label="Choisissez un utilisateur"
                labelId="user-email-select"
                value={recipientUserId}
                onChange={e => {
                  setRecipientUserId(e.target.value);
                }}
                items={project?.users?.map(u => (
                  <MenuItem key={u.user_id} selected={u.user_id === recipientUserId} value={u.user_id}>
                    {u.username}
                  </MenuItem>
                ))}
              />
            ) : (
              <TextField
                disabled={recipientUserId !== ''}
                required
                value={recipientEmail}
                onChange={event => {
                  setRecipientEmail(event.target.value);
                }}
                label="Email du destinataire"
                placeholder="Renseignez l'email du destinataire..."
                color="primary"
                fullWidth
                error={hasEmailError}
                helperText={hasEmailError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.email : ''}
              />
            )}
          </Box>
        </Box>
        {isSubmitting && <Spinner text="Envoi de la demande" isLoading />}
      </DialogContent>

      <DialogActions sx={{justifyContent: 'center'}}>
        <Button size="large" variant="contained" color="secondary" disabled={submitButtonDisabled} onClick={handleSubmit}>
          Envoyer la demande
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

AccountantDocumentRequestModal.propTypes = {
  isOpen: bool.isRequired,
  closeModal: func.isRequired
};

export default AccountantDocumentRequestModal;
