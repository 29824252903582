import AddFolderIcon from '@mui/icons-material/CreateNewFolderOutlined';
import {Box} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

import {BREAKPOINTS} from '../../../../const';
import useBreakpoint from '../../../../hooks/dom/useBreakpoint';
import usePayment from '../../../../hooks/providers/usePayment';
import useProjects from '../../../../hooks/providers/useProjects';
import Button from '../../../form/buttons/Button/Button';

const CreateButtonContainer = muiStyled(Box)(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    borderLeftWidth: 0,
    padding: '0 4px 0 0'
  },
  borderLeftWidth: 1,
  borderRightWidth: 1,
  borderColor: '#DCE0EA',
  borderStyle: 'solid',
  padding: '0 10px'
}));

const CreateConnectionButton = () => {
  const {isGuestMode, isLoading, setMaximumNumberOfProjectsReachedModalOpen, setCreateProjectModalOpen} = useProjects();
  const {numberOfProjectsOwnedByUser, maxNumberOfProjects} = usePayment();
  const breakpoint = useBreakpoint();
  const {isAdmin} = usePayment();

  const handleClick = () => {
    const numberOfProjectsLimitReached = numberOfProjectsOwnedByUser >= maxNumberOfProjects;

    if (numberOfProjectsLimitReached) {
      setMaximumNumberOfProjectsReachedModalOpen(true);
    } else {
      setCreateProjectModalOpen(true);
    }
  };

  // Business rule : if user is invited on a project, he cannot create any new projects.
  // Exception : current user is admin and should be able to perform all actions in app.
  const shouldDisplayCreateProjectButton = !isGuestMode || isAdmin;
  const isSmallScreen = breakpoint === BREAKPOINTS.xs || breakpoint === BREAKPOINTS.sm;
  const text = isSmallScreen ? 'Nouvelle connexion' : 'Ajouter une nouvelle connexion';

  if (shouldDisplayCreateProjectButton) {
    return (
      <CreateButtonContainer>
        <Button disabled={isLoading} onClick={handleClick} size={isSmallScreen ? 'small' : 'large'} variant="contained" color="secondary" startIcon={isSmallScreen ? null : <AddFolderIcon />}>
          {text}
        </Button>
      </CreateButtonContainer>
    );
  }
  return null;
};

export default CreateConnectionButton;
