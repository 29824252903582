import {Grid} from '@mui/material';
import Box from '@mui/material/Box';

import useProjects from '../../../../hooks/providers/useProjects';
import ConnectorBox from '../ConnectorBox/ConnectorBox';
import ConnectorsCategoryTitle from '../ConnectorsCategoryTitle/ConnectorsCategoryTitle';

const Connectors = () => {
  const {connectors} = useProjects();

  const groupedByCategoryConnectors = connectors.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  return (
    <>
      {Object.entries(groupedByCategoryConnectors).map(([category, categoryConnectors]) => {
        return (
          <div key={category}>
            <Box mt={2.5} mb={3.5}>
              <ConnectorsCategoryTitle category={category} />
            </Box>

            <Grid container spacing={1} justifyContent="center">
              {categoryConnectors.map(c => (
                <ConnectorBox key={c.id} connector={c} />
              ))}
            </Grid>
          </div>
        );
      })}
    </>
  );
};

export default Connectors;
