import {Grid} from '@mui/material';

import {messageShape} from '../../../proptypes-shapes';
import CsvFileMessage from '../CsvFileMessage/CsvFileMessage';

const UploadedFileMessage = ({message}) => {
  const filename = message?.content[0]?.text?.value;

  if (!filename) return null;

  return (
    <Grid container justifyContent="flex-end" spacing={1} px={1} mb={1}>
      <Grid item xs={10} pb={1} display="flex" alignItems="flex-end" flexDirection="column">
        <CsvFileMessage size="large" filename={filename} />
      </Grid>
    </Grid>
  );
};

UploadedFileMessage.propTypes = {
  message: messageShape.isRequired
};

export default UploadedFileMessage;
