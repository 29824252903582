import {useEffect, useState} from 'react';

import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {Divider, Menu, useTheme} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import useProfile from 'hooks/providers/useProfile';

import {APP_ROUTES} from '../../const';
import useAuth from '../../hooks/providers/useAuth';
import useLoading from '../../hooks/providers/useLoading';
import DefaultAvatar from '../../img/default-avatar.png';

const Container = styled.div`
  height: 100%;
  min-width: 64px;
  border-left: solid 1px rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  padding: 0 28px;
  margin-left: 24px;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    padding: 0 8px;
    margin-left: 8px;
  }
`;

const UserNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 16px;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const UserName = styled.p`
  font-family: 'InstrumentSans';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #ffffff;
`;
const UserType = styled.p`
  font-family: 'InstrumentSans';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #ffffff;
  opacity: 0.4;
`;
const UserAvatar = styled.img`
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #ffffff;

  @media screen and (max-width: 900px) {
    margin-right: 4px;
  }
`;

const UserComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const profileContext = useProfile();
  const theme = useTheme();
  const {setCompletedApiCalls} = useLoading();

  useEffect(() => {
    (async () => {
      if (auth?.user?.tokenAad) {
        try {
          await profileContext.fetchProfile();
          setCompletedApiCalls(prevCompleted => prevCompleted + 1);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    })();
  }, []);

  const logout = async () => {
    if (auth) {
      await auth.logout();
    }
    setIsOpen(false);
  };

  const openProfileMenu = event => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const closeProfileMenu = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const navigateTo = route => {
    navigate(route);
    setIsOpen(false);
  };

  const fullName = `${profileContext?.profile?.givenName || ''} ${profileContext?.profile?.surname || ''}`;

  return (
    <Container onClick={isOpen ? closeProfileMenu : openProfileMenu}>
      <UserNameContainer>
        <UserName>{fullName}</UserName>
        <UserType>{profileContext?.profile?.companyName}</UserType>
      </UserNameContainer>
      <UserAvatar
        // src={profileContext?.profile?.avatar} // TODO UNCOMMENT ONCE AVATAR FEATURE IS IMPLEMENTED
        src={DefaultAvatar}
      />
      {isOpen ? <KeyboardArrowUp sx={{fontSize: 20, color: '#fff'}} /> : <KeyboardArrowDown sx={{fontSize: 20, color: '#fff'}} />}
      <Menu
        disableScrollLock={false}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={() => navigateTo(APP_ROUTES.connections)}>
          <ListItemIcon sx={{color: theme.palette.primary.main}}>
            <CreateNewFolderIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mes connexions</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigateTo(APP_ROUTES.profile)}>
          <ListItemIcon sx={{color: theme.palette.primary.main}}>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mon compte</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon sx={{color: theme.palette.error.main}}>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{color: 'error'}}>Déconnexion</ListItemText>
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default UserComponent;
