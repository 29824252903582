import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import muiStyled from '@mui/material/styles/styled';
import styled from 'styled-components';

import {assistantShape} from '../../../proptypes-shapes';
import BrandedTooltip from '../../tooltip/BrandedTooltip';

const DescriptionBlockquote = styled.blockquote`
  border-left: 4px solid white;
  line-height: 1.2;
  padding-left: 8px;
  font-style: italic;
  margin: 6px 0 4px 4px;
  color: #c2c2c2;
  font-size: 11px;
`;

const Image = muiStyled(Box)(() => ({
  maxWidth: 45,
  cursor: 'pointer',
  borderRadius: '50%',
  boxShadow: '0px 3px 6px #00000029'
}));

const AssistantPictureTooltip = ({assistant}) => {
  return (
    <BrandedTooltip
      arrow
      title={
        <>
          <Typography textAlign="center" color="#FFF" fontSize={14}>
            {assistant.name}
          </Typography>
          <Typography textAlign="center" color="#FFF" fontSize={13}>
            {assistant.role}
          </Typography>
          <DescriptionBlockquote>" {assistant.description} "</DescriptionBlockquote>
        </>
      }
    >
      <Image alt={`image assistant : ${assistant.name}`} component="img" src={assistant.url_photo} />
    </BrandedTooltip>
  );
};

AssistantPictureTooltip.propTypes = {
  assistant: assistantShape.isRequired
};

export default AssistantPictureTooltip;
