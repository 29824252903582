import {Tooltip} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {number} from 'prop-types';

import {BREAKPOINTS} from '../../../../const';
import useBreakpoint from '../../../../hooks/dom/useBreakpoint';
import useProjects from '../../../../hooks/providers/useProjects';

const RoundedBordersCircularProgress = styled(CircularProgress)(() => ({
  [`& .${circularProgressClasses.svg}`]: {
    strokeLinecap: 'round'
  }
}));

/**
 * Normalises value to be used by MUI Progress component (range 0-100)
 * @param value
 * @param maxValue
 * @returns {number}
 */
const normaliseValue = (value, maxValue) => {
  const minValue = 0;
  if (value <= minValue) {
    return 0;
  }
  if (value >= maxValue) {
    return 100;
  }
  return ((value - minValue) / (maxValue - minValue)) * 100;
};

const getCounterCssColor = value => {
  if (value < 80) {
    return '#2BC5AB';
  }
  if (value >= 80 && value < 100) {
    return '#EA9C1F';
  }
  return '#DB434F';
};

const ConnectorFoldersListCounter = ({numberOfFoldersSelected}) => {
  const {connector} = useProjects();
  const breakpoint = useBreakpoint();

  const maximumNumberOfFolders = connector.max_folder_to_load;
  const value = normaliseValue(numberOfFoldersSelected, maximumNumberOfFolders);

  const color = getCounterCssColor(value);
  const size = breakpoint === BREAKPOINTS.xs ? 55 : 60;
  const thickness = breakpoint === BREAKPOINTS.xs ? 2 : 3;
  const numberFontSize = breakpoint === BREAKPOINTS.xs ? 11 : 12;

  return (
    <Tooltip title={`${maximumNumberOfFolders} dossiers simultanément`} placement="top">
      <Box sx={{position: 'relative', display: 'inline-flex'}}>
        <RoundedBordersCircularProgress data-testid="progressbar" size={size} variant="determinate" thickness={thickness} value={value} sx={{color, zIndex: 1}} />
        <RoundedBordersCircularProgress size={size} variant="determinate" thickness={thickness} value={100} sx={{position: 'absolute', color: 'lightgrey', zIndex: 0}} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography lineHeight={1} variant="caption" component="div" fontSize={numberFontSize}>
            {`${numberOfFoldersSelected} / ${maximumNumberOfFolders}`}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

ConnectorFoldersListCounter.propTypes = {
  numberOfFoldersSelected: number.isRequired
};

export default ConnectorFoldersListCounter;
