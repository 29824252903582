import {TextField} from '@mui/material';

import PasswordInputRevealable from '../components/form/PasswordRevealableInput/PasswordInputRevealable';
import SirenTextField from '../components/form/SirenTextField/SirenTextField';
import UploadDropzone from '../components/upload/UploadDropzone';
import {FORM_FIELD_TYPES} from '../const';

const getFormFieldComponent = type => {
  let Component = null;
  switch (type) {
    case FORM_FIELD_TYPES.text:
      Component = TextField;
      break;
    case FORM_FIELD_TYPES.password:
      Component = PasswordInputRevealable;
      break;
    case FORM_FIELD_TYPES.siren:
      Component = SirenTextField;
      break;
    case FORM_FIELD_TYPES.drop_zone:
      Component = UploadDropzone;
      break;
    default:
      // eslint-disable-next-line no-console
      console.error(`Unknown form field type : ${type}`);
      break;
  }

  return Component;
};

const generateFormFields = ({connector, formData, setFormData, setFiles = null, handleSirenChange = null}) => {
  const formFields = connector.credential_fields?.map(field => {
    const FormFieldComponent = getFormFieldComponent(field.type);

    if (field.type === FORM_FIELD_TYPES.drop_zone) {
      const acceptedFileTypes = {};

      field.accepted_extension.forEach(ext => {
        acceptedFileTypes[ext.mime_type] = [ext.extension];
      });

      return (
        <FormFieldComponent
          key={field.label}
          maximumNumberOfFiles={field.maximum_number_of_files}
          onFilesUpload={f => {
            setFiles(f);
          }}
          acceptedFileTypes={acceptedFileTypes}
        />
      );
    }

    if (field.type === FORM_FIELD_TYPES.siren) {
      return <FormFieldComponent onChange={handleSirenChange} />;
    }

    return (
      <FormFieldComponent
        key={field.label}
        required
        size="small"
        value={formData[field.id]}
        label={field.label}
        color="primary"
        fullWidth
        sx={{
          mb: 1.5,
          display: field.is_hide ? 'none' : 'inherit'
        }}
        onChange={e => {
          setFormData(currentFormData => {
            const newFormData = {...currentFormData};
            const newValue = e.target.value;
            newFormData[field.id] = newValue;
            return newFormData;
          });
        }}
      />
    );
  });

  return formFields;
};

export {generateFormFields};
