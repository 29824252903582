import {useEffect, useState} from 'react';

import {TextField, Box, DialogContent, DialogActions, FormControlLabel, Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';

import {REGULAR_EXPRESSIONS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import Button from '../../form/buttons/Button/Button';
import FormSelect from '../../form/MuiSelect/MuiSelect';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';

// eslint-disable-next-line complexity
const DeleteBudgetDialog = () => {
  const {
    isCreateBudgetForm: isCreation,
    deleteBudget,
    isDeleteBudgetDialogOpen: isOpen,
    setIsDeleteBudgetDialogOpen,
    setSpreadsheetMenuOpen,
    setIsSpreadsheetModalOpen,
    selectedBudget: budgetName,
    setSelectedBudget,
    selectedBudgetYears: budgetYears,
    selectedProject: project
  } = useReport();

  const [shouldDeleteWholeBudget, setShouldDeleteWholeBudget] = useState(false);
  const [year, setYear] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const budgetRegex = REGULAR_EXPRESSIONS.labelWithMinimumChars(4);
  const hasLabelError = budgetName !== '' && !budgetRegex.test(budgetName);
  const canSubmit = budgetName && (year || shouldDeleteWholeBudget) && !hasLabelError;

  // This hook aims to reset state on modal closing
  // Without this hook, the component might keep the state shouldDeleteWholeBudget=true and not display the years select
  useEffect(() => {
    if (!isOpen) {
      setYear('');
      setShouldDeleteWholeBudget(false);
    }
  }, [isOpen]);

  return (
    <BaseModal onClose={() => setIsDeleteBudgetDialogOpen(false)} open={isOpen} title="Supprimer un budget">
      <DialogContent>
        <Box mt={1} mb={2}>
          <TextField disabled={!isCreation} sx={{mb: 2}} value={budgetName} fullWidth label="Nom du budget" defaultValue="" />

          {!shouldDeleteWholeBudget && budgetYears.length > 1 && (
            <FormSelect
              disabled={isSubmitting}
              sx={{width: '100%', mb: 2}}
              label="Sélectionner une année à supprimer"
              labelId="year-label"
              value={year}
              onChange={e => {
                setYear(e.target.value);
              }}
              items={budgetYears.map(y => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            />
          )}

          <FormControlLabel
            disabled={isSubmitting}
            control={<Checkbox onChange={ev => setShouldDeleteWholeBudget(ev.target.checked)} />}
            label={<Typography variant="p">Supprimer le budget</Typography>}
          />
        </Box>
      </DialogContent>
      {isSubmitting && <Spinner text="Suppression du budget" isLoading />}

      <DialogActions sx={{justifyContent: 'center'}}>
        <Button
          disabled={isSubmitting || !canSubmit}
          variant="contained"
          color="primary"
          onClick={async () => {
            setIsSubmitting(true);
            const res = await deleteBudget({
              budgetName: budgetName.trim(),
              budgetYear: shouldDeleteWholeBudget ? null : year,
              siren: project?.siren
            });
            setIsSubmitting(false);
            if (res.status === 200) {
              setIsDeleteBudgetDialogOpen(false);
              setSpreadsheetMenuOpen(false);
              if (shouldDeleteWholeBudget) {
                setIsSpreadsheetModalOpen(false);
                setSelectedBudget(null);
              }
            }
          }}
        >
          Supprimer le budget
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

export default DeleteBudgetDialog;
