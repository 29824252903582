import {Grid} from '@mui/material';
import {bool, func} from 'prop-types';

import useProjects from '../../../../../hooks/providers/useProjects';
import {generateFormFields} from '../../../../../utils';
import Button from '../../../../form/buttons/Button/Button';
import LocalConnectorAskCredentialsButton from '../LocalConnectorAskCredentialsButton/LocalConnectorAskCredentialsButton';

const LoginToConnectorForm = ({isCheckingConnectorConnection, isLogging, handleSubmit}) => {
  const {connector, loginToConnectorFormData: formData, setLoginToConnectorFormData: setFormData, isLoginToConnectorFormValid} = useProjects();

  if (isCheckingConnectorConnection || isLogging) {
    return null;
  }

  const formFields = generateFormFields({connector, formData, setFormData});
  const isFormValid = isLoginToConnectorFormValid();

  return (
    <>
      {formFields}

      <Grid container alignItems="center" mt={2} flexDirection="column">
        <Grid item xs={12} sm={8}>
          <Button disabled={isLogging || !isFormValid} onClick={handleSubmit} fullWidth variant="contained" color="secondary" size="large">
            Connecter mon compte {connector.name}
          </Button>
        </Grid>

        <LocalConnectorAskCredentialsButton connector={connector} />
      </Grid>
    </>
  );
};

LoginToConnectorForm.propTypes = {
  isCheckingConnectorConnection: bool.isRequired,
  isLogging: bool.isRequired,
  handleSubmit: func.isRequired
};

export default LoginToConnectorForm;
