/* eslint-disable react/jsx-props-no-spreading */
import {useState} from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {IconButton, TextField, InputAdornment} from '@mui/material';
import {string} from 'prop-types';

const PasswordInputRevealable = props => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle visibility" onClick={() => setShowPassword(!showPassword)} edge="end">
              {showPassword ? <VisibilityOff data-testid="visibility-icon-off" /> : <Visibility data-testid="visibility-icon-on" />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

PasswordInputRevealable.defaultProps = {
  helperText: ''
};

PasswordInputRevealable.propTypes = {
  helperText: string
};

export default PasswordInputRevealable;
