import CloseIcon from '@mui/icons-material/Close';
import {Grid, Hidden, Paper} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import muiStyled from '@mui/material/styles/styled';

import useChatbot from '../../../../hooks/providers/useChatbot';
import {groupThreadsByTime} from '../../../../utils';
import Spinner from '../../../spinner/Spinner';
import ChatbotSingleThreadMenu from '../../ChatbotSingleThreadMenu/ChabotSingleThreadMenu';
import TopbarIconButton from '../../ChatbotTopbarIconButton/ChatbotTopbarIconButton';
import DeleteThreadModal from '../../DeleteThreadModal/DeleteThreadModal';
import GroupedThreadsList from '../GroupedThreadsList/GroupedThreadsList';
import TalksListHeader from '../TalksListHeader/TalksListHeader';

const Container = muiStyled(Paper)(() => ({
  position: 'sticky',
  top: 0,
  overflow: 'scroll',
  marginRight: 4,
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

const TalksList = () => {
  const {threads, chatbotDataLoading, threadNameSearched, isFullScreenMode, isTalksListDrawerOpen, setIsTalksListDrawerOpen} = useChatbot();

  const filteredThreads = threads.filter(f => f.thread_name.toLowerCase().includes(threadNameSearched.toLowerCase()));
  const groupedThreads = groupThreadsByTime(filteredThreads.sort((a, b) => b.creation_time - a.creation_time));

  const renderContent = () => {
    return (
      <Container elevation={1}>
        <TalksListHeader />

        {chatbotDataLoading ? (
          <Grid container justifyContent="center" alignItems="center" flex={1}>
            <Spinner text="Chargement des conversations" size={96} isLoading />
          </Grid>
        ) : (
          <GroupedThreadsList groupedThreads={groupedThreads} />
        )}

        <DeleteThreadModal />
        <ChatbotSingleThreadMenu />
      </Container>
    );
  };

  return (
    <>
      {isFullScreenMode && <Box sx={{display: {lg: 'block', xs: 'none'}}}>{renderContent()}</Box>}

      <Box sx={{display: {lg: 'none', xs: 'block'}}}>
        <Drawer
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
          }}
          open={isTalksListDrawerOpen}
          onClose={() => setIsTalksListDrawerOpen(false)}
        >
          {renderContent()}
          <TopbarIconButton aria-label="close" sx={{position: 'fixed', top: 12, right: 8}} onClick={() => setIsTalksListDrawerOpen(false)}>
            <CloseIcon color="white" fontSize="medium" />
          </TopbarIconButton>
        </Drawer>
      </Box>
    </>
  );
};

export default TalksList;
