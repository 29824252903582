import {useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, SNACKBAR_ACTIONS} from '../const';
import ContactContext from '../contexts/ContactContext';
import useHttp from '../hooks/misc/useHttp';
import useSnackbar from '../hooks/providers/useSnackbar';

// eslint-disable-next-line complexity
const ContactProvider = ({children}) => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const {showSnackbar} = useSnackbar();
  const {_post} = useHttp();

  const sendContactMessage = async (shouldDisplaySnackbar = true) => {
    try {
      const url = API_ENDPOINTS.projects.contact;
      const {response, responseJson: data} = await _post(url, {
        subject,
        message
      });

      if (response.status === 200) {
        if (shouldDisplaySnackbar) {
          showSnackbar(SNACKBAR_ACTIONS.SEND_CONTACT_MESSAGE_SUCCESS);
        }
        return {
          message: data?.message || data,
          status: 200
        };
      }
      return {
        status: response.status,
        message: data?.message || data
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e
      };
    }
  };

  const openAskConnectorOpeningContactForm = c => {
    const contactSubject = `Ouverture connecteur ${c.name}`;
    const contactMessage = `Bonjour, je voudrais connecter mes données présentes dans ${c.name}. Pourriez-vous me recontacter ? Merci.`;
    setIsContactFormOpen(true);
    setMessage(contactMessage);
    setSubject(contactSubject);
  };

  const useMemoDeps = [sendContactMessage, isContactFormOpen, setIsContactFormOpen, subject, setSubject, message, setMessage];

  const value = useMemo(
    () => ({
      sendContactMessage,
      isContactFormOpen,
      setIsContactFormOpen,
      subject,
      setSubject,
      message,
      setMessage,
      openAskConnectorOpeningContactForm
    }),
    useMemoDeps
  );

  return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>;
};

ContactProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ContactProvider;
