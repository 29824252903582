import {useEffect, useState} from 'react';

import {TabContext} from '@mui/lab';
import {DialogActions, DialogContent, FormControl, Grid, RadioGroup, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import {func, bool} from 'prop-types';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../const';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import Button from '../form/buttons/Button/Button';
import Checkbox from '../form/Checkbox/Checkbox';
import ErrorMessage from '../form/ErrorMessage/ErrorMessage';
import FormUppercaseLabel from '../form/FormUppercaseLabel/FormUppercaseLabel';
import Radio from '../form/Radio/Radio';
import BaseModal from '../modal/BaseModal';
import Spinner from '../spinner/Spinner';
import StyledTab from '../tabs/StyledTab';
import StyledTabs from '../tabs/StyledTabs';
import UploadDropzone from '../upload/UploadDropzone';
import ImportedReportNameAlreadyExistsModal from './ImportedReportNameAlreadyExistsModal/ImportedReportNameAlreadyExistsModal';

const MODAL_TABS = {
  import: 'import',
  create: 'create'
};

// eslint-disable-next-line complexity
const CreateOrImportReportModal = ({isOpen, closeModal}) => {
  const {isCustomReportCreation, setIsCustomReportCreation} = useWorkspaces();
  const [reportName, setReportName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileUploadError, setFileUploadError] = useState('');
  const [reportContainsSecurityOnData, setReportContainsSecurityOnData] = useState(0);
  const [reportSecurityOnDataRole, setReportSecurityOnDataRole] = useState('');

  const [importedReportNameAvailable, setImportedReportNameAvailable] = useState(false);
  const [importedReportNameNotAvailableModalOpen, setImportedReportNameNotAvailableModalOpen] = useState(false);

  const {createReport, importReport, selectedWorkspaceId, selectedWorkspace, importReportError, setImportReportError} = useWorkspaces();

  const isNameAvailable = !selectedWorkspace?.reports?.map(r => r.report_name).includes(reportName);
  const hasNameError = reportName !== '' && !REGULAR_EXPRESSIONS.labelWithMinimumChars(4).test(reportName);
  const isSecurityOnReportRoleNameValid = REGULAR_EXPRESSIONS.labelWithRangeChars(1, 50).test(reportSecurityOnDataRole);

  const isReportNameValid = reportName && !hasNameError && isNameAvailable;
  const areCustomReportFilesValid = files && files?.length > 0 && fileUploadError === '';

  const handleImportReportSubmit = async (shouldEraseExistingReport = false) => {
    const importReportData = {
      file: files[0],
      workspaceId: selectedWorkspaceId,
      isEffectiveIdentityRolesRequired: reportContainsSecurityOnData,
      role: reportSecurityOnDataRole
    };
    return importReport(importReportData, shouldEraseExistingReport);
  };

  const onSubmit = async () => {
    if (isCustomReportCreation && !importedReportNameAvailable) {
      setImportedReportNameNotAvailableModalOpen(true);
      return;
    }

    setIsSubmitting(true);
    let result;
    if (isCustomReportCreation) {
      result = await handleImportReportSubmit(false);
    } else {
      result = await createReport(reportName, selectedWorkspaceId);
    }

    setIsSubmitting(false);

    if (result.success) {
      closeModal();
    }
  };

  const isFormValid = () => {
    if (isSubmitting) return false;

    if (isCustomReportCreation) {
      return areCustomReportFilesValid && (!reportContainsSecurityOnData || (reportContainsSecurityOnData && isSecurityOnReportRoleNameValid));
    }

    return isReportNameValid;
  };

  const getReportNameHelperText = () => {
    if (hasNameError) return REGULAR_EXPRESSIONS_ERROR_TEXTS.label;
    if (!isNameAvailable) return 'Ce nom de rapport est déja utilisé dans cet espace';
    return '';
  };

  const getSecurityOnDataRoleHelperText = () => {
    if (!isSecurityOnReportRoleNameValid && reportSecurityOnDataRole !== '') return REGULAR_EXPRESSIONS_ERROR_TEXTS.labelWithRangeChars(1, 50);
    return 'La mise en place de la sécurité sur les données nécessite une table de sécurité comprenant les GUID de vos utilisateurs. Contactez-nous pour toutes questions';
  };

  const handleTabChange = () => {
    setIsCustomReportCreation(!isCustomReportCreation);
  };

  useEffect(() => {
    if (!isOpen) {
      setReportName('');
      setFileUploadError('');
      setFiles(null);
      setIsSubmitting(false);
      setReportContainsSecurityOnData(0);
      setReportSecurityOnDataRole('');
      setImportReportError('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (files && files.length > 0) {
      const filenameWithExtension = files[0].name;
      const filename = filenameWithExtension.replace(/\.pbix$/, '');
      setReportName(filename);
      const isImportedReportNameAvailable = !selectedWorkspace?.reports?.map(r => r.report_name).includes(filename);
      setImportedReportNameAvailable(isImportedReportNameAvailable);
    }
  }, [files]);

  const reportNameHelperText = getReportNameHelperText();
  const securityOnReportRoleHelperText = getSecurityOnDataRoleHelperText();
  const submitButtonText = isCustomReportCreation ? 'Importer le rapport' : 'Créer le rapport';
  const spinnerText = isCustomReportCreation ? 'Chargement du rapport en cours...' : 'Création du rapport';
  const canSubmit = isFormValid();
  const tabModalSelected = isCustomReportCreation ? MODAL_TABS.import : MODAL_TABS.create;

  console.log({isCustomReportCreation, tabModalSelected});
  return (
    <BaseModal withoutTitle onClose={closeModal} open={isOpen}>
      <TabContext value={tabModalSelected}>
        <StyledTabs onChange={handleTabChange} scrollButtons={false} aria-label="Onglets créer ou importer un rapport">
          <StyledTab label="Importer un rapport" value={MODAL_TABS.import} />
          <StyledTab disabled label="Créer un rapport" value={MODAL_TABS.create} />
        </StyledTabs>
      </TabContext>

      <DialogContent sx={{p: 3, mt: isCustomReportCreation ? 0 : 2}}>
        <Grid container flexDirection="column">
          {!isCustomReportCreation && (
            <>
              <TextField
                disabled={isSubmitting}
                required
                value={reportName}
                onChange={event => {
                  setReportName(event.target.value);
                }}
                label="Nom"
                placeholder="Saisir le nom du nouveau rapport"
                color="primary"
                fullWidth
                error={hasNameError || !isNameAvailable}
                helperText={reportNameHelperText}
              />
              <Box mt={2}>
                <FormUppercaseLabel label="Gérer les sources de données" />
              </Box>
              <Grid container>
                <Checkbox label="Comptable" checked onChange={null} />
                <Checkbox disabled label="Budget" onChange={null} />
                <Checkbox disabled label="Social" onChange={null} />
              </Grid>
            </>
          )}

          {isCustomReportCreation && !isSubmitting && (
            <>
              <FormUppercaseLabel label="Glisser-déposer un fichier Power BI (.pbix)" />
              <UploadDropzone
                maximumNumberOfFiles={1}
                error={fileUploadError}
                onFilesUpload={setFiles}
                acceptedFileTypes={{
                  'application/pbix': ['.pbix']
                }}
              />

              <FormControl sx={{mt: 3}}>
                <FormUppercaseLabel label="Ce fichier contient-il de la sécurité sur les données ?" />
                <RadioGroup
                  row
                  value={reportContainsSecurityOnData}
                  onChange={() => {
                    setReportContainsSecurityOnData(reportContainsSecurityOnData === 1 ? 0 : 1);
                  }}
                  aria-labelledby="report contains security on data"
                  name="security-on-data-role"
                >
                  <Radio value={1} label="Oui" checked={reportContainsSecurityOnData === 1} />
                  <Radio value={0} label="Non" checked={reportContainsSecurityOnData === 0} />
                </RadioGroup>
              </FormControl>

              {Boolean(reportContainsSecurityOnData) && (
                <TextField
                  disabled={isSubmitting}
                  required
                  value={reportSecurityOnDataRole}
                  onChange={event => {
                    setReportSecurityOnDataRole(event.target.value);
                  }}
                  label="Rôle"
                  placeholder="Veuillez saisir le nom donné au rôle (sensible à la casse)"
                  color="primary"
                  fullWidth
                  error={!isSecurityOnReportRoleNameValid && reportSecurityOnDataRole !== ''}
                  helperText={securityOnReportRoleHelperText}
                  sx={{
                    mt: 1.5
                  }}
                />
              )}
            </>
          )}
        </Grid>
        {isSubmitting && <Spinner text={spinnerText} isLoading />}
        {importReportError !== '' && <ErrorMessage message={importReportError} />}
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', mb: 2}}>
        <Button sx={{maxWidth: 300}} disabled={!canSubmit} onClick={onSubmit} fullWidth variant="contained" color="secondary" size="large">
          {submitButtonText}
        </Button>
      </DialogActions>
      <ImportedReportNameAlreadyExistsModal
        reportName={reportName}
        isOpen={importedReportNameNotAvailableModalOpen}
        onClose={() => setImportedReportNameNotAvailableModalOpen(false)}
        onSubmit={async () => {
          await handleImportReportSubmit(true);
          closeModal();
        }}
      />
    </BaseModal>
  );
};

CreateOrImportReportModal.defaultProps = {
  isOpen: false
};

CreateOrImportReportModal.propTypes = {
  isOpen: bool,
  closeModal: func.isRequired
};

export default CreateOrImportReportModal;
