import {useState} from 'react';

import {Button, DialogActions, DialogContent, Typography} from '@mui/material';
import {bool, func, string} from 'prop-types';

import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';

const ImportedReportNameAlreadyExistsModal = ({isOpen, onClose, onSubmit, reportName}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
    onClose();
  };

  const title = isSubmitting ? 'Chargement en cours' : `Rapport ${reportName} existant`;

  return (
    <BaseModal onClose={onClose} maxWidth="sm" open={isOpen} title={title}>
      <DialogContent sx={{p: 3}}>
        {isSubmitting ? (
          <Spinner text="Import du rapport en cours" isLoading />
        ) : (
          <Typography>
            Cette action <b> écrasera {reportName}</b>. Confirmez-vous ?
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{pb: 1}}>
        <Button disabled={isSubmitting} variant="contained" color="error" onClick={handleSubmit}>
          Remplacer le rapport
        </Button>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Annuler
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

ImportedReportNameAlreadyExistsModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  reportName: string.isRequired
};

export default ImportedReportNameAlreadyExistsModal;
