import VisibilityIcon from '@mui/icons-material/Visibility';
import {Tooltip} from '@mui/material';
import {bool} from 'prop-types';

const InvitedOnProjectIcon = ({isOwner}) => {
  return !isOwner ? (
    <Tooltip title="Vous êtes invité sur cette connexion. Celle-ci ne compte pas dans le nombre de connexions créées">
      <VisibilityIcon aria-label="icone invité" color="primary" />
    </Tooltip>
  ) : null;
};

InvitedOnProjectIcon.propTypes = {
  isOwner: bool.isRequired
};

export default InvitedOnProjectIcon;
