import {useEffect, useState} from 'react';

import {POWER_BI_PAGES, POWER_BI_REPORT_BOOKMARKS} from '../../../../const';
import useProjects from '../../../../hooks/providers/useProjects';
import useReport from '../../../../hooks/providers/useReport';
import {getAccountantExpectedElementsVisualJsonData} from '../../../../utils';
import Button from '../../../form/buttons/Button/Button';
import AccountantDocumentRequestModal from '../../AccountantDocumentRequestModal/AccountantDocumentRequestModal';

const AccountantDocumentRequestButton = () => {
  const [documentRequestBookmarkApplied, setDocumentRequestBookmarkApplied] = useState(false);
  const [isAccountantDocumentRequestModalOpen, setAccountantDocumentRequestModalOpen] = useState(false);
  const [isDataArrayEmpty, setIsDataArrayEmpty] = useState(false);

  const {reportSelectedPage, lastBookmarkExceptFilters} = useReport();
  const {isGuestMode} = useProjects();

  const isSynthesisPage = reportSelectedPage.name === POWER_BI_PAGES.reportSynthesis.id;
  const shouldDisplay = documentRequestBookmarkApplied && isSynthesisPage && !isGuestMode;

  useEffect(() => {
    const checkVisualData = async () => {
      if (!shouldDisplay) return;
      const data = await getAccountantExpectedElementsVisualJsonData(reportSelectedPage);
      setIsDataArrayEmpty(data.length === 0);
    };
    checkVisualData();

    const intervalId = setInterval(checkVisualData, 1000);
    return () => clearInterval(intervalId);
  }, [shouldDisplay, reportSelectedPage]);

  useEffect(() => {
    const isDocumentRequestBookmark = lastBookmarkExceptFilters === POWER_BI_REPORT_BOOKMARKS.accountantExpectedElements.name;
    if (isDocumentRequestBookmark) {
      setDocumentRequestBookmarkApplied(true);
    } else {
      setDocumentRequestBookmarkApplied(false);
    }
  }, [lastBookmarkExceptFilters]);

  if (shouldDisplay) {
    return (
      <>
        <Button disabled={isDataArrayEmpty} variant="contained" color="secondary" onClick={() => setAccountantDocumentRequestModalOpen(true)}>
          Envoyer une demande de documents
        </Button>
        <AccountantDocumentRequestModal isOpen={isAccountantDocumentRequestModalOpen} closeModal={() => setAccountantDocumentRequestModalOpen(false)} />
      </>
    );
  }

  return null;
};

export default AccountantDocumentRequestButton;
