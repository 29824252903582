import {string} from 'prop-types';

import useContact from '../../../hooks/providers/useContact';
import Button from '../../form/buttons/Button/Button';

const OpenContactFormButton = ({text}) => {
  const {setIsContactFormOpen} = useContact();

  return (
    <Button sx={{textDecoration: 'underline', fontSize: 14}} size="small" variant="text" onClick={() => setIsContactFormOpen(true)}>
      {text}
    </Button>
  );
};

OpenContactFormButton.propTypes = {
  text: string.isRequired
};

export default OpenContactFormButton;
