/* eslint-disable react/boolean-prop-naming */
import {useEffect, useState} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {createTheme, Divider, useTheme, ThemeProvider, IconButton, Grid} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {useWindowSize} from '@uidotdev/usehooks';
import {bool, func, string, node, oneOfType, arrayOf, shape} from 'prop-types';

const createDialogTheme = parentTheme => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette)
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            padding: 1
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: 8,
            paddingBottom: 0
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            width: '50%',
            [parentTheme.breakpoints.down('md')]: {
              width: '80%'
            },
            margin: '0 auto'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            textAlign: 'center',
            fontSize: 18,
            padding: parentTheme.spacing(1.5),
            fontFamily: 'SoehneBreitKraftig',
            [parentTheme.breakpoints.down('md')]: {
              padding: parentTheme.spacing(1),
              fontSize: 17
            }
          }
        }
      }
    }
  });
};

const BaseModal = props => {
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);

  const {
    isFullScreen,
    customRenderTitle,
    hasFullScreenButton,
    shouldMatchEmbedReportWidth = false,
    withoutTitle,
    onClose,
    title,
    children,
    hasCloseIcon,
    canCloseOnBackdropClick,
    overridesPaperProps,
    hasTitleDivider,
    ...rest
  } = props;

  const [reportWidth, setReportWidth] = useState(0);

  const windowSize = useWindowSize();
  const parentTheme = useTheme();
  const dialogTheme = createDialogTheme(parentTheme);

  const shouldApplyReportWidth = shouldMatchEmbedReportWidth && !isFullScreen && !isFullScreenEnabled;
  const paperProps = {
    sx: {
      maxHeight: 'calc(100% - 8px)',
      ...(!isFullScreenEnabled && !isFullScreen
        ? {
            margin: {
              xs: 1,
              sm: 2,
              md: 3
            }
          }
        : {
            margin: 0
          }),

      width: {
        xs: '100%'
      },
      ...overridesPaperProps,
      ...(shouldApplyReportWidth && {maxWidth: `${reportWidth}px !important`})
    }
  };

  useEffect(() => {
    if (shouldMatchEmbedReportWidth) {
      const reportHtmlElement = document.querySelector('.report-embed');
      setReportWidth(reportHtmlElement?.clientWidth);
    }
  }, [shouldMatchEmbedReportWidth, windowSize.width]);

  const renderTitle = () => {
    if (withoutTitle) return null;

    if (customRenderTitle) return customRenderTitle();

    if (hasCloseIcon || hasFullScreenButton) {
      return (
        <Grid container alignItems="center">
          <DialogTitle sx={{flex: 1}} ml={6}>
            {title}
          </DialogTitle>

          {hasFullScreenButton ? (
            <Grid item mr={1} sx={{cursor: 'pointer'}} onClick={() => setIsFullScreenEnabled(!isFullScreenEnabled)}>
              {isFullScreenEnabled ? <FullscreenExitIcon sx={{fontSize: 30}} /> : <FullscreenIcon sx={{fontSize: 30}} />}
            </Grid>
          ) : null}

          {hasCloseIcon ? (
            <Grid item mr={1}>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: theme => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          ) : null}

          {hasTitleDivider ? <Divider sx={{my: 0.5, width: '100%'}} /> : null}
        </Grid>
      );
    }

    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        {hasTitleDivider ? <Divider sx={{my: 0.5}} /> : null}
      </>
    );
  };

  const handleClose = (event, reason) => {
    if (!canCloseOnBackdropClick && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <ThemeProvider theme={dialogTheme}>
      <Dialog fullScreen={isFullScreenEnabled || isFullScreen} onClose={(e, r) => handleClose(e, r)} fullWidth PaperProps={paperProps} {...rest}>
        {renderTitle()}
        {children}
      </Dialog>
    </ThemeProvider>
  );
};

BaseModal.defaultProps = {
  title: '',
  withoutTitle: false,
  hasCloseIcon: false,
  canCloseOnBackdropClick: true,
  overridesPaperProps: null,
  hasFullScreenButton: false,
  shouldMatchEmbedReportWidth: false,
  customRenderTitle: null,
  isFullScreen: false,
  hasTitleDivider: true
};

BaseModal.propTypes = {
  onClose: func.isRequired,
  customRenderTitle: func,
  title: oneOfType([string, node]),
  children: oneOfType([arrayOf(node), node]).isRequired,
  withoutTitle: bool,
  hasCloseIcon: bool,
  canCloseOnBackdropClick: bool,
  overridesPaperProps: shape({}),
  hasFullScreenButton: bool,
  shouldMatchEmbedReportWidth: bool,
  isFullScreen: bool,
  hasTitleDivider: bool
};

export default BaseModal;
